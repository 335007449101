import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withoutAuthAxios } from '../services/config';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const FooterMobile = () => {
    const { accessToken } = useSelector((state) => state.auth);
    const userType = useSelector((state) => state.auth?.user?.userType);
    const user = useSelector((state) => state.auth.user);
    const [email, setEmail] = useState('');
    const location = useLocation();
    const currentPath = location.pathname;
    // Define paths where the footer should be visible
  const hideFooterPaths = ['/', '/become-a-creatr', '/become-a-hustlr'];
  const isMobileView = window.innerWidth <= 822;


    const handleSubscribe = async () => {
        await withoutAuthAxios().post('/newsletter/subscribe', { email })
            .then((response) => {
                const data = response.data
                toast.success(data?.message);
                setEmail('');
            })
            .catch((error) => {
                console.error('Error subscribing to newsletter:', error.message);
                toast.info(error.response?.data?.message);
            })
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    return (

        <>
        {/* <h2 
  className='playFairDisplay' 
  style={{ 
    margin: '10px', 
    fontSize: '26px', 
    color: '#333', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    textAlign: 'center'
  }}
>
</h2> */}

<div 
    className='news--letter--footer' 
    style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        padding: '20px', 
        margin: '0 auto', 
        marginBottom: '30px',  // Added bottom gap
        marginTop:'30px',
        textAlign: 'center', 
        width: '80%', 
        maxWidth: '500px', 
        backgroundColor: '#f9f9f9', 
        borderRadius: '10px', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    }}>
    <div className='heading--n-leter' style={{ marginBottom: '20px' }}>
    
        <h3 className='playFairDisplay' style={{ margin: '0', fontSize: '28px', color: '#333' }}>  Keep Me Posted </h3>

    </div>
    <div className='input--email' style={{ marginBottom: '15px', width: '100%' }}>
        <input 
            type='email' 
            placeholder='Enter your email' 
            value={email} 
            onChange={handleEmailChange} 
            style={{
                width: '100%',
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontSize: '16px'
            }}
        />
    </div>

    <div className='input--check' style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', width: '100%' }}>
        <input type='checkbox' style={{ marginRight: '10px' }} />
        <div className='newsspra'>
            <p className='text-black' style={{ margin: '0', fontSize: '14px', color: '#666' }}>
                Subscribe to receive news and updates about SideHustl
            </p>
        </div>
    </div>
    <div className='submit-news'>
        <button 
            className='ligghtbtn-hover' 
            onClick={handleSubscribe} 
            style={{
                backgroundColor: '#1F1F28', 
                color: '#fff', 
                padding: '10px 20px', 
                borderRadius: '5px', 
                border: 'none', 
                cursor: 'pointer', 
                fontSize: '16px',
                transition: 'background-color 0.3s ease'
            }}
            // onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
            // onMouseOut={(e) => e.target.style.backgroundColor = '#007bff'}
        >
            <span>Subscribe</span>
        </button>
    </div>
</div>



        <footer className='footer'>

            
            <div className='container'>

                {/* Newsletter Section */}
                <section className='social' >
                    <div className='social-logo'>
                        <Link to='/home'><img src='/assets/images/logo.png' alt='SideHustl' /></Link>
                    </div>
                    <h2 className='playFairDisplay less-hassle-title'>Less Hassle, More Hustl</h2>

                </section>


<div >
                <section className='discover' 

                style={{ textAlign: "center" }}
                >
                    <h3>Discover</h3>
                    <ul className='discover-links'>
                        <li>
                            <Link to='/'>{user?.userType === "HUSTLER" ? 'New Jobs' : 'Home'}</Link>
                        </li>
                        {accessToken && (userType === "DIRECTOR" || userType === "HUSTLER") ? (
                            <li><Link to='/messages'>Messages</Link></li>
                        ) : (
                            <li><Link to='/about-us'>About Us</Link></li>
                        )}
                        {user?.userType === "DIRECTOR" && (
                            <>
                                <li><Link to='/hustlrs-lists'>Hustlrs</Link></li>
                                <li><Link to='/your-task-list'>Your Jobs</Link></li>
                            </>
                        )}
                        {user?.userType === "HUSTLER" && (
                            <li><Link to='/hustlr-jobs'>Your Jobs</Link></li>
                        )}
                        {accessToken && (userType === "DIRECTOR" || userType === "HUSTLER") && (
                            <li><Link to='https://sidehustlsupport.zendesk.com/' target='_blank'>Help Center</Link></li>
                        )}
                    </ul>
                </section>

                                <section className='less-hassle '>
                                <ul className='social-icons' >
                        <li><Link to="https://www.facebook.com/sidehustl.ca" target='_blank' aria-label='Facebook'><img src='/assets/images/facebook.png' alt='Facebook' /></Link></li>
                        <li><Link to="https://www.instagram.com/sidehustl.ca/" target='_blank' aria-label='Instagram'><img src='/assets/images/insta.png' alt='Instagram' /></Link></li>
                        <li><Link to="https://x.com/SideHustlCA" target='_blank' aria-label='Twitter'><img src='/assets/images/twiter.png' alt='Twitter' /></Link></li>
                        <li><Link to="https://www.linkedin.com/company/sidehustl/" target='_blank' aria-label='LinkedIn'><img src='/assets/images/linkdin.png' alt='LinkedIn' /></Link></li>
                        <li><Link to="https://www.youtube.com/@SideHustlCA" target='_blank' aria-label='YouTube'><img src='/assets/images/youtube.png' alt='YouTube' /></Link></li>
                    </ul>
                </section>

                </div>
            </div>

            <div className='copy--right text-center' style={{ borderTop: '1px solid #272730' }}>
                        <p className='text-white mb-0'>Copyright © 2024 SideHustl</p>
                    </div>
            {/* Styles */}

                    {isMobileView && !hideFooterPaths.includes(currentPath) && (
        <div className='copy--right text-center' style={{ borderTop: '1px solid #272730' }}>
          <p className='text-white mb-0'>.</p>
        </div>
      )}

            

            <style jsx>{`
            .social-logo img {
    // width: 300px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
}

                .footer {
                    background-color: #1F1F28;
                    color: #fff;
                    padding: 20px 10px;
                    text-align: center;
                }
                .container {
                    max-width: 1200px;
                    // margin: 0 auto;
                //     display: grid;
                //     grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
                //  gap: 40px;
                }
                .less-hassle {

                    // text-align: center;
                    // margin-bottom: 20px;
                    //     // border: 2px solid #ffffff; /* White border with 2px thickness */
                    //         width: 500px; /* Set width to your desired value, e.g., 300px */
                    //       height: auto; /* Set the desired height */
                        //   display:flex;
                          align-item:center;
                            //   display: flex; 
    justify-content: center; 
    align-items: center; 
                }
                .less-hassle-title {
                            white-space: nowrap; /* Prevent text from wrapping */
                margin-top:20px;
                font-size: 30px;
                color: #ffffff;
                }
                .less-hassle-subtitle {
                    font-size: 22px;
                    font-weight: bold;
                    color: #fff;
                }
                .newsletter, .discover, .social {
                    margin-bottom: 20px;
                }
                .newsletter-title {
                    font-size: 24px;
                    margin-bottom: 15px;
                }
                .newsletter-input {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 10px;
                }
                .input {
                    padding: 10px;
                    border-radius: 5px 0 0 5px;
                    border: 1px solid #ccc;
                    flex: 1;
                }
                .btn.subscribe-btn {
                    background-color: #28a745;
                    color: #fff;
                    padding: 10px 20px;
                    border-radius: 0 5px 5px 0;
                    border: none;
                    cursor: pointer;
                    transition: background-color 0.3s;
                }
                .btn.subscribe-btn:hover {
                    background-color: #218838;
                }
                .newsletter-checkbox {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .newsletter-checkbox label {
                    margin-left: 5px;
                    font-size: 14px;
                    color: #ccc;
                }
                .discover h3, .social h3 {
                    font-size: 20px;
                    margin-bottom: 15px;
                }
                .discover-links, .social-icons {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }
                .discover-links li, .social-icons li {
                    margin-bottom: 10px;
                }
 .discover-links li a {
        text-decoration: none; /* Removes the underline */
        color: white; /* Sets the text color to white */
    }
                .social-icons {
                    display: flex;
                    justify-content: center;
                    gap: 15px;
                }
                .social-icons img {
                    width: 30px;
                    height: 30px;
                    transition: transform 0.3s;
                }
                .social-icons img:hover {
                    transform: scale(1.1);
                }
                .footer-copyright {
                    border-top: 1px solid #444;
                    padding-top: 20px;
                    font-size: 14px;
                    color: #ccc;
                }

                            .social{
                            // border:1px solid #FFF;
                            // width:500px;
                            // margin-left:-50px;
                                // margin-right: 100px; /* Adjust the value as needed */
                                 display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: auto; /* Full viewport height */
            text-align: center;

                            }

            `}</style>
        </footer>
        </>

    );
};

export default FooterMobile;
