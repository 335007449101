import React, { useState } from "react";
import { authAxios } from "../../services/config";
import moment from "moment";
import { dateFormat } from "../../utils/helper";
const ConfirmProgressTask = ({
  setshowConfirmProgressTask,
  handleSubmitProgressTask,
  setcheckboxValue,
  taskID,
  allId,
  setallId,
  handleCompleteReccuringJob,
  setTaskDetail,
  TaskDetail
}) => {


  const handleClose = () => {
    setshowConfirmProgressTask(false)
    if (setallId) {
      setallId((prev) => ({
        ...prev,
        intervalId: "",
        subscriptionId: "",
      }))
      setTaskDetail([])
    }

  }

  


  const handleSubmit = () => {
    if (allId?.subscriptionId) {
      handleCompleteReccuringJob()
      setTaskDetail([])
    } else {
      handleSubmitProgressTask()
    }
  }

  return (
    <div>
      <div
        className={`deletemodal modal ${setshowConfirmProgressTask ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-center">Please Confirm</h4>
              <button
                type="button"
                className="close"
                onClick={handleClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-5 pb-5 confirmation--modal">
              {allId?.subscriptionId ? (
                <h5 className="mb-0">
                  Are you sure this reoccurring job for<br />  {TaskDetail?.latestCounterOffer?.finalDate ? (
                    <span>
                    {dateFormat(new Date())}
                  </span>
                  ) : (
                    <span>
                    {dateFormat(new Date())}
                  </span>
                  )}  has been completed?
                </h5>
              ) : (
                <h5>Are you sure you have completed this Job</h5>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn"
                onClick={handleClose}
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                className="btn btn-dark darkbtn-hover"
                onClick={handleSubmit} // Call endSubscription when "Yes" is clicked
              >
                <span>Yes</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${setshowConfirmProgressTask ? "show" : ""}`}
      ></div>
    </div>
  );
};

export default ConfirmProgressTask;
