import React, { useEffect, useState } from "react";
import Select from "react-select";
import { IoIosCloseCircleOutline, IoIosSearch } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { CanadianCity } from "../../utils/constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  BsChatRightText,
  BsChevronDown,
  BsListCheck,
  BsListTask,
  BsSend,
} from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import {
  AiFillHeart,
  AiOutlineCalendar,
  AiOutlineHeart,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { TbMoneybag } from "react-icons/tb";
import ShowOfferModal from "./ShowOfferModal";
import { GrLocation } from "react-icons/gr";
import { BiSolidCaretLeftSquare } from "react-icons/bi";
import FilterRightSide from "./FilterRightSide";
import { authAxios, withoutAuthAxios } from "../../services/config";
import { toast } from "react-toastify";
import { setReportFormatDate } from "../../assets/Helper";
import ShowInterestModal from "./ShowInterestModal";
import Wishlistmodal from "./Wishlistmodal";
import DeletewishlistModal from "./DeletewishlistModal";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import CanceljobModal from "./CanceljobModal";
import { saveServices, saveTaskOptions } from "../../Redux/Reducers/appSlice";
import { State, City } from "country-state-city";
import { PiEye } from "react-icons/pi";
import IsLoggedinHOC from "../../common/IsLoggedInHOC";
import ShowIntersetDisableModal from "../../common/Modal/ShowIntersetDisableModal";
import ShowCancelDisableModal from "../../common/Modal/ShowCancelDisableModal";
import { IoInformationCircleOutline } from "react-icons/io5";
import {
  BudgetFormat,
  displayBudget,
  handleDecimalNumber,
  payloadBudget,
  staticHustlerFilter,
  toCamelCase,
} from "../../utils/helper";
import { LuListChecks, LuPartyPopper } from "react-icons/lu";
import { GoArrowRight, GoCheckbox } from "react-icons/go";
import { Tooltip } from "react-tooltip";
import { ImCancelCircle } from "react-icons/im";
import CounterOfferJobModel from "../../common/Modal/CounterOfferJobModel";
import { useSocket } from "../../contexts/SocketContext";
import Pagination from "../../common/Pagination";
import AcceptIncomingJobModel from "./AcceptIncomingJobModel";
import { FaRegCircleCheck } from "react-icons/fa6";
import ShowRejectedReason from "./ShowRejectedReason";
import CancelIncomingJobModel from "./CancelIncomingJobModel";
import ConfirmDeniedJobModel from "../../common/ConfirmDeniedJobModel";

const HustlerHome = (props) => {
  const { setLoading } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id;
  const socket = useSocket();
  const { interestedTask } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [searchKeywords, setsearchKeywords] = useState("");
  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [stateCode, setStateCode] = useState("");
  const [activeTab, setActiveTab] = useState("new-jobs");
  const [userDetails, setuserDetails] = useState([]);
  const [showCounterOfferList, setShowCounterOfferList] = useState(true);
  const [showCounterOffermodel, setshowCounterOffermodel] = useState(false);
  const [husterActiveJobs, sethusterActiveJobs] = useState([]);
  const [wishlistJobs, setwishlistJobs] = useState([]);
  const [clickedItems, setClickedItems] = useState({});
  const [openModal, setopenModal] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [wishlistId, setwishlistId] = useState("");
  const [showInterestModal, setShowInterestModal] = useState(false);
  const [TaskDetails, setTaskDetails] = useState([]);
  const [appliedJobs, setappliedJobs] = useState([]);
  const [cancelId, setcancelId] = useState("");
  const [offerDetails, setOfferDetails] = useState([]);
  const [showCanceljobModal, setshowCanceljobModal] = useState(false);
  const [CancelTaskid, setCancelTaskid] = useState("");
  const [allCounterOfferdetails, setallCounterOfferdetails] = useState([]);
  const [baseProfileBase, setBaseProfileBase] = useState("");
  const [showIntersetDisableModal, setshowIntersetDisableModal] =
    useState(false);
  const [showCancelDisableModal, setshowCancelDisableModal] = useState(false);
  const [showOfferModel, setshowOfferModel] = useState(false);
  const [taskid, setTaskId] = useState("");
  const [incomingJobtaskId, setincomingJobtaskId] = useState("");
  const [incomingJobs, setincomingJobs] = useState([]);
  const [showdenieJobModel, setshowdenieJobModel] = useState(false);
  const [selectedSort, setSelectedSort] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [prevIntrestedTask, setPrevIntrestedTask] = useState("");

  const [showIncomingModel, setshowIncomingModel] = useState({
    acceptJob: false,
    cancelJob: false,
    counterJob: false,
  });

  const [showRejectionModel, setshowRejectionModel] = useState(false);
  const [rejectionDetails, setRejectionDetails] = useState([]);

  const [currentPage, setcurrentPage] = useState(1);
  const [ongoingPagination, setongoingPagination] = useState("");
  const [postsPerPage, setpostsPerPage] = useState(10);
  const [totalPosts, settotalPosts] = useState(0);
  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const firstProvince = useSelector(
    (state) => state.auth.user.hustlerDetails.province
  );

  useEffect(() => {
    // Function to parse task ID from URL
    const getTaskIdFromUrl = () => {
      const searchParams = new URLSearchParams(location.search);
      const taskIdFromUrl = searchParams.get("taskId");
      if (taskIdFromUrl) {
        getSpecificTaskById(taskIdFromUrl);
      }
    };
    getTaskIdFromUrl();
  }, [location.search]);

  const fetchhustlerDetails = async () => {
    await authAxios()
      .get("/users/profile")
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          const hustlerProvince = {
            label: resData.data.hustlerDetails.province,
            value: resData.data.hustlerDetails.province,
          };
          const hustlerCity = {
            label: resData.data.hustlerDetails.location,
            value: resData.data.hustlerDetails.location,
          };
          const data = {
            province: resData.data.hustlerDetails.province,
            location: resData.data.hustlerDetails.location,
          };
          if (localStorage.getItem("Province")) {
            setselectedProvince({
              label: localStorage.getItem("Province"),
              value: localStorage.getItem("Province"),
            });
          }
          if (localStorage.getItem("CityName")) {
            const allcities = JSON.parse(localStorage.getItem("CityName")).map(
              (item) => ({ label: item, value: item })
            );
            setSelectedCity(allcities);
          }

          setuserDetails(resData?.data);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchIncomingJobs = async (number) => {
    setLoading(true);
    await authAxios()
      .get(
        `/task/get-assigned-task?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      // .get(`/task/get-assigned-task`)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          setincomingJobs(resData?.data);
          settotalPosts(resData?.count?.total);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleIncomingTask = (e, item, key) => {
    if (key == "accept") {
      setshowIncomingModel({
        acceptJob: true,
        cancelJob: false,
        counterJob: false,
      });
      setincomingJobtaskId(item?._id);
    } else if (key == "view") {
      setshowIncomingModel({
        acceptJob: false,
        cancelJob: false,
        counterJob: true,
      });
      setShowCounterOfferList(true);
      setTaskDetails(item);
      setincomingJobtaskId(item?._id);
      setcancelId(item?._id);
      handleViewCounterOfferIncomming(item?._id);
    } else if (key == "cancel") {
      setshowIncomingModel({
        acceptJob: false,
        cancelJob: true,
        counterJob: false,
      });
      setincomingJobtaskId(item?._id);
    } else if (key == "counter") {
      setshowIncomingModel({
        acceptJob: false,
        cancelJob: false,
        counterJob: true,
      });
      setShowCounterOfferList(false);
      setTaskDetails(item);
      setincomingJobtaskId(item?._id);
    } else {
    }
  };

  const handleViewCounterOfferIncomming = async (id) => {
    await authAxios()
      .get(`/task/get-counter-offer/${id}`)
      .then((response) => {
        const resData = response.data;

        const counterOfferData = resData.data.find(
          (item) => item.hustlerId === userDetails._id
        );

        if (counterOfferData) {
          const hustlerCounterOffers = counterOfferData.counterOffers.filter(
            (offer) => offer.userType === "HUSTLER"
          );
          const DirectorCounterOffers = counterOfferData.counterOffers.filter(
            (offer) => offer.userType !== "HUSTLER"
          );

          const allOffers = {
            hustlerCounterOffers: hustlerCounterOffers,
            directorCounterOffers: DirectorCounterOffers,
          };
          setallCounterOfferdetails(allOffers);
        } else {
          setallCounterOfferdetails([]);
          toast.info("Creatr hasn't sent an offer yet.");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const showRejectedReason = async (item) => {
    if (item.taskType === "rejected") {
      setshowRejectionModel(false);
      setLoading(true);
      await authAxios()
        .get(`/task/rejected/${item._id}`)
        .then((response) => {
          setLoading(false);
          const resData = response.data;
          if (resData.status == 1) {
            setshowRejectionModel(true);
            setRejectionDetails(resData.data.rejectionDetails);
          } else {
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const handleCancelJob = async ({ finalprice, reason }) => {
    const taskId = incomingJobtaskId;

    setLoading(true);
    await authAxios()
      .put(`task/cancel-tasks/${taskId}`, {
        // finalPrice: finalprice,
        reason: reason,
      })
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          socket.emit("taskCancelled", resData.data);
          toast.success(resData?.message);
          handleNavigateTabClick(ongoingPagination);
          // fetchIncomingJobs();
          setshowIncomingModel({
            acceptJob: false,
            cancelJob: false,
            counterJob: false,
          });
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleDeniedJob = async (data) => {
    setshowdenieJobModel(true);
    setshowCounterOffermodel(false);
    setallCounterOfferdetails([]);
    setshowIncomingModel({
      acceptJob: false,
      cancelJob: false,
      counterJob: false,
    });

    if (showdenieJobModel == true) {
      if (ongoingPagination == "interested-jobs") {
        setLoading(true);
        await authAxios()
          .post(`/taskproposal/cancel/${cancelId}`, { comment: data })
          .then((response) => {
            setLoading(false);
            const resData = response.data;
            if (resData.status == 1) {
              socket.emit("cancelledProposalByHustler", resData.data);
              // fetchappliedJobs(1);

              // setcurrentPage(1);
              handleNavigateTabClick(ongoingPagination);
              setshowdenieJobModel(false);
              toast.success(resData?.message);
            } else {
              toast.error(resData?.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.message);
          });
      } else {
        setLoading(true);
        await authAxios()
          .put(`task/cancel-tasks/${cancelId}`, { reason: data })
          .then((response) => {
            const resData = response.data;
            setLoading(false);
            if (resData.status == 1) {
              // fetchIncomingJobs(1);
              // setcurrentPage(1);
              handleNavigateTabClick(ongoingPagination);
              setshowdenieJobModel(false);
              toast.success(resData?.message);
            } else {
              toast.error(resData?.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.message);
          });
      }
    }
  };

  const handleCancelDeniedJob = () => {
    setshowdenieJobModel(false);
  };

  const handleSelectCity = (option) => {
    if (option) {
      setSelectedCity(option);
      if (option.length > 0) {
        const setItem = option.map((item) => item?.label);
        localStorage.setItem("CityName", JSON.stringify(setItem));
      } else {
        localStorage.removeItem("CityName");
      }
    }
  };

  const handleSearch = async (number, data) => {
    if (number) {
      setcurrentPage(1);
      setongoingPagination("search-jobs");
      setActiveTab("new-jobs");
    }
   // setSelectedSort("");
    let payload = "";
    payload = {
      query: localStorage.getItem("Keyword") || "",
      province: localStorage.getItem("Province") || "",
      cityNames: JSON.parse(localStorage.getItem("CityName")) || [],
      sort: selectedSort?.value
    };

    setLoading(true);
    await authAxios()
      .post(
        `/search/search-task-for-hustler?page=${
          number || currentPage
        }&limit=${postsPerPage}`,
        data|| payload
      )
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          settotalPosts(resData.data.totalTasks);
          sethusterActiveJobs(resData?.data?.tasks);
        } else {
          toast.error(resData?.message);
          sethusterActiveJobs([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
        sethusterActiveJobs([]);
      });
  };

  const handleSortChange = async (sortOption) => {
    if (sortOption?.value == selectedSort?.value) {
      setSelectedSort("");
      fetchAlltask(1);
      
    } else if (sortOption) {
      setcurrentPage(1);
      setongoingPagination("search-jobs");
      setActiveTab("new-jobs");
       setSelectedSort(sortOption);
      let payload = "";
    payload = {
      query: localStorage.getItem("Keyword") || "",
      province: localStorage.getItem("Province") || "",
      cityNames: JSON.parse(localStorage.getItem("CityName")) || [],
      sort: sortOption?.value
    };
      handleSearch(1,payload)
     
    }
  };

  const handleSelectSortBy = async (data, number) => {
    const prevData = {
      query: selectedService?.value,
      location: selectedCity?.value,
      province: selectedProvince?.label,
      sort: selectedSort?.value,
    };

    await authAxios()
      .post(`/search/search-task-for-hustler`, data || prevData)
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status === 1) {
          settotalPosts(resData?.data?.totalCount);
          sethusterActiveJobs(resData?.data?.tasks);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchAlltask = async (number) => {
    setongoingPagination("new-jobs");
    setLoading(true);
    await authAxios()
      .get(
        `/task/get-all-tasks-list?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          settotalPosts(resData?.count?.total);
          sethusterActiveJobs(resData?.data);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const getSpecificTaskById = async (task_Id) => {
    setLoading(true);
    await authAxios()
      .get(`/task/get-all-tasks-list?page=${1}&limit=${100}`)
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          const specificTask = resData?.data.find(
            (task) => task._id === task_Id
          );
          if (specificTask) {
            handleAddShowInterestItem(specificTask, interestedTask);
          }
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchWishlisttask = async (number) => {
    setongoingPagination("saved-jobs");
    setLoading(true);
    await authAxios()
      .get(
        `/wishlist/users-wishlist?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          settotalPosts(resData?.count?.total);
          setwishlistJobs(resData?.data);
        } else {
          toast.error(resData?.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchWishlistAllJobs = async () => {
    setLoading(true);
    await authAxios()
      .get(`/wishlist/users-wishlist`)
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          setwishlistJobs(resData?.data);
        } else {
          toast.error(resData?.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchappliedJobs = async (number) => {
    setLoading(true);
    await authAxios()
      .get(
        `taskproposal/hustler-interested-jobs?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          settotalPosts(resData?.count?.total);
          const filteredData = resData?.data.filter(
            (item) => !item.hasOwnProperty("cancellation")
          );
          setappliedJobs(filteredData);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleAddWishlistitem = async (id) => {
    setwishlistId(id);
    setopenModal(true);

    if (openModal == true) {
      setLoading(true);
      await authAxios()
        .post(`/wishlist/add-to-wishlist/${wishlistId}`)
        .then((response) => {
          setLoading(false);
          const resData = response.data;
          if (resData.status == 1) {
            setcurrentPage(1);
            toast.success(resData?.message);
            handleNavigateTabClick(ongoingPagination);
            // fetchWishlistAllJobs();
            // fetchAlltask(1);
            // setcurrentPage(1);
            setopenModal(false);
            setClickedItems({ ...clickedItems, [id]: true });
          } else toast.error(resData.error);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const handleAddShowInterestItem = (item, interestedTask) => {
    setTaskId(item?._id);
    setTaskDetails(item);
    setShowInterestModal(true);
    if (interestedTask) {
      setPrevIntrestedTask(interestedTask);
    }
  };

  const handleShowDisableInterest = (item) => {
    setshowIntersetDisableModal(true);
    setTaskDetails(item);
  };

  const handleShowCancelDisable = (item) => {
    setTaskDetails(item);
    setshowCancelDisableModal(true);
  };

  const handleDeleteWishlistitem = async (id) => {
    setwishlistId(id);
    setopenDeleteModal(true);

    if (openDeleteModal == true) {
      setLoading(true);
      await authAxios()
        .delete(`/wishlist/remove-from-wishlist/${wishlistId}`)
        .then((response) => {
          setLoading(false);
          const resData = response.data;
          if (resData.status == 1) {
            toast.success(resData?.message);
            setopenDeleteModal(false);
            handleNavigateTabClick(ongoingPagination);
            // if (ongoingPagination == "new-jobs") {
            //   setcurrentPage(1);
            //   fetchAlltask(1);
            //   fetchWishlistAllJobs();
            // } else {
            //   setcurrentPage(1);

            //   fetchWishlisttask(1);
            // }
          } else {
            toast.error(resData.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const handleCancelPopup = (id) => {
    setshowCanceljobModal(true);
    setCancelTaskid(id);
  };

  const CancelTask = async (data) => {
    setLoading(true)
    const payload = data;
    await authAxios()
      .post(`taskproposal/cancel/${CancelTaskid}`, payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false)
        if (resData.status == 1) {
         
          setshowCanceljobModal(false);
          // fetchappliedJobs(1);
          // setcurrentPage(1);
          handleNavigateTabClick(ongoingPagination);

          fetchhustlerDetails();
          //  fetchWishlisttask();
        } else {
          
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.response?.data?.message);
      });
  };

  const getTaskListData = async () => {
    setLoading(true);
    await withoutAuthAxios()
      .get(`/services/get-all-services`)
      .then((response) => {
        const resData = response?.data;
        
        setLoading(false);
        if (resData?.status == 1) {
          const services = [];
          resData?.data.map((item) => {
            services.push({
              label: toCamelCase(item?.serviceName),
              value: toCamelCase(item?.serviceName),
            });
          });

          const category = [];

          resData?.data?.map((item) => {
            item?.category?.map((cat) => {
              category?.push(cat);
            });
          });

          dispatch(saveServices(services));
          dispatch(saveTaskOptions(category));

          //  dispatch(saveServices(services));
        } else {
          toast.error(resData?.message?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  const getOfferDetails = async (id) => {
    setLoading(true)
    await authAxios()
      .get(`/send-offer/get-offer/${userDetails._id}/${id}`)
      .then((response) => {
        const resData = response?.data;
        setLoading(false)
        if (resData?.status == 1) {
          setOfferDetails(resData.data);
          setshowOfferModel(true);
        } else {
          toast.error(resData?.message?.message);
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.response?.data?.message);
      });
  };

  const handleSelectProvince = (e) => {
    if (e) {
      localStorage.setItem("Province", e?.label);
      setStateCode(e?.value);
      setselectedProvince(e);
      setSelectedCity([]);
    } else {
      localStorage.removeItem("Province");
      localStorage.removeItem("CityName");
      setcityList([]);
      setSelectedCity([]);
      setStateCode("");
      setselectedProvince([]);
    }
  };

  const handleUpdateJob = async (data) => {
    const payload = {
      task: TaskDetails?.taskDetails?._id,
      offer: payloadBudget(`${data?.offer}`),
      description: data?.description,
      proposalDate: data.proposalDate,
    };

    setLoading(true);

    await authAxios()
      .post(`/taskproposal/create`, payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData.status == 1) {
          // fetchappliedJobs(1);
          // setcurrentPage(1);
          handleNavigateTabClick(ongoingPagination);
          fetchhustlerDetails();

          setshowIntersetDisableModal(false);
          setTaskDetails([]);
          toast.success(resData?.message);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const CounterOffer = async (data) => {
    setLoading(true);

    await authAxios()
      .post(`/task/counter-offer`, data)
      .then((response) => {
        const resData = response.data;

        setLoading(false);

        if (resData.status == 1) {
          socket.emit("counterOfferbyHustler", resData.data);
          toast.success(resData?.message);

          // fetchappliedJobs();
          // fetchIncomingJobs();
          fetchhustlerDetails();

          handleNavigateTabClick(ongoingPagination);

          setshowCounterOffermodel(false);
          setshowIncomingModel(false);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleViewCounterOffer = async (item) => {
    setLoading(true)
    setcancelId(item._id);
    await authAxios()
    
      .get(`/task/get-counter-offer/${item.taskDetails._id}`)
      .then((response) => {
        const resData = response.data;

        const counterOfferData = resData.data.find(
          (item) => item.hustlerId === userDetails._id
        );
        setLoading(false)
        if (counterOfferData) {
          const hustlerCounterOffers = counterOfferData.counterOffers.filter(
            (offer) => offer.userType === "HUSTLER"
          );
          const DirectorCounterOffers = counterOfferData.counterOffers.filter(
            (offer) => offer.userType !== "HUSTLER"
          );

          const allOffers = {
            hustlerCounterOffers: hustlerCounterOffers,
            directorCounterOffers: DirectorCounterOffers,
          };
          setallCounterOfferdetails(allOffers);
          setShowCounterOfferList(true);
          setTaskDetails(item.taskDetails);
          setincomingJobtaskId(item.taskDetails._id);
          setshowCounterOffermodel(true);
        } else {
          setallCounterOfferdetails([]);
          toast.info("Creatr hasn't sent an offer yet.");
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.response?.data?.message);
      });
  };

  const handleAcceptJob = async () => {
    setLoading(true);
    await authAxios()
      .put(`task/accept-task/${incomingJobtaskId}`)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          socket.emit("taskAccepted", resData.data);
          toast.success(resData?.message);

          /*  fetchappliedJobs(1);
          setcurrentPage(1);
          fetchhustlerDetails();
          getTaskListData();
          setshowCounterOffermodel(false);
          setshowIncomingModel(false);
          setallCounterOfferdetails([]);
          */
          fetchhustlerDetails();
          navigate("/hustlr-jobs");
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleFirstSearch = async () => {
    try {
      setongoingPagination("search-jobs");
      setActiveTab("new-jobs");
      setcurrentPage(1);
      const data = {
        query: localStorage.getItem("Keyword") || "",
        province: localStorage.getItem("Province") || "",

        cityName: JSON.parse(localStorage.getItem("CityName")) || [],
      };
      setLoading(true);
      const searchResponse = await authAxios().post(
        `/search/search-task-for-hustler?page=${currentPage}&limit=${postsPerPage}`,
        data
      );
      setLoading(false);
      const searchData = searchResponse.data;
      if (searchData.status !== 1) {
        toast.error(searchData.message);
        sethusterActiveJobs([]);
        return;
      }

      if (searchData.message === "Search job retrieved successfully") {
        settotalPosts(searchData.data.totalTasks);
        sethusterActiveJobs(searchData.data.tasks);
      } else if (
        searchData.message ===
        "No jobs found matching search criteria, showing all jobs in province"
      ) {
        settotalPosts(searchData.data.totalTasks);
        sethusterActiveJobs(searchData.data.tasks);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || error.message);
      sethusterActiveJobs([]);
    }
  };

  useEffect(() => {
    setprovinceList(
      [ { value: "BC", label: "British Columbia" },
     ]
     );
    // setprovinceList(
    //   State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
    //     value: isoCode,
    //     label: name,
    //   }))
    // );
  }, []);

  useEffect(() => {
    const getAllprovinceList = State.getStatesOfCountry("CA").map(
      ({ isoCode, name }) => ({
        value: isoCode,
        label: name,
      })
    );

    const newFilter = localStorage.getItem("Province") || firstProvince;
    const filteredProvince = getAllprovinceList.filter(
      (item) => item?.label == newFilter
    );

    // setcityList(
    //   City.getCitiesOfState("CA", filteredProvince[0]?.value).map(
    //     ({ name }) => ({
    //       value: name,
    //       label: name,
    //     })
    //   )
    // );
  }, []);

  useEffect(() => {
    setcityList(
      [ {"value": "Kamloops", "label": "Kamloops"},
        {"value": "Kelowna","label": "Kelowna"},
        {"value": "Peachland","label": "Peachland"},
        {"value": "Penticton","label": "Penticton"},
        {"value": "Vernon","label": "Vernon"},
        {"value": "West Kelowna","label": "West Kelowna"},
      ]
    )
    if (stateCode) {
      // setcityList(
      //   City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
      //     value: name,
      //     label: name,
      //   }))
      // );
    }
  }, [stateCode]);

  const handleProfileBaseMode = (buttonName) => {
    setBaseProfileBase(buttonName === baseProfileBase ? null : buttonName);
  };

  const handleRemoveActiveClass = () => {
    setBaseProfileBase(null);
  };

  const handleTabClick = (id) => {
    if (id === activeTab) {
      return;
    }

    setActiveTab(id);
    if (id == "interested-jobs") {
      setcurrentPage(1);
      fetchappliedJobs(1);
      setongoingPagination("interested-jobs");
    } else if (id == "search-jobs") {
      fetchWishlistAllJobs();
      setcurrentPage(1);

      setongoingPagination("search-jobs");

      handleSearch(1);
    } else if (id == "new-jobs") {
      fetchWishlistAllJobs();
      if (
        localStorage.getItem("Province") ||
        localStorage.getItem("CityName") ||
        localStorage.getItem("Keyword")
      ) {
        setcurrentPage(1);

        setongoingPagination("search-jobs");

        handleSearch(1);
      } else {
        setcurrentPage(1);
        setongoingPagination("new-jobs");
        fetchAlltask(1);
      }
    } else if (id == "saved-jobs") {
      fetchWishlistAllJobs();
      setcurrentPage(1);
      setongoingPagination("saved-jobs");
      fetchWishlisttask(1);
    } else if (id == "incoming-jobs") {
      setcurrentPage(1);
      setongoingPagination("incoming-jobs");
      fetchIncomingJobs(1);
    }
  };

  const handleNavigateTabClick = (id) => {
    setActiveTab(id);
    if (id == "interested-jobs") {
      setcurrentPage(1);
      fetchappliedJobs(1);
      setongoingPagination("interested-jobs");
    } else if (id == "search-jobs") {
      fetchWishlistAllJobs();
      setcurrentPage(1);

      setongoingPagination("search-jobs");

      handleSearch(1);
    } else if (id == "new-jobs") {
      fetchWishlistAllJobs();
      if (
        localStorage.getItem("Province") ||
        localStorage.getItem("CityName") ||
        localStorage.getItem("Keyword")
      ) {
        setcurrentPage(1);

        setongoingPagination("search-jobs");

        handleSearch(1);
      } else {
        setcurrentPage(1);
        setongoingPagination("new-jobs");
        fetchAlltask(1);
      }
    } else if (id == "saved-jobs") {
      fetchWishlistAllJobs();
      setcurrentPage(1);
      setongoingPagination("saved-jobs");
      fetchWishlisttask(1);
    } else if (id == "incoming-jobs") {
      setcurrentPage(1);
      setongoingPagination("incoming-jobs");
      fetchIncomingJobs(1);
    }
  };

  useEffect(() => {
    setActiveTab(id || "new-jobs");
    fetchhustlerDetails();
   // getTaskListData();
    if (id == "interested-jobs") {
      setcurrentPage(1);
      fetchappliedJobs(1);
      setongoingPagination("interested-jobs");
    } else if (id == "incoming-jobs") {
      setcurrentPage(1);
      setongoingPagination("incoming-jobs");
      fetchIncomingJobs(1);
    } else {
      fetchWishlistAllJobs();
      if (
        localStorage.getItem("Province") ||
        localStorage.getItem("CityName") ||
        localStorage.getItem("Keyword")
      ) {
        if (localStorage.getItem("Keyword")) {
          setsearchKeywords(localStorage.getItem("Keyword"));
        }

        handleFirstSearch();
      } else {
        fetchAlltask();
      }
    }
  }, [id]);

  useEffect(() => {
    if (ongoingPagination == "new-jobs") {
      fetchAlltask();
      fetchWishlistAllJobs();
    } else if (ongoingPagination == "saved-jobs") {
      fetchWishlisttask();
    } else if (ongoingPagination == "interested-jobs") {
      fetchappliedJobs();
    } else if (ongoingPagination == "search-jobs") {
      handleSearch();
    } else if (ongoingPagination == "incoming-jobs") {
      fetchIncomingJobs();
    } else {
    }
  }, [currentPage]);

  const handlewriteKeyboard = (e) => {
    setsearchKeywords(e.target.value);
    localStorage.setItem("Keyword", e.target.value);
  };

  const handleViewOffer = (data) => {
    getOfferDetails(data._id);
  };

  return (
    <>
      <section className="director--search--hustler pt-117 hustler--home--page">
        <div className="custom--container m-auto pt-sm-5 pb-5 pt-0">
          <div className="container--filter">
            <div className="filter--data--search">
              <div className="filter--search--col">
                <div className="search--bar--banner flex sm-flex-col sm-justify-start responsive--search--bar searchbanner--mutiple">
                  <input
                    required
                    placeholder="Enter keywords"
                    className="need--select"
                    value={searchKeywords}
                    onChange={(e) => handlewriteKeyboard(e)}
                  />

                  <div className="city--zip">
                    <Select
                      options={provinceList}
                      isClearable
                      className="select--custom province--select"
                      value={selectedProvince}
                      placeholder={`Select Province`}
                      onChange={handleSelectProvince}
                    />
                  </div>

                  <div className="city--zip filter--city">
                    <Select
                      isMulti
                      options={cityList}
                      className="select--custom"
                      value={selectedCity}
                      placeholder={`Select City`}
                      onChange={handleSelectCity}
                    />
                  </div>

                  <button
                    className="btn btn-dark submit--need"
                    onClick={() => handleSearch(1)}
                  >
                    Filter Jobs
                  </button>
                </div>
              </div>

              <div className="task--lists--profile--based pt-2 pt-sm-5">
                <div className="base--profile--your">
                  <h1 className="text--dark playFairDisplay text-left heading-h1 mb-0">
                    Let's get to work!
                  </h1>
                  <div
                    className="arrow--view--profilebase"
                    onClick={() => handleProfileBaseMode("profile--toggle")}
                  >
                    <BiSolidCaretLeftSquare />
                  </div>
                </div>

                <div className="para--tabing mb-1 mb-sm-5 mt-2">
                  {activeTab === "new-jobs" && (
                    <p>All new jobs will be posted here.</p>
                  )}

                  {activeTab === "saved-jobs" && (
                    <p>These are your saved jobs.</p>
                  )}

                  {activeTab === "interested-jobs" && (
                    <p>Here are all the jobs you have applied for.</p>
                  )}

                  {activeTab === "incoming-jobs" && (
                    <p>
                      These are the new jobs requested by Creatrs who asked for
                      you.
                    </p>
                  )}
                </div>

                {openModal && (
                  <Wishlistmodal
                    setopenModal={setopenModal}
                    handleAddWishlistitem={handleAddWishlistitem}
                  />
                )}

                {openDeleteModal && (
                  <DeletewishlistModal
                    handleDeleteWishlistitem={handleDeleteWishlistitem}
                    setopenDeleteModal={setopenDeleteModal}
                  />
                )}

                <div className="director--list--task">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          activeTab === "new-jobs" ? "active" : ""
                        }`}
                        id="new-jobs-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#new-jobs"
                        type="button"
                        role="tab"
                        aria-controls="new-jobs"
                        aria-selected={
                          activeTab === "new-jobs" ? "true" : "false"
                        }
                        onClick={() => handleTabClick("new-jobs")}
                      >
                        New Jobs
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          activeTab === "saved-jobs" ? "active" : ""
                        }`}
                        id="saved-jobs-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#saved-jobs"
                        type="button"
                        role="tab"
                        aria-controls="saved-jobs"
                        aria-selected={
                          activeTab === "saved-jobs" ? "true" : "false"
                        }
                        onClick={() => handleTabClick("saved-jobs")}
                      >
                        Saved Jobs
                      </button>
                    </li>

                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          activeTab === "interested-jobs" ? "active" : ""
                        }`}
                        id="interested-jobs-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#interested-jobs"
                        type="button"
                        role="tab"
                        aria-controls="interested-jobs"
                        aria-selected={
                          activeTab === "interested-jobs" ? "true" : "false"
                        }
                        onClick={() => handleTabClick("interested-jobs")}
                      >
                        Applied Jobs
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          activeTab === "incoming-jobs" ? "active" : ""
                        }`}
                        id="incoming-jobs-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#incoming-jobs"
                        type="button"
                        role="tab"
                        aria-controls="incoming-jobs"
                        aria-selected={
                          activeTab === "incoming-jobs" ? "true" : "false"
                        }
                        onClick={() => handleTabClick("incoming-jobs")}
                      >
                        Incoming Jobs
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className={`tab-pane fade ${
                        activeTab === "new-jobs" ? "show active" : ""
                      }`}
                      id="new-jobs"
                      role="tabpanel"
                      aria-labelledby="new-jobs-tab"
                    >
                      <div className="list--tasks">
                        {husterActiveJobs && husterActiveJobs?.length > 0 ? (
                          husterActiveJobs.map((item, index) => (
                            <div
                              key={index}
                              className="created--task--lists process--task--list"
                            >
                              <div
                                className="new-jobs new--jobs-h"
                                style={{ zIndex: "auto" }}
                              >
                                <span>
                                  <BsListTask /> New Job
                                </span>
                              </div>
                              <div className="task--work">
                                <div
                                  className="task--items--header"
                                  style={{ marginBottom: "0px" }}
                                >
                                  <div className="task--add--info">
                                    <div className="user--task--info">
                                      <div className="task--info--img">
                                        <Link
                                          to={`/user-info/${item?.user?._id}`}
                                        >
                                          <img
                                            src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.user?.profilePic}`}
                                          />
                                        </Link>
                                      </div>
                                      <div className="task--info--details">
                                        <span>
                                          {item?.user?.fname}{" "}
                                          {item?.user?.lname}{" "}
                                        </span>
                                        <p>
                                          <img src="../assets/images/hustler-star.png" />{" "}
                                          {handleDecimalNumber(
                                            item?.user?.directorDetails?.ratings
                                          )}{" "}
                                        </p>
                                      </div>
                                    </div>
                                    {/* <h3>{item?.title}</h3> */}
                                  </div>
                                  <div className="task--items--edit--delete">
                                    <Tooltip
                                      id="add--wishlist--task"
                                      className="tooltip--task--wishlist"
                                    />
                                    <div
                                      className="delete--taks--items wislishadd"
                                      data-tooltip-id="add--wishlist--task"
                                      data-tooltip-content={
                                        wishlistJobs?.some(
                                          (job) => job?.taskId === item?._id
                                        )
                                          ? "Remove Job"
                                          : "Save Job"
                                      }
                                      data-tooltip-place="top"
                                      onClick={() => {
                                        if (
                                          wishlistJobs?.some(
                                            (job) => job?.taskId === item?._id
                                          )
                                        ) {
                                          handleDeleteWishlistitem(item._id);
                                        } else {
                                          handleAddWishlistitem(item._id);
                                        }
                                      }}
                                    >
                                      {wishlistJobs?.some(
                                        (job) => job?.taskId === item?._id
                                      ) ? (
                                        <AiFillHeart />
                                      ) : (
                                        <AiOutlineHeart />
                                      )}
                                    </div>

                                    <div className="edit--taks--items">
                                      <button
                                        className="custom--btn ligghtbtn-hover transparent--btn"
                                        onClick={() =>
                                          handleAddShowInterestItem(item)
                                        }
                                      >
                                        <span>Show Interest</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="task--items--details">
                                  <div
                                    className="task--detail-full"
                                    style={{ width: "100%" }}
                                  >
                                    <h2
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      className="hover-text"
                                      onClick={() =>
                                        handleAddShowInterestItem(item)
                                      }
                                    >
                                      {item?.title}
                                    </h2>
                                    <div className="service--category--task">
                                      <h3>{item?.serviceName}</h3>
                                    </div>
                                  </div>
                                </div>

                                <div className="task--items--content">
                                  <p>
                                    <span className="task--items--content--icon">
                                      <AiOutlineCalendar />
                                    </span>
                                    {setReportFormatDate(item?.dueDate)}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <TbMoneybag />
                                    </span>{" "}
                                    Budget: ${BudgetFormat(`${item?.budget}`)}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <GrLocation />
                                    </span>{" "}
                                    Area: {item?.cityName}
                                  </p>
                                </div>
                                <div className="task--items-footer">
                                  {/* <p>{item?.description}</p> */}
                                  <p>
                                    {item?.description?.length > 250
                                      ? `${item?.description?.substring(
                                          0,
                                          250
                                        )}...`
                                      : `${item?.description}`}
                                  </p>
                                </div>
                                <div className="task--items-application">
                                  <p>
                                    <img src="../assets/images/hustler--taskdone.png" />{" "}
                                    {item.applications} Applications
                                  </p>
                                </div>
                              </div>
                              {item?.oneTimePayment ? null : (
                                <div className="task--items-down--footer new--jobs-h">
                                  <p>
                                    Please note that this is a{" "}
                                    <b>{item?.subscriptionInterval}ly</b> job,
                                    and payment will be made on a{" "}
                                    <b>{item?.subscriptionInterval}ly</b> basis
                                    upon completion.{" "}
                                  </p>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div>No new jobs to display</div>
                        )}
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === "saved-jobs" ? "show active" : ""
                      }`}
                      id="saved-jobs"
                      role="tabpanel"
                      aria-labelledby="saved-jobs-tab"
                    >
                      <div className="list--tasks">
                        {wishlistJobs && wishlistJobs?.length > 0 ? (
                          wishlistJobs.map((item, index) => (
                            <div
                              key={index}
                              className="created--task--lists process--task--list"
                            >
                              <div
                                className="start--work"
                                style={{ zIndex: "auto" }}
                              >
                                <span>
                                  <GoCheckbox /> Saved Job
                                </span>
                              </div>
                              <div className="task--work">
                                <div
                                  className="task--items--header"
                                  style={{ marginBottom: "0px" }}
                                >
                                  <div className="task--add--info">
                                    <div className="user--task--info">
                                      <div className="task--info--img">
                                        <Link
                                          to={`/user-info/${item?.user?._id}`}
                                        >
                                          <img
                                            src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.user?.profilePic}`}
                                          />
                                        </Link>
                                      </div>
                                      <div className="task--info--details">
                                        <span>
                                          {item?.user?.fname}{" "}
                                          {item?.user?.lname}{" "}
                                        </span>
                                        <p>
                                          <img src="../assets/images/hustler-star.png" />{" "}
                                          {handleDecimalNumber(
                                            item?.user?.directorDetails?.ratings
                                          )}{" "}
                                        </p>
                                      </div>
                                    </div>
                                    {/* <h3>{item?.title}</h3> */}
                                  </div>
                                  <div className="task--items--edit--delete">
                                    <Tooltip
                                      id="remove-wishlist-task"
                                      className="tooltip--task--wishlist"
                                    />
                                    <div
                                      className="delete--taks--items"
                                      data-tooltip-id="remove-wishlist-task"
                                      data-tooltip-content="Remove from wishlist"
                                      onClick={() =>
                                        handleDeleteWishlistitem(item?.taskId)
                                      }
                                    >
                                      <RiDeleteBin6Line />
                                    </div>
                                    <div className="edit--taks--items">
                                      <button
                                        onClick={() =>
                                          handleAddShowInterestItem(item)
                                        }
                                        className="custom--btn ligghtbtn-hover transparent--btn"
                                      >
                                        <span>Show Interest</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="task--items--details">
                                  <div
                                    className="task--detail-full"
                                    style={{ width: "100%" }}
                                  >
                                    <h2>{item?.title}</h2>
                                    <div className="service--category--task">
                                      <h3>{item?.serviceName}</h3>
                                    </div>
                                  </div>
                                </div>

                                <div className="task--items--content">
                                  <p>
                                    <span className="task--items--content--icon">
                                      <AiOutlineCalendar />
                                    </span>
                                    {setReportFormatDate(item?.dueDate)}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <TbMoneybag />
                                    </span>{" "}
                                    Budget: ${BudgetFormat(`${item?.budget}`)}
                                    {/* | Members:{" "}
                                  {item?.members} */}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <GrLocation />
                                    </span>{" "}
                                    Area: {item?.cityName}
                                  </p>
                                </div>
                                <div className="task--items-footer">
                                  <p>
                                    {item?.description?.length > 250
                                      ? `${item?.description?.substring(
                                          0,
                                          250
                                        )}...`
                                      : `${item?.description}`}
                                  </p>
                                </div>
                                <div className="task--items-application">
                                  <p>
                                    <img src="../assets/images/hustler--taskdone.png" />{" "}
                                    {item?.applications} Applications
                                  </p>
                                </div>
                              </div>
                              {item?.oneTimePayment ? null : (
                                <div className="task--items-down--footer start--work">
                                  <p>
                                    Please note that this is a{" "}
                                    <b>{item?.subscriptionInterval}ly</b> job,
                                    and payment will be made on a{" "}
                                    <b>{item?.subscriptionInterval}ly</b> basis
                                    upon completion.{" "}
                                  </p>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div>No saved jobs to display</div>
                        )}
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === "interested-jobs" ? "show active" : ""
                      }`}
                      id="interested-jobs"
                      role="tabpanel"
                      aria-labelledby="interested-jobs-tab"
                    >
                      <div className="list--tasks">
                        {appliedJobs && appliedJobs?.length > 0 ? (
                          appliedJobs.map((item, index) => (
                            <div
                              key={index}
                              className="created--task--lists process--task--list"
                            >
                              {item?.directorInvitation === true ? (
                                <div className="waiting--msg" key={index}>
                                  <span>
                                    <AiOutlineInfoCircle /> Creatr has sent you
                                    an offer!
                                  </span>
                                  <span
                                    className="view--reason"
                                    onClick={() =>
                                      handleViewOffer(item.taskDetails)
                                    }
                                  >
                                    View Offer <GoArrowRight />
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {item.taskDetails.status == "open" ? (
                                    <div
                                      className="confirmation-task"
                                      style={{ zIndex: "auto" }}
                                    >
                                      <span>
                                        <BsListCheck /> Applied Job
                                      </span>
                                    </div>
                                  ) : (
                                    <>
                                      {item?.taskDetails?.status ===
                                      "in-progress" ? (
                                        <div
                                          className="confirmation-task"
                                          style={{ zIndex: "auto" }}
                                        >
                                          <span>
                                            <BsListCheck />
                                            This Job is assigned to another
                                            Hustlr
                                          </span>
                                        </div>
                                      ) : item?.taskDetails?.status ===
                                        "completed" ? (
                                        <div
                                          className="confirmation-task"
                                          style={{ zIndex: "auto" }}
                                        >
                                          <span>
                                            <BsListCheck /> This Job is
                                            completed by another Hustlr
                                          </span>
                                        </div>
                                      ) : item?.taskDetails?.status ===
                                        "assigned" ? (
                                        <div
                                          className="confirmation-task"
                                          style={{ zIndex: "auto" }}
                                        >
                                          <span>
                                            <BsListCheck /> This job has been
                                            directly assigned to another Hustlr
                                          </span>
                                        </div>
                                      ) : item?.taskDetails?.status ===
                                        "closed" ? (
                                        <div
                                          className="confirmation-task"
                                          style={{ zIndex: "auto" }}
                                        >
                                          <span>
                                            <BsListCheck /> This Job is closed
                                            by Creatr
                                          </span>
                                        </div>
                                      ) : null}
                                    </>
                                  )}
                                </>
                              )}

                              <div className="task--work">
                                <div
                                  className="task--items--header"
                                  style={{ marginBottom: "0px" }}
                                >
                                  <div className="task--add--info">
                                    <div className="user--task--info">
                                      <div className="task--info--img">
                                        <Link
                                          to={`/user-info/${item?.directorId}`}
                                        >
                                          <img
                                            src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.taskDetails?.directorDetails?.profilePic}`}
                                          />
                                        </Link>
                                      </div>
                                      <div className="task--info--details">
                                        <span>
                                          {
                                            item?.taskDetails?.directorDetails
                                              ?.name
                                          }
                                        </span>
                                        <p>
                                          <img src="../assets/images/hustler-star.png" />{" "}
                                          {handleDecimalNumber(
                                            item?.taskDetails?.directorDetails
                                              ?.ratings
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    {/* <h3>{item?.taskDetails?.title}</h3> */}
                                  </div>
                                  <div className="task--items--edit--delete">
                                    {item.cancellation ? (
                                      <>
                                        <div
                                          className="delete--taks--items wislishadd"
                                          style={{
                                            // width: "55px",
                                            background: "var(--darkblack)",
                                            color: "var(--white)",
                                          }}
                                          onClick={() =>
                                            handleShowCancelDisable(item)
                                          }
                                        >
                                          <PiEye />
                                        </div>
                                        <span className="cancelled--interested-job">
                                          <IoInformationCircleOutline />{" "}
                                          Cancelled Job
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          className="button--lists-view"
                                          style={{ display: "flex" }}
                                        >
                                          {item.taskDetails.status ==
                                            "open" && (
                                            <>
                                              {" "}
                                              <button
                                                onClick={() =>
                                                  handleShowDisableInterest(
                                                    item
                                                  )
                                                }
                                                className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                              >
                                                <span>
                                                  <PiEye /> View Details
                                                </span>
                                              </button>
                                              <button
                                                onClick={() =>
                                                  handleViewCounterOffer(item)
                                                }
                                                className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                              >
                                                <span>
                                                  <BsSend /> View Offer
                                                </span>
                                              </button>
                                              <button
                                                onClick={() =>
                                                  handleCancelPopup(item?._id)
                                                }
                                                className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                              >
                                                <span>
                                                  <ImCancelCircle /> Cancel Job
                                                </span>
                                              </button>
                                            </>
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>

                                <div className="task--items--details">
                                  <div
                                    className="task--detail-full"
                                    style={{ width: "100%", marginTop: "10px" }}
                                  >
                                    <h2>{item?.taskDetails?.title}</h2>
                                    <div className="service--category--task">
                                      <h3>{item?.taskDetails?.serviceName}</h3>
                                    </div>
                                  </div>
                                </div>

                                <div className="task--items--content">
                                  <p>
                                    <span className="task--items--content--icon">
                                      <AiOutlineCalendar />
                                    </span>
                                    {setReportFormatDate(
                                      item?.taskDetails?.dueDate
                                    )}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <TbMoneybag />
                                    </span>{" "}
                                    Budget: $
                                    {BudgetFormat(
                                      `${item?.taskDetails?.budget}`
                                    )}
                                    {/* Members:{" "}
                                  {item?.task?.members} */}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <GrLocation />
                                    </span>{" "}
                                    Area: {item?.taskDetails?.cityName}
                                  </p>
                                </div>
                                <div className="task--items-footer">
                                  <p>
                                    {item?.taskDetails?.description?.length >
                                    250
                                      ? `${item?.taskDetails?.description?.substring(
                                          0,
                                          250
                                        )}...`
                                      : `${item?.taskDetails?.description}`}
                                  </p>
                                </div>
                                <div className="task--items-application">
                                  <p>
                                    <img src="../assets/images/hustler--taskdone.png" />{" "}
                                    {item?.applications} Applications
                                  </p>
                                </div>
                              </div>
                              {item?.taskDetails?.oneTimePayment ? null : (
                                <div className="task--items-down--footer confirmation-task">
                                  <p>
                                    Please note that this is a{" "}
                                    <b>
                                      {item?.taskDetails?.subscriptionInterval}
                                      ly
                                    </b>{" "}
                                    job, and payment will be made on a{" "}
                                    <b>
                                      {item?.taskDetails?.subscriptionInterval}
                                      ly
                                    </b>{" "}
                                    basis upon completion.{" "}
                                  </p>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div>No applied jobs to display</div>
                        )}
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === "incoming-jobs" ? "show active" : ""
                      }`}
                      id="incoming-jobs"
                      role="tabpanel"
                      aria-labelledby="incoming-jobs-tab"
                    >
                      <div className="list--tasks">
                        {incomingJobs && incomingJobs?.length > 0 ? (
                          incomingJobs.map((item, index) => (
                            <div
                              key={index}
                              className="created--task--lists process--task--list"
                            >
                              {item?.taskType === "rejected" ? (
                                <div className="cancelled--interested-job">
                                  <span>
                                    <IoIosCloseCircleOutline />{" "}
                                    {item?.rejectionDetails?.rejectedBy ===
                                    "HUSTLER"
                                      ? "You have rejected this job"
                                      : "Creatr has rejected your offer"}
                                  </span>
                                  <span
                                    className="view--reason"
                                    onClick={() => showRejectedReason(item)}
                                  >
                                    View Rejections <GoArrowRight />
                                  </span>
                                </div>
                              ) : (
                                <div
                                  className="new-jobs"
                                  style={{ zIndex: "auto" }}
                                >
                                  <span>
                                    <BsListTask /> Incoming Job
                                  </span>
                                  <span className="view--reason">
                                    <div className="task--link">
                                      <Link
                                        to={`/job-details/${item._id}`}
                                        state={{ id: "jobDetails--view" }}
                                      >
                                        Job Details <GoArrowRight />
                                      </Link>
                                    </div>
                                  </span>
                                </div>
                              )}

                              <div className="task--work">
                                <div
                                  className="task--items--header"
                                  style={{ marginBottom: "0px" }}
                                >
                                  <div className="task--add--info">
                                    <div className="user--task--info">
                                      <div className="task--info--img">
                                        <Link
                                          to={`/user-info/${item?.user?._id}`}
                                        >
                                          <img
                                            src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.user?.profilePic}`}
                                          />
                                        </Link>
                                      </div>
                                      <div className="task--info--details">
                                        <span>
                                          {item?.user?.fname}{" "}
                                          {item?.user?.lname}
                                        </span>
                                        <p>
                                          <img src="../assets/images/hustler-star.png" />{" "}
                                          {handleDecimalNumber(
                                            item?.user?.directorDetails?.ratings
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="task--items--edit--delete">
                                    {item?.taskType !== "rejected" ? (
                                      <div className="task--items--edit--delete">
                                        <div
                                          className="button--lists-view"
                                          style={{ display: "flex" }}
                                        >
                                          <button
                                            onClick={(e) =>
                                              handleIncomingTask(
                                                e,
                                                item,
                                                "accept"
                                              )
                                            }
                                            className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                          >
                                            <span>
                                              <FaRegCircleCheck /> Accept Job
                                            </span>
                                          </button>
                                          {item.counterOffer == true ? (
                                            <button
                                              onClick={(e) =>
                                                handleIncomingTask(
                                                  e,
                                                  item,

                                                  "view"
                                                )
                                              }
                                              className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                            >
                                              <span>
                                                <PiEye /> View Offer
                                              </span>
                                            </button>
                                          ) : (
                                            <button
                                              onClick={(e) =>
                                                handleIncomingTask(
                                                  e,
                                                  item,
                                                  "counter"
                                                )
                                              }
                                              className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                            >
                                              <span>
                                                <BsSend /> Counter Offer
                                              </span>
                                            </button>
                                          )}

                                          <button
                                            onClick={(e) =>
                                              handleIncomingTask(
                                                e,
                                                item,
                                                "cancel"
                                              )
                                            }
                                            className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                          >
                                            <span>
                                              <ImCancelCircle /> Decline this
                                              Offer
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="task--items--details">
                                  <div
                                    className="task--detail-full"
                                    style={{ width: "100%", marginTop: "10px" }}
                                  >
                                    <h2>{item.title}</h2>
                                    <div className="service--category--task">
                                      <h3>{item?.serviceName}</h3>
                                    </div>
                                  </div>
                                </div>

                                <div className="task--items--content">
                                  <p>
                                    <span className="task--items--content--icon">
                                      <AiOutlineCalendar />
                                    </span>
                                    {setReportFormatDate(item?.dueDate)}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <TbMoneybag />
                                    </span>{" "}
                                    Budget: ${BudgetFormat(`${item?.budget}`)}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <GrLocation />
                                    </span>{" "}
                                    Area: {item?.cityName}
                                  </p>
                                </div>
                                <div className="task--items-footer">
                                  <p>
                                    {item?.description?.length > 250
                                      ? `${item?.description?.substring(
                                          0,
                                          250
                                        )}...`
                                      : `${item?.description}`}
                                  </p>
                                </div>
                              </div>
                              {item?.oneTimePayment ? null : (
                                <div
                                  className={`task--items-down--footer ${
                                    item?.taskType === "rejected"
                                      ? "cancelled--interested-job"
                                      : "new-jobs"
                                  }`}
                                >
                                  <p>
                                    Please note that this is a{" "}
                                    <b>{item?.subscriptionInterval}ly</b> job,
                                    and payment will be made on a{" "}
                                    <b>{item?.subscriptionInterval}ly</b> basis
                                    upon completion.{" "}
                                  </p>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div>No incoming jobs to display</div>
                        )}
                      </div>
                    </div>

                    <Pagination
                      currentPage={currentPage}
                      totalPosts={totalPosts}
                      paginate={paginate}
                      postsPerPage={postsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`filter--data custom--scroll ${
                baseProfileBase === "profile--toggle"
                  ? "active--toggle--baseprofile"
                  : ""
              }`}
            >
              <div className="scroll--sticky">
                <div class="your--area">
                  <div class="filter--div">
                    <div class="select--price">
                      <div className="checkbox--task">
                        <ul style={{ padding: "0px", listStyle: "none" }}>
                          {staticHustlerFilter &&
                            staticHustlerFilter.length > 0 &&
                            staticHustlerFilter
                              .slice(0, 2)
                              .map((item, index) => (
                                <li key={index}>
                                  <div className="checkbox--custom">
                                    <label
                                      htmlFor={`checkbox-${index}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {item?.label}
                                    </label>

                                    <input
                                      type="checkbox"
                                      id={`checkbox-${index}`}
                                      checked={
                                        selectedSort?.value === item?.value
                                      }
                                      onChange={() => handleSortChange(item)}
                                    />
                                    <span className="checkmark"></span>
                                  </div>
                                </li>
                              ))}
                        </ul>
                      </div>

                      <p class="filter--head">Sort By</p>
                      <div className="checkbox--task">
                        <ul style={{ padding: "0px", listStyle: "none" }}>
                          {staticHustlerFilter &&
                            staticHustlerFilter
                              .slice(2, 4)
                              .map((item, index) => (
                                <>
                                  <li key={index}>
                                    <div className="checkbox--custom">
                                      <label
                                        htmlFor={`checkbox-${index}`}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item?.label}
                                      </label>

                                      <input
                                        type="checkbox"
                                        id={`checkbox-${index}`}
                                        checked={
                                          selectedSort?.value === item?.value
                                        }
                                        onChange={() => handleSortChange(item)}
                                      />
                                      <span className="checkmark"></span>
                                    </div>
                                  </li>
                                </>
                              ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <FilterRightSide
                  userDetails={userDetails}
                  onRemoveActiveClass={handleRemoveActiveClass}
                  handleTabClick={handleTabClick}
                />
              </div>
            </div>
            {baseProfileBase === "profile--toggle" && (
              <div className="overlay--filter--data"></div>
            )}
          </div>
        </div>
      </section>

      {showInterestModal && (
        <ShowInterestModal
          prevIntrestedTask={prevIntrestedTask}
          fetchWishlisttask={fetchWishlisttask}
          fetchAlltask={fetchAlltask}
          fetchappliedJobs={fetchappliedJobs}
          fetchhustlerDetails={fetchhustlerDetails}
          TaskDetails={TaskDetails}
          taskid={taskid}
          setLoading={setLoading}
          ongoingPagination={ongoingPagination}
          setShowInterestModal={setShowInterestModal}
          setcurrentPage={setcurrentPage}
          setongoingPagination={setongoingPagination}
          setActiveTab={setActiveTab}
          handleNavigateTabClick={handleNavigateTabClick}
        //  handleTabClick={handleTabClick}
        />
      )}

      {showIntersetDisableModal && (
        <ShowIntersetDisableModal
          handleUpdateJob={handleUpdateJob}
          setshowIntersetDisableModal={setshowIntersetDisableModal}
          TaskDetails={TaskDetails}
        />
      )}

      {showCanceljobModal && (
        <CanceljobModal
          CancelTask={CancelTask}
          setshowCanceljobModal={setshowCanceljobModal}
        />
      )}

      {showIncomingModel.cancelJob && (
        <CancelIncomingJobModel
          handleCancelJob={handleCancelJob}
          setshowIncomingModel={setshowIncomingModel}
        />
      )}

      {showdenieJobModel && (
        <ConfirmDeniedJobModel
          handleCancelDeniedJob={handleCancelDeniedJob}
          handleDeniedJob={handleDeniedJob}
          setshowdenieJobModel={setshowdenieJobModel}
        />
      )}

      {showCancelDisableModal && (
        <ShowCancelDisableModal
          setshowCancelDisableModal={setshowCancelDisableModal}
          TaskDetails={TaskDetails}
        />
      )}

      {showCounterOffermodel && (
        <CounterOfferJobModel
          handleDeniedJob={handleDeniedJob}
          setshowCounterOffermodel={setshowCounterOffermodel}
          CounterOffer={CounterOffer}
          allCounterOfferdetails={allCounterOfferdetails}
          setallCounterOfferdetails={setallCounterOfferdetails}
          showCounterOfferList={showCounterOfferList}
          handleAcceptJob={handleAcceptJob}
          incomingJobtaskId={incomingJobtaskId}
          TaskDetails={TaskDetails}
        />
      )}

      {showIncomingModel.counterJob && (
        <CounterOfferJobModel
          handleDeniedJob={handleDeniedJob}
          setshowIncomingModel={setshowIncomingModel}
          incomingJobtaskId={incomingJobtaskId}
          CounterOffer={CounterOffer}
          allCounterOfferdetails={allCounterOfferdetails}
          setallCounterOfferdetails={setallCounterOfferdetails}
          showCounterOfferList={showCounterOfferList}
          handleAcceptJob={handleAcceptJob}
          TaskDetails={TaskDetails}
        />
      )}

      {showOfferModel && (
        <ShowOfferModal
          offerDetails={offerDetails}
          setshowOfferModel={setshowOfferModel}
        />
      )}

      {showIncomingModel.acceptJob && (
        <AcceptIncomingJobModel
          handleAcceptJob={handleAcceptJob}
          setshowIncomingModel={setshowIncomingModel}
        />
      )}

      {showRejectionModel && (
        <ShowRejectedReason
          setshowRejectionModel={setshowRejectionModel}
          showRejectionModel={showRejectionModel}
          rejectionDetails={rejectionDetails}
        />
      )}
    </>
  );
};

export default IsLoadingHOC(IsLoggedinHOC(HustlerHome));
