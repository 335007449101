import React from 'react';

const RatingStar = ({ ratings }) => {
  const filledStars = Math.floor(ratings);
  const fractionalPart = ratings - filledStars;

  return (
    <>
      <div className="stars">
        {[...Array(5).keys()].map((index) => (
          <svg
            key={index}
            stroke="currentColor"
            fill={
              index < filledStars
                ? '#f0ad4e'
                : index === filledStars && fractionalPart > 0
                ? `url(#gradFractional)`
                : '#ccd6df'
            }
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              {index === filledStars && fractionalPart > 0 && (
                <linearGradient id="gradFractional" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset={`${fractionalPart * 100}%`} style={{ stopColor: '#f0ad4e', stopOpacity: 1 }} />
                  <stop offset={`${fractionalPart * 100}%`} style={{ stopColor: '#ccd6df', stopOpacity: 1 }} />
                </linearGradient>
              )}
            </defs>
            <path d="m12.672.668 3.059 6.197 6.838.993a.75.75 0 0 1 .416 1.28l-4.948 4.823 1.168 6.812a.75.75 0 0 1-1.088.79L12 18.347l-6.116 3.216a.75.75 0 0 1-1.088-.791l1.168-6.811-4.948-4.823a.749.749 0 0 1 .416-1.279l6.838-.994L11.327.668a.75.75 0 0 1 1.345 0Z"></path>
          </svg>
        ))}
      </div>
    </>
  );
};

export default RatingStar;
