import React from "react";

const TermsandConditonModel = ({
  setshowTermsandConditonModel,
  setformData,
}) => {
  const handleSubmit = () => {
    setformData((prev) => ({
      ...prev,
      termsandConditon: true,
    }));
    setshowTermsandConditonModel(false);
  };
  return (
    <div>
      <div
        className={`deletemodal modal ${setshowTermsandConditonModel ? "show" : ""
          }`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document" style={{ maxWidth: '1200px' }}>
          <div className="modal-content">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-center">
                Your SideHustl Services Inc.<br />
                200-450 2nd Avenue N., Saskatoon, SK, Canada, S7K 2C3
              </h4>
              <button
                type="button"
                className="close"
                onClick={() => setshowTermsandConditonModel(false)}
                // onClick={()=>handleCancelPopup(false)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body confirmation--modal request--lists custom--scroll"

              style={{ textAlign: "left", maxHeight:'70vh', overflowY:'scroll' }}
            >
              <p className="text-center">Terms of Use</p>
              <p>
                <b style={{ fontSize: '18px' }}>THESE</b> Terms of Use, Privacy Policy, and Mobile Devices Terms and all other policies posted on our site or application set out the terms of service on which we offer you access to, and use of our site, services, applications, tools, and communication platforms (collectively “Services” or “SideHustl Services”). Our Terms of Use may change from time to time, you agree to check in from time to time to ensure you are up to date with our most current policies, terms, or conditions.
              </p>
              <p>
                The SideHustl Services are provided to you by Your SideHustl Services Inc. (“SideHustl”), 200-450 2nd Avenue N., Saskatoon, SK, Canada, S7K 2C3.
              </p>
              <p>
                <b style={{ fontSize: '18px' }}>AND WHEREAS</b> you agree to the following terms of use in exchange for the Services associated with SideHustl.
              </p>
              <p>
                <b style={{ fontSize: '18px' }}>NOW THEREFORE</b> in consideration for access to and potential use of SideHustl Services (the receipt and sufficiency of which is hereby acknowledged by you), the mutual covenants contained in this Agreement and other good and valuable consideration, you agree to the following terms and conditions as follows:
              </p>

              <h5><strong>1. Term</strong></h5>
              <p>The term of this Agreement shall extend from the date of acceptance for an indefinite period, until changed by SideHustl. You agree to review and keep up to date with changing policies and terms of use as they are changed by SideHustl. </p>

              <h5><strong>2. Your Account</strong></h5>
              <p>In order to access and use some or a portion of the Services, you may be required to register with SideHustl and set up an account with contact information, including but not limited to your name, email address, civic address, as well as a password (your “Account”). You agree that the contact information you provide shall be your own contact information, and you are solely responsible for maintaining the confidentiality of your password. You agree that SideHustl is not liable in any way, shape, or form if your contact information or password is misplaced, lost, or stolen, even if the data does originate from SideHustl. Therefore, you should protect your password and make your password difficult for others to guess.</p>

              <p>You agree that the account shall be your sole account, and you shall not transfer your account to any other party without SideHustl’s explicit written consent.</p>

              <p>You are aware of the danger that a password or other personal information may be lost or stolen (a “leak”). You are aware that such a leak may originate from SideHustl. Nevertheless, you agree that SideHustl shall not be responsible in any way for any leak. You also agree to mitigate against such risks by taking recommended data safety precautions such as using unique contact information or passwords.</p>

              <p>You also agree that SideHustl may connect your account, or personal information with a third-party, or third-party service. You are giving us permission to access, store, and use information obtained from you for this third-party service as permitted by them, and as may be described in any other policy of ours. </p>

              <h5><strong>3. Using the Services</strong></h5>
              <p>You agree to only use the Services for purposes intended by SideHustl. You will not do any of the following:</p>

              <ol type="a" style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
                <li>Violate our terms of use, or any other policy which we may put forward for using the Services;</li>
                <li>Violate any law;</li>
                <li>Be false or misleading;</li>
                <li>Infringe any third-party right;</li>
                <li>Distribute or communicate spam, chain letters, pyramid schemes, or multi-level marketing materials;</li>
                <li>Engage in any harassing behavior, or sending communications that may be reasonably interpreted by us as harassment;</li>
                <li>Distribute viruses or other technologies that may harm SideHustl or the interests or property of SideHustl users/hustlrs;</li>
                <li>Impose an unreasonable load on our infrastructure, or interfere with the proper working of SideHustl and its Services ;</li>
                <li>Impose an unreasonable load on any user of SideHustl, or interfere with their use and enjoyment of SideHustl or SideHustl Services;</li>
                <li>Copy, modify, or distribute any other person’s content;</li>
                <li>Engage in any behaviour that would violate another individual’s copyright or trademark;</li>
                <li>Engage in any behaviour that would violate any copyright or trademark related to SideHustl;</li>
                <li>Use any automated means to access SideHustl or its Services and collect content for any purpose without SideHustl’s express written permission;</li>
                <li>Harvest or otherwise collect information about other users/hustlrs, including their email addresses, without their consent and the consent of SideHustl;</li>
                <li>Bypass measures used to prevent or restrict access to SideHustl or its Services;</li>
              </ol>

              <h5><strong>4. Abusing SideHustl Services</strong></h5>
              <p>You agree that if you discover an issue with the Services, or any system developed or operated by SideHustl, to immediately report such an issue to SideHustl so SideHustl can keep the Services working properly. SideHustl may limit or terminate some or all of its Services, remove hosted content, and take technical or legal steps to keep users/hustlrs of SideHustl if for any reason SideHustl believes it to be necessary. This may include actions taken against a user if SideHustl believes the user is creating problems or acting inconsistently with the letter or spirit of our policies, or these terms of use.  SideHustl may take actions against these users/hustlrs such as removing hosted content, or removing a user/hustlr of SideHustl.</p>

              <p>However, you agree that SideHustl shall not be liable for monitoring SideHustl, or SideHustl Services, for unauthorized, unlawful, offensive, or wrongful content on SideHustl or its Services or created by SideHustl users/hustlrs. SideHustl is similarly not liable for any damage resulting from a user’s usage of SideHustl or SideHustl Services.</p>

              <h5><strong>5. Fees and Services</strong></h5>
              <p>There may be fees associated with certain aspects of our Services. If the service charges you a fee, you will be able to review and accept that charge. Our fees are quoted in Canadian Dollars (CAD), and we may change them from time to time, without notice to yourself. We may choose to temporarily change our fees for promotional events or new services. These changes are effective when we announce the promotional events or new services. Any fee we charge is non-refundable, and you are responsible for paying them when they are due. If you don’t, we may limit your ability to use our Services. If your payment method fails, or your account is past due, you agree that we may collect fees owed to us using other collection mechanisms. You agree that you will be responsible for any costs we incur to collect on overdue accounts, including legal fees we may incur attempting to collect on those accounts. </p>

              <p>Any applicable taxes associated with our Services will be collected when applicable. You agree to provide accurate address information necessary for SideHustl to comply with its obligations under any applicable law. If you fail or refuse to provide such information for any reason, you agree that SideHustl has complete discretion to determine and collect appropriate taxes from yourself.</p>

              <p>For any contracts made between yourself and any other user, you are solely responsible to collect and remit any applicable taxes. This includes any applicable taxes that may arise from the sale of any items or services by yourself that originates from SideHustl.</p>

              <p>You are aware and agree that some, many, or all features and functionality may vary depending on which website and/or mobile device you use to access the Services. Further, you agree that you are aware that some Services may not be available to yourself for geographic reasons, or reasons relating to the law of any jurisdiction you are in.</p>

              <p>Further, you are aware that some or all of your data may cross any number of international borders. Such transit may be for reasons outside of SideHustl’s control. Nevertheless, you agree that you will comply with any such legal obligations that may arise from the transmittal of such data. Further, you agree and authorize SideHustl to transfer or transmit data to any authority we believe, even if such belief is not reasonably held, has a legal right to examine such data. </p>

              <p>Additionally, you agree that you will not use any anonymizing service, or VPN (virtual private network) absent SideHustl’s express written consent. </p>

              <h5><strong>6. Content</strong></h5>
              <p>While using SideHustl Services, you will encounter content created by SideHustl, SideHustl users/hustlrs, and third parties. </p>

              <p>You agree that SideHustl and SideHustl Services are not and will not be held liable or responsible for the accuracy, the correctness, the reliability, the quality, the suitability, the completeness, the legality, the decency, the copyright, or any other aspect of the content hosted, published, or republished, on SideHustl and SideHustl Services. </p>

              <p>You agree not to copy, modify, resell, or distribute part or all of our Services, copyrights, or trademarks. When you post content on SideHustl, or use SideHustl services, you agree that you are giving us content, and granting as a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable right to exercise any right to that content, including the copyright, publicity, database, and informational rights to that content. If you believe that your legal rights have been violated, you agree to contact SideHustl for a determination by SideHustl as to whether or not your rights have been violated. We reserve the right to remove content where we for any reason believe a user/hustlr has violated the terms of service, any of our policies, or the rights of any other party. </p>

              <p>You agree to not post content that infringes the rights of any third-party or parties.  This includes, but is not limited to, content that infringes on intellectual property rights such as copyright and trademark. You agree to not post content that contains any reference to or likeness of any identifiable third-party, unless consent has been obtained from each such party or individual as may be required. You agree that we may remove content without notice to yourself if we believe there has been a violation of these terms of use, any policy of SideHustl, or any intellectual property rights. </p>

              <p>You agree that if you believe that your rights have been violated by SideHustl, or any use of SideHustl or the Services, that you shall provide written notice to SideHustl of such a violation. You agree that you shall not take legal action against SideHustl or a SideHustl user/hustlr without providing such notification to SideHustl first, and providing SideHustl an opportunity to examine whether such conduct has violated SideHustl policies. You also agree that if you contact SideHustl about any potential violation that SideHustl can share any such information SideHustl receives with any third-party to deal with the complaint.</p>

              <h5><strong>7. Disclaimers and Limitations of Liability</strong></h5>
              <p>You agree that the Services are provided “as is” and “as available”. You agree that you will not hold us liable for any loss or harm that may arise as a result of any service interruption that SideHustl experiences. You agree not to hold us responsible for things other users/hustlrs post or do. You also agree not to hold us responsible for the payment processing of other service providers. For greater certainty, as applicable, if you use SideHustl and the third-party services of a payment processor, you may also be bound by terms and conditions related to that third-party payment processor. You agree we are not liable for any loss, claim, or damages howsoever arising in connection with that third-party’s services. </p>

              <p>You agree that SideHustl and/or SideHustl Services does not and will not create any contract between users, aside from this agreement, the terms of use, terms of service, and all related policies. Users/hustlrs are solely responsible for entering into their own contracts with other users, whether written or verbal, including any and all terms therein, and you agree that SideHustl and SideHustl Services will not be relied upon in the formation of, or interpretation of, any such contract. </p>

              <p>For greater certainty, you agree that any other contract for services you may make between yourself and any other party does not involve SideHustl as a party to such a contract, and that such contracts are made outside of the Services, SideHustl, or any other service SideHustl provides, or may provide, now or in the future. Further, as any contract that is made is formed by other parties, we do not guarantee the accuracy, completeness, efficacy, or timeliness of any posting, user/hustlr communication, or offer. You also agree that we are not obligated to ensure continuous or secure access to our Services. In the event that Service is disrupted for any reason, you agree we are not liable for any damages or inconvenience that may cause. </p>

              <p>You also agree that we are not responsible for you receiving notifications in a timely manner. You are aware that notification functions for our Services may not occur in real time, and that there are a variety of reasons that such notifications may not be transmitted, or you may not receive them. This may be as a result of technical disruption on our end, or latency issues due to physical location of yourself or our servers. Further, there may be latency or disruption on the part of your internet service provider, or wireless data service provider. In any event, you agree that we are not responsible for you receiving notifications in a timely manner and that you will not hold us liable for any damages that you may suffer as the result of any delay.</p>

              <p>Further, you agree that there is no warranty, representation, condition, express or implied, including those of quality, merchantability, merchantable quality, durability, fitness for a particular purpose, or representation as to reputation, except as to those expressly required by statute. You agree that we are not liable for any loss, whether of money, profit, goodwill, reputation, or any special, indirect, or consequential damages arising out of your use, misuse, or failure to use SideHustl, even if you advise us or we could reasonably foresee the damages, or the possibility of damage occurring. </p>

              <p>Despite the clauses attorning yourself to the jurisdiction of Saskatchewan found below, in the event such clauses are severed, any disclaimer or exclusion of damages operates here to the maximum extent allowable in the jurisdiction found.</p>

              <p>Further, without limiting the generality of the foregoing, if we are found for any reason to be liable, our liability to you or any third-party, whether in contract, tort, negligence, strict liability in tort, by statute, or otherwise, is limited to $1 CAD. </p>

              <h5><strong>8. Indemnification</strong></h5>
              <p>You agree to indemnify and hold harmless SideHustl, its affiliates, anyone identified in any of our policies, as well as SideHustl’s respective officers, creatrs, agents, and employees (the “Indemnified Parties”), from any claim made by any third-party, together with any amounts payable to the third-party whether in settlement or as may otherwise be awarded, and reasonable legal costs incurred by any of the indemnified parties, arising from or relating to your use of the Services, any alleged violation by you of the applicable terms of use, any violation by you of any applicable law or regulation, or for any claim in tort, contract or otherwise that may arise between you and any other party wherein SideHustl may have acted as an intermediary. We reserve the right, at our expense, to assume the exclusive defence and control of any matter subject to indemnification by you, but doing so will not excuse your indemnification obligations.</p>

              <h5><strong>9. Personal Information</strong></h5>
              <p>By using our Services, you agree to the collection, transfer, storage, and use of your personal information by SideHustl on servers located in Canada, the United States, or elsewhere. </p>

              <h5><strong>10. Reviews</strong></h5>
              <p>You may be asked to leave reviews when interacting with other users/hustlrs, or after interacting with other users/hustlrs on SideHustl. Additionally, you may have the option of leaving reviews relating to other users/hustlrs. Your submitted review, username, and any other information we deem relevant may be made public. You agree that the reviews shall be truthful, left in good faith, and fairly depict any interaction that may have occurred. You agree that you shall not use the review system to harass or abuse other users/hustlrs, manipulate or mislead others, or otherwise engage in any activity which SideHustl believes may harm SideHustl’s reputation.</p>

              <p>You also agree that you shall not have the power to edit or remove reviews on your own profile, or someone else’s profile. SideHustl will also not mediate review related disputes. Such user/hustlr reviews reflect the opinion of the individual who posted such review, and does not reflect the view or opinion of SideHustl.</p>

              <p>Further, for any review posted, you agree, despite the fact that a review may be posted on SideHustl, SideHustl Services, or stored on SideHustl servers, that you shall not hold SideHustl responsible for the publication of any review, including any that are libelous or defamatory in nature. </p>

              <p>SideHustl also reserves the right to remove reviews which violate their policies, guidelines, or they believe, reasonably or not, may harm their business interests.</p>

              <h5><strong>11. General</strong></h5>
              <p>These terms and other policies posted by us in relation to SideHustl Services constitute the entire agreement between us and you, superseding any prior agreement. This agreement is governed by the laws of the Province of Saskatchewan and the federal laws of Canada applicable therein. We both submit irrevocably to the jurisdiction of the Courts of the Province of Saskatchewan. You agree to waive any statutory benefit, if applicable, that you may receive under any law in any other jurisdiction. If SideHustl does not enforce any particular provision of any agreement or statutory benefit, SideHustl is not waiving its right to do so later at a time it deems preferable.</p>

              <p>You agree that SideHustl reserves the right to automatically assign any benefit or right they may have pursuant to this agreement in their sole discretion and with no notice to yourself. If notice is required to be sent to SideHustl, you agree that such notice must be sent by Registered Mail to: #200, 450 2nd Avenue N., Saskatoon, Saskatchewan, S7K 2C3.</p>

              <p>You agree that we may send you notice via email to an address you have provided, or by push notification. You agree that such notices shall be deemed received after electronic transmission as opposed to after electronic reception.</p>

              <p>You agree that we may update this agreement at any time, without providing notice to your email and that you agree to monitor our terms and conditions upon visiting our site as any notification of changes is provided on SideHustl’s site. </p>

              <p>No other amendment to this agreement shall be effective unless made in writing, signed by yourself, and an authorized representative of SideHustl.</p>

              <p>You further agree that, in the interpretation of these terms and conditions, no rule of construction shall apply to the disadvantage of any party on the basis that that party prepared these terms and conditions or any part of them.</p>

              <h5><strong>12. Severance</strong></h5>
              <p>In the event any Court of competent jurisdiction strikes down any of these terms, you agree the remaining terms shall survive. </p>

              <h5><strong>13. Mobile Device Terms</strong></h5>
              <p>In the event you choose to access Services from a mobile device using an authorized application on the Apple App Store or the Android App Store, you agree that these terms and conditions shall apply in addition to any other terms and conditions or policies that may apply specifically for Mobile Devices. These may include an additional Mobile Policy, or End User License Agreement as the case may be. </p>

              <p>In the event of any conflict between such terms, you agree that the terms and conditions in this agreement shall take effect and operate to the benefit of SideHustl.</p>

              <h5><strong>14. Application and Website Use</strong></h5>

              <p>SideHustl grants you the revocable right to use the Services pursuant to the following restrictions. You may not:</p>

              <ol type="a" style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
                <li>modify, copy, publish, license, sell, or otherwise commercialize the Services or any information or software associated with the Services;</li>
                <li>rent, lease, or otherwise transfer rights to the Services; or</li>
                <li>use the Services in any manner that could impair the Services or interfere with any party’s use or enjoyment of the Services. </li>
              </ol>

              <p>You agree to comply with any and all applicable laws and third-party terms of any agreement when using the Services, including any wireless data service agreement you may be a party to. The Services may not contain the same functionality as it does on any different platform, you agree that these differences do not cause you damage in any way shape or form. </p>

              <p>You agree that the download and use of the site or application is at your own discretion and risk, and you are solely responsible for any damages to your hardware device(s) or loss of data that may result from your download or use of the Services. </p>

              <h5><strong>15. Intellectual Property</strong></h5>
              <p>SideHustl owns, or is the licensee to, all rights, titles, and interest in and to its Services, including all rights under patent, copyright, trade secret, trademark, and any and all other proprietary rights, including all applications, renewals, extensions, and restorations thereof. You will not modify, adapt, translate, prepare derivative works from, decompile, reverse-engineer, disassemble, or otherwise attempt to derive source code from any Services and you will not remove, obscure, or alter SideHustl’s copyright notice, trademark, or other proprietary rights notices affixed to, contained within, or accessed in conjunction with or by any Services.</p>

              <p>Further, as it relates to any copyright interest that may be created or arise from your usage of SideHustl services or systems, you agree that SideHustl shall hold the sole copyrights to such material. </p>

              <h5><strong>16. Foreign Trade Regulation</strong></h5>
              <p>You agree that you shall not use any SideHustl Services or SideHustl technology in any manner that may violate any embargo or sanctions implemented by the Government of Canada. When using the technology, you are solely responsible for complying with any trade regulation, domestic law, or foreign law that is meant to govern its usage across any international border.</p>

              <h5><strong>17. Additional Terms for Apple Devices</strong></h5>
              <p>You agree that Apple is not responsible for the Services, application and any content observed through the Services. You are granted a limited license to use the Services only on approved iOS products that you own or control as permitted by the Usage Rules set forth by Apple’s Terms and Conditions. Neither Apple nor SideHustl have any obligation to furnish maintenance and support services for the Services. Neither Apple nor SideHustl are responsible for the investigation, defence, settlement, and discharge of any third-party intellectual property infringement claim. Apple is not responsible for addressing any claims by you or any third-party relating to the Services or your possession and/or use of the Services, including but not limited to: (a) product liability claims; (b) any claim that the Services fail to conform to any applicable legal or regulatory requirements; and (c) claims arising under any applicable consumer protection or similar legislation. You agree that there is no applicable warranty relating to the Services, and that you are not entitled to any refund if the Services do not perform to your expectations. You agree that Apple is a third-party beneficiary to these terms, and that Apple may enforce these terms against you.</p>

              <h5><strong>18. Additional Terms for Android Devices</strong></h5>
              <p>In addition to the foregoing, you agree that Google is not responsible for any SideHustl Services or the content of any SideHustl Services. You agree that your use of the Services will comply with any applicable policy by Google for the Services. Google is only a provider of the Google Play Store, and has no obligation or liability with respect to you and the Services. You agree that Google is a third-party beneficiary to these terms as it relates to the Application and may enforce these terms against you.</p>

              <h5><strong>19. Cookies</strong></h5>
              <p>You agree that we may use cookies, web beacons, or similar technologies for storing information, advertising purposes, or any other purpose SideHustl deems important. </p>

              <h5><strong>20. Privacy</strong></h5>
              <p>You agree that SideHustl may have and implement further Privacy policies in addition to the following. You agree, and provide explicit consent that SideHustl may collect, use, disclose, and retain your personal information. You also agree that we may use such information as we deem fit, including transferring it to any third-party. </p>

              <p>Further, you agree that we are not liable in the event any third-party comes into possession of such information and uses it for illegal, immoral, or damaging purposes.</p>

              <p>You also agree that we may forward such information we collect to law enforcement agencies as we deem fit. For clarity, this may include law enforcement agencies of any jurisdiction, not just the jurisdiction you reside in, or that we operate in.</p>

              <p>You also agree that you have no right to review, collect, or examine any information we have in relation to yourself and that such information constitutes proprietary trade secrets important to our business functions.</p>

              <h5><strong>21. Representations</strong></h5>
              <p>You agree that we are not responsible for confirming the representation of any other user/hustlr on the platform, including that they are certified to carry out any work or services for which you may retain their services. This includes confirming their identity and whether they are duly authorized by any trade or profession.</p>

              <h5><strong>22. Enurement</strong></h5>
              <p>This Agreement shall enure to the benefit of and be binding on the parties hereto, their respective heirs, executors, administrators, successors, and assigns as the case may be.</p>

              <h5><strong>23. Insurance</strong></h5>
              <p>You agree to purchase and maintain in force and effect during the term of this Agreement, general and liability insurance in connection with any contract for services that you create as a result of your usage of SideHustl, SideHustl Services, or any related application. </p>

              <div className="footer-accept">
                <button type="button" className="btn btn-dark darkbtn-hover" onClick={handleSubmit}>
                  <span>Accept</span>
                </button>
                <button
                  type="button"
                  className="custom--btn ligghtbtn-hover transparent--btn"
                  onClick={() => setshowTermsandConditonModel(false)}
                >
                  <span>Deny</span>
                </button>
              </div>
              {/* <input type="checkbox" onClick={handleSubmit} />{" "}
              <span>You are accepting All terms and Condition</span> */}
            </div>

            {/* 
            <div className="modal-footer">
              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn"
             //   onClick={handleCancelPopup}
                // onClick={()=>handleCancelPopup(false)}
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                className="btn btn-dark darkbtn-hover"
             //   onClick={handleSubmit}
              >
                <span>Book</span>
              </button>
            </div>

            */}
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${setshowTermsandConditonModel ? "show" : ""
          }`}
      ></div>
    </div>
  );
};

export default TermsandConditonModel;
