import React from "react";
import Header from "../common/header";
import Footer from "../common/footer";

const Layout = ({ children, pageName }) => {
  const id = pageName ? `${pageName}--page` : "home--page";
  return (
    <div id={id}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
