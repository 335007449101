import React, { useState } from "react";

const DeleteCreatedTask = (props) => {
  const { deleteCreatedTask, closeModal, handleCloseTask } = props;

  return (
    <>
      <div
        className={`deletemodal modal ${deleteCreatedTask ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-center">Please Confirm</h4>
              <button
                type="button"
                className="close"
                onClick={closeModal}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-5 pb-5 confirmation--modal">
              <h5 className="mb-0">Do you really want to close this job ?</h5>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn"
                onClick={closeModal}
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                className="btn btn-dark darkbtn-hover"
                onClick={handleCloseTask}
              >
                <span>Close</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${deleteCreatedTask ? "show" : ""}`}
      ></div>
    </>
  );
};

export default DeleteCreatedTask;
