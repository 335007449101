import React, { useState, useEffect } from 'react';
import { IoCloseCircle } from 'react-icons/io5';
import { GoChevronRight, GoChevronLeft } from 'react-icons/go';

const TaskMedia = ({ imagePaths }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const MAX_DISPLAY_IMAGES = 4;

    useEffect(() => {
        if (isModalOpen) {
            const handleKeyDown = (event) => {
                if (event.key === 'ArrowRight') {
                    nextImage();
                } else if (event.key === 'ArrowLeft') {
                    prevImage();
                } else if (event.key === 'Escape') {
                    closeModal();
                }
            };

            window.addEventListener('keydown', handleKeyDown);
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [isModalOpen]);

    const openModal = (index) => {
        setCurrentImageIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === imagePaths.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? imagePaths.length - 1 : prevIndex - 1
        );
    };

    const openModalWithAllImages = () => {
        setIsModalOpen(true);
    };

    return (
        <div className="image-slider">
            <div className="task--media--list">
                {imagePaths && imagePaths.slice(0, MAX_DISPLAY_IMAGES).map((imagePath, index) => (
                    <img
                        key={index}
                        src={`${process.env.REACT_APP_IMAGE_URL}job_attachments/${imagePath}`}
                        alt={`Image ${index + 1}`}
                        onClick={() => openModal(index)}
                        className="clickable"
                    />
                ))}
                {imagePaths?.length > MAX_DISPLAY_IMAGES && (
                    <div className="more--images" onClick={openModalWithAllImages}>
                        {imagePaths.length - MAX_DISPLAY_IMAGES} more images
                    </div>
                )}
            </div>

            {isModalOpen && (
                <>
                    <div className="modal--gallery">
                        <IoCloseCircle className="close--modal" onClick={closeModal} />
                        <div className="overlay--gallery" onClick={closeModal}></div>
                        <div className="media--list">
                            <img
                                src={`${process.env.REACT_APP_IMAGE_URL}job_attachments/${imagePaths[currentImageIndex]}`}
                                alt={`Image ${currentImageIndex + 1}`}
                            />
                            <div className="navigation--task--image">
                                <div className="button--arrow" onClick={prevImage}>
                                    <GoChevronLeft />
                                </div>
                                <div className="button--arrow" onClick={nextImage}>
                                    <GoChevronRight />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default TaskMedia;
