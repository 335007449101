import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

const CancelIncomingJobModel = ({ setshowIncomingModel, handleCancelJob }) => {
  const [formdata, setformdata] = useState(
    {
      reason: "",
      finalprice: "",
    },
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    setformdata((prev) => ({
      ...prev,

      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleCancelJob(formdata)

    console.log(formdata);

  };

  return (
    <div>
      <div
        className={`deletemodal modal ${setshowIncomingModel ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <form onSubmit={handleSubmit}>
          <div className="modal-content" style={{width:'95%',margin:'0 auto'}}>
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-center">Please Confirm</h4>
              <button
                type="button"
                className="close"
                onClick={setshowIncomingModel}
                // onClick={()=>setshowIncomingModel(false)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-5 pb-5 confirmation--modal">
              <h5 className="mb-0">Are you sure you want to Decline this job?</h5>

              <div className="request--task--work">
                <div className="field--div">
                  <label>Reason <span className="mendotry-field">*</span></label>
                   <TextareaAutosize
                        minRows={3}
                        maxRows={4}
                        value={formdata.reason}
                        onChange={handleChange}
                        name="reason"
                        required
                    />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn"
                onClick={setshowIncomingModel}
              >
                <span>Cancel</span>
              </button>
              <button type="submit" className="btn btn-dark darkbtn-hover">
                <span>Submit</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div
        className={`modal-backdrop ${setshowIncomingModel ? "show" : ""}`}
      ></div>
    </div>
  );
};

export default CancelIncomingJobModel;
