import React, { useState } from 'react'
import CancelSubscription from '../../common/Modal/CancelSubscription';

const SubscriptionTaskDetails = () => {

    const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
    return (
        <>
            <div className='subscription--container pt-117 px-3'>
                <div className='pt-5'></div>
                <div className='subscription--header mb-5'>
                    <h3 className="text--dark mb-3">Current Subscription</h3>
                    <div className='subscription--status'>
                        <div className='details--subscription'>
                            <p>Your ongoing subscriptions is Yearly </p>
                            <p>Visa*******2789</p>
                        </div>
                        <div className='btn--subscription'>
                            <button
                                type="button"
                                className="custom--btn darkbtn-hover"
                                onClick={() => setCancelSubscriptionModal(true)}
                            >
                                <span>Cancel Subscription</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className='subscription--list'>
                    <div className='subscription--item'>
                        <div className='subscription--item-header'>
                            <div className='subs-top'>
                                <div className='logo'>
                                    <img
                                        src="/assets/images/logo.png"
                                        alt="logo"
                                    />
                                </div>
                                <div className='invoice-number'>
                                    <p className='sub-head'>Invoice No.</p>
                                    <p><b>57842Dsd44-0002</b></p>
                                </div>
                            </div>
                            <div className='subs-bottom'>
                                <div className='paidto'>
                                    <p className='sub-head'>Paid To:</p>
                                    <p><b>Client Name</b></p>
                                </div>
                                <div className='invoice-number'>
                                    <p className='sub-head'>Payment Method</p>
                                    <p>Visa...4245</p>
                                    <p className='sub-head'>Payment Date</p>
                                    <p>December 7, 2022</p>
                                </div>
                            </div>
                        </div>

                        <div className='subscription--item-body'>
                            <div className='subscription--job'>
                                <div className='job--details'>
                                    <p><b>Services</b></p>
                                    <p>Invoice Item 1</p>
                                </div>
                                <div className='job--amount'>
                                    <p className='sub-head'>Total</p>
                                    <p>4,000.00</p>
                                </div>
                            </div>

                            <div className='button--action'>
                                <button
                                    type="button"
                                    className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                >
                                    <span>Download Invoice</span>
                                </button>
                                <button
                                    type="button"
                                    className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                >
                                    <span>Download Receipt</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {cancelSubscriptionModal && (
                <CancelSubscription
                    setCancelSubscriptionModal={setCancelSubscriptionModal}
                />
            )}
        </>
    )
}

export default SubscriptionTaskDetails