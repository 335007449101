import React from "react";

const DirectorcancelJobReason = ({
  directorCancelreason,
  setdirectorCancelreason,
}) => {
  console.log(directorCancelreason);
  return (
    <div>
      <div
        className={`deletemodal modal ${directorCancelreason ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-left">
                Creatr Reason for Job not Completed
              </h4>
              <button
                type="button"
                className="close"
                onClick={() =>
                  setdirectorCancelreason({ model: false, reason: "" })
                }
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body pt-3 pb-4">
              <p className="mb-0">
                {directorCancelreason.reason}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${directorCancelreason ? "show" : ""}`}
      ></div>
    </div>
  );
};

export default DirectorcancelJobReason;
