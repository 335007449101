const cities = [
    "Airdrie",
    "Grande Prairie",
    "Red Deer",
    "Beaumont",
    "Hanna",
    "St. Albert",
    "Bonnyville",
    "Hinton",
    "Spruce Grove",
    "Brazeau",
    "Irricana",
    "Strathcona County",
    "Breton",
    "Lacombe",
    "Strathmore",
    "Calgary",
    "Leduc",
    "Sylvan Lake",
    "Camrose",
    "Lethbridge",
    "Swan Hills",
    "Canmore",
    "McLennan",
    "Taber",
    "Didzbury",
    "Medicine Hat",
    "Turner Valley",
    "Drayton Valley",
    "Olds",
    "Vermillion",
    "Edmonton",
    "Onoway",
    "Wood Buffalo",
    "Ft. Saskatchewan",
    "Provost",
    "Burnaby",
    "Lumby",
    "City of Port Moody",
    "Cache Creek",
    "Maple Ridge",
    "Prince George",
    "Castlegar",
    "Merritt",
    "Prince Rupert",
    "Chemainus",
    "Mission",
    "Richmond",
    "Chilliwack",
    "Nanaimo",
    "Saanich",
    "Clearwater",
    "Nelson",
    "Sooke",
    "Colwood",
    "New Westminster",
    "Sparwood",
    "Coquitlam",
    "North Cowichan",
    "Surrey",
    "Cranbrook",
    "North Vancouver",
    "Terrace",
    "Dawson Creek",
    
    "Tumbler",
    "Delta",
    "Osoyoos",
   
    "Fernie",
    "Parksville",
    "Vancouver",
    "Invermere",
    "Peace River",
    "Vernon",
    "Kamloops",
    "Penticton",
    "Victoria",
    "Kaslo",
    "Port Alberni",
    "Whistler",
    "Langley",
    "Port Hardy",
    "Birtle",
    "Flin Flon",
    "Swan River",
    "Brandon",
    "Snow Lake",
    "The Pas",
    "Cranberry Portage",
    "Steinbach",
    "Thompson",
    "Dauphin",
    "Stonewall",
    "Winnipeg",
    "Cap-Pele",
    "Miramichi",
    "Saint John",
    "Fredericton",
    "Moncton",
    "Saint Stephen",
    "Grand Bay-Westfield",
    "Oromocto",
    "Shippagan",
    "Grand Falls",
    "Port Elgin",
    "Sussex",
    "Memramcook",
    "Sackville",
    "Tracadie-Sheila",
    "Argentia",
    "Corner Brook",
    "Paradise",
    "Bishop's Falls",
    "Labrador City",
    "Portaux Basques",
    "Botwood",
    "Mount Pearl",
    "St. John's",
    "Brigus",
    "Town of Hay River",
    "Town of Inuvik",
    "Yellowknife",
    "Amherst",
    "Hants County",
    "Pictou",
    "Annapolis",
    "Inverness County",
    "Pictou County",
    "Argyle",
    "Kentville",
    "Queens",
    "Baddeck",
    "County of Kings",
    "Richmond",
    "Bridgewater",
    "Lunenburg",
    "Shelburne",
    "Cape Breton",
    "Lunenburg County",
    "Stellarton",
    "Chester",
    "Mahone Bay",
    "Truro",
    "Cumberland County",
    "New Glasgow",
    "Windsor",
    "East Hants",
    "New Minas",
    "Yarmouth",
    "Halifax",
    "Parrsboro",
    "Ajax",
    "Halton",
    "Peterborough",
    "Atikokan",
    "Halton Hills",
    "Pickering",
    "Barrie",
    "Hamilton",
    "Port Bruce",
    "Belleville",
    "Hamilton-Wentworth",
    "Port Burwell",
    "Blandford-Blenheim",
    "Hearst",
    "Port Colborne",
    "Blind River",
    "Huntsville",
    "Port Hope",
    "Brampton",
    "Ingersoll",
    "Prince Edward",
    "Brant",
    "James",
    "Quinte West",
    "Brantford",
    "Kanata",
    "Renfrew",
    "Brock",
    "Kincardine",
    "Richmond Hill",
    "Brockville",
    "King",
    "Sarnia",
    "Burlington",
    "Kingston",
    "Sault Ste. Marie",
    "Caledon",
    "Kirkland Lake",
    "Scarborough",
    "Cambridge",
    "Kitchener",
    "Scugog",
    "Chatham-Kent",
    "Larder Lake",
    "Souix Lookout CoC Sioux Lookout",
    "Chesterville",
    "Leamington",
    "Smiths Falls",
    "Clarington",
    "Lennox-Addington",
    "South-West Oxford",
    "Cobourg",
    "Lincoln",
    "St. Catharines",
    "Cochrane",
    "Lindsay",
    "St. Thomas",
    "Collingwood",
    "London",
    "Stoney Creek",
    "Cornwall",
    "Loyalist Township",
    "Stratford",
    "Cumberland",
    "Markham",
    "Sudbury",
    "Deep River",
    "Metro Toronto",
    "Temagami",
    "Dundas",
    "Merrickville",
    "Thorold",
    "Durham",
    "Milton",
    "Thunder Bay",
    "Dymond",
    "Nepean",
    "Tillsonburg",
    "Ear Falls",
    "Newmarket",
    "Timmins",
    "East Gwillimbury",
    "Niagara",
    "Toronto",
    "East Zorra-Tavistock",
    "Niagara Falls",
    "Uxbridge",
    "Elgin",
    "Niagara-on-the-Lake",
    "Vaughan",
    "Elliot Lake",
    "North Bay",
    "Wainfleet",
    "Flamborough",
    "North Dorchester",
    "Wasaga Beach",
    "Fort Erie",
    "North Dumfries",
    "Waterloo",
    "Fort Frances",
    "North York",
    "Waterloo",
    "Gananoque",
    "Norwich",
    "Welland",
    "Georgina",
    "Oakville",
    "Wellesley",
    "Glanbrook",
    "Orangeville",
    "West Carleton",
    "Gloucester",
    "Orillia",
    "West Lincoln",
    "Goulbourn",
    "Osgoode",
    "Whitby",
    "Gravenhurst",
    "Oshawa",
    "Wilmot",
    "Grimsby",
    "Ottawa",
    "Windsor",
    "Guelph",
    "Ottawa-Carleton",
    "Woolwich",
    "Haldimand-Norfork",
    "Owen Sound",
    "York",
    "Alberton",
    "Montague",
    "Stratford",
    "Charlottetown",
    "Souris",
    "Summerside",
    "Cornwall",
    "Alma",
    "Fleurimont",
    "Longueuil",
    "Amos",
    "Gaspe",
    "Marieville",
    "Anjou",
    "Gatineau",
    "Mount Royal",
    "Aylmer",
    "Hull",
    "Montreal",
    "Beauport",
    "Joliette",
    "Montreal Region",
    "Bromptonville",
    "Jonquiere",
    "Montreal-Est",
    "Brosssard",
    "Lachine",
    "Quebec",
    "Chateauguay",
    "Lasalle",
    "Saint-Leonard",
    "Chicoutimi",
    "Laurentides",
    "Sherbrooke",
    "Coaticook",
    "LaSalle",
    "Sorel",
    "Coaticook",
    "Laval",
    "Thetford Mines",
    "Dorval",
    "Lennoxville",
    "Victoriaville",
    "Drummondville",
    "Levis",
    "Avonlea",
    "Melfort",
    "Swift Current",
    "Colonsay",
    "Nipawin",
    "Tisdale",
    "Craik",
    "Prince Albert",
    "Unity",
    "Creighton",
    "Regina",
    "Weyburn",
    "Eastend",
    "Saskatoon",
    "Wynyard",
    "Esterhazy",
    "Shell Lake",
    "Yorkton",
    "Gravelbourg",
    "Carcross",
    "Whitehorse"
  ];
  
  export const CanadianCities = cities.map(city => ({ value: city, label: city }));
 
  export const CanadianCity=[
    { value: "Frontenac County", label: "Frontenac County", zip: "K0H" },
    { value: "Airdrie East", label: "Airdrie East", zip: "T4A" },
    { value: "Airdrie West", label: "Airdrie West", zip: "T4B" },
    { value: "Athabasca", label: "Athabasca", zip: "T9S" },
    { value: "Banff", label: "Banff", zip: "T1L" },
    { value: "Barrhead", label: "Barrhead", zip: "T7N" },
    { value: "Beaumont", label: "Beaumont", zip: "T4X" },
    { value: "Bonnyville", label: "Bonnyville", zip: "T9N" },
    { value: "Brooks", label: "Brooks", zip: "T1R" },
    { value: "Calgary", label: "Calgary", zip: "T3S" },
    { value: "Calgary (Braeside / Woodbine)", label: "Calgary (Braeside / Woodbine)", zip: "T2W" },
    { value: "Calgary (Brentwood / Collingwood / Nose Hill)", label: "Calgary (Brentwood / Collingwood / Nose Hill)", zip: "T2L" },
    { value: "Calgary (Bridgeland / Greenview / Zoo / YYC)", label: "Calgary (Bridgeland / Greenview / Zoo / YYC)", zip: "T2E" },
    { value: "Calgary (City Centre / Calgary Tower)", label: "Calgary (City Centre / Calgary Tower)", zip: "T2P" },
    { value: "Calgary (Connaught / West Victoria Park)", label: "Calgary (Connaught / West Victoria Park)", zip: "T2R" },
    { value: "Calgary (Cranston)", label: "Calgary (Cranston)", zip: "T3M" },
    { value: "Calgary (Dalhousie / Edgemont / Hamptons / Hidden Valley)", label: "Calgary (Dalhousie / Edgemont / Hamptons / Hidden Valley)", zip: "T3A" },
    { value: "Calgary (Discovery Ridge / Signal Hill / Aspen Woods / Patterson / Cougar Ridge)", label: "Calgary (Discovery Ridge / Signal Hill / Aspen Woods / Patterson / Cougar Ridge)", zip: "T3H" },
    { value: "Calgary (Douglas Glen / McKenzie Lake / Copperfield / East Shepard)", label: "Calgary (Douglas Glen / McKenzie Lake / Copperfield / East Shepard)", zip: "T2Z" },
    { value: "Calgary (Elbow Park / Britannia / Parkhill / Mission)", label: "Calgary (Elbow Park / Britannia / Parkhill / Mission)", zip: "T2S" },
    { value: "Calgary (Forest Lawn / Dover / Erin Woods)", label: "Calgary (Forest Lawn / Dover / Erin Woods)", zip: "T2B" },
    { value: "Calgary (Hawkwood / Arbour Lake / Royal Oak / Rocky Ridge)", label: "Calgary (Hawkwood / Arbour Lake / Royal Oak / Rocky Ridge)", zip: "T3G" },
    { value: "Calgary (Highfield / Burns Industrial)", label: "Calgary (Highfield / Burns Industrial)", zip: "T2H" },
    { value: "Calgary (Inglewood / Burnsland / Chinatown / East Victoria Park / Saddledome)", label: "Calgary (Inglewood / Burnsland / Chinatown / East Victoria Park / Saddledome)", zip: "T2G" },
    { value: "Calgary (Kensington / Westmont / Parkdale / University)", label: "Calgary (Kensington / Westmont / Parkdale / University)", zip: "T2N" },
    { value: "Calgary (Lakeview / Glendale / Killarney / Glamorgan)", label: "Calgary (Lakeview / Glendale / Killarney / Glamorgan)", zip: "T3E" },
    { value: "Calgary (Lynnwood Ridge / Ogden / Foothills Industrial / Great Plains)", label: "Calgary (Lynnwood Ridge / Ogden / Foothills Industrial / Great Plains)", zip: "T2C" },
    { value: "Calgary (Martindale / Taradale / Falconridge / Saddle Ridge)", label: "Calgary (Martindale / Taradale / Falconridge / Saddle Ridge)", zip: "T3J" },
    { value: "Calgary (Midnapore / Sundance)", label: "Calgary (Midnapore / Sundance)", zip: "T2X" },
    { value: "Calgary (Millrise / Somerset / Bridlewood / Evergreen)", label: "Calgary (Millrise / Somerset / Bridlewood / Evergreen)", zip: "T2Y" },
    { value: "Calgary (Montgomery / Bowness / Silver Springs / Greenwood)", label: "Calgary (Montgomery / Bowness / Silver Springs / Greenwood)", zip: "T3B" },
    { value: "Calgary (Mount Pleasant / Capitol Hill / Banff Trail)", label: "Calgary (Mount Pleasant / Capitol Hill / Banff Trail)", zip: "T2M" },
    { value: "Calgary (Oak Ridge / Haysboro / Kingsland / Windsor Park)", label: "Calgary (Oak Ridge / Haysboro / Kingsland / Windsor Park)", zip: "T2V" },
    { value: "Calgary (Penbrooke Meadows / Marlborough)", label: "Calgary (Penbrooke Meadows / Marlborough)", zip: "T2A" },
    { value: "Calgary (Queensland Downs / Lake Bonavista / Willow Park / Acadia)", label: "Calgary (Queensland Downs / Lake Bonavista / Willow Park / Acadia)", zip: "T2J" },
    { value: "Calgary (Rosscarrock / Wildwood / Shaganappi / Sunalta)", label: "Calgary (Rosscarrock / Wildwood / Shaganappi / Sunalta)", zip: "T3C" },
    { value: "Calgary (Rundle / Whitehorn / Monterey Park)", label: "Calgary (Rundle / Whitehorn / Monterey Park)", zip: "T1Y" },
    { value: "Calgary (Sandstone / Harvest Hills / Coventry Hills / Panorama Hills / Beddington)", label: "Calgary (Sandstone / Harvest Hills / Coventry Hills / Panorama Hills / Beddington)", zip: "T3K" },
    { value: "Calgary (Symons Valley)", label: "Calgary (Symons Valley)", zip: "T3P" },
    { value: "Calgary (Thornecliffe / Tuxedo)", label: "Calgary (Thornecliffe / Tuxedo)", zip: "T2K" },
    { value: "Calgary (Tuscany / Scenic Acres)", label: "Calgary (Tuscany / Scenic Acres)", zip: "T3L" },
    { value: "Calgary Northeast", label: "Calgary Northeast", zip: "T3N" },
    { value: "Calgary Northwest", label: "Calgary Northwest", zip: "T3R" },
    { value: "Calgary South (Altadore / Bankview / Richmond)", label: "Calgary South (Altadore / Bankview / Richmond)", zip: "T2T" },
    { value: "Camrose", label: "Camrose", zip: "T4V" },
    { value: "Canmore", label: "Canmore", zip: "T1W" },
    { value: "Central  (Stettler)", label: "Central  (Stettler)", zip: "T0C" },
    { value: "Central Foothills (Sundre)", label: "Central Foothills (Sundre)", zip: "T0M" },
    { value: "Chestermere", label: "Chestermere", zip: "T1X" },
    { value: "Coaldale", label: "Coaldale", zip: "T1M" },
    { value: "Cochrane", label: "Cochrane", zip: "T4C" },
    { value: "Cold Lake", label: "Cold Lake", zip: "T9M" },
    { value: "Devon", label: "Devon", zip: "T9G" },
    { value: "Drayton Valley", label: "Drayton Valley", zip: "T7A" },
    { value: "Eastern  (St. Paul)", label: "Eastern  (St. Paul)", zip: "T0A" },
    { value: "Edmonton (Central Beverly)", label: "Edmonton (Central Beverly)", zip: "T5W" },
    { value: "Edmonton (Central Bonnie Doon)", label: "Edmonton (Central Bonnie Doon)", zip: "T6C" },
    { value: "Edmonton (Central Jasper Place / Buena Vista)", label: "Edmonton (Central Jasper Place / Buena Vista)", zip: "T5R" },
    { value: "Edmonton (Central Londonderry)", label: "Edmonton (Central Londonderry)", zip: "T5C" },
    { value: "Edmonton (Central Mistatim)", label: "Edmonton (Central Mistatim)", zip: "T5V" },
    { value: "Edmonton (East Castledowns)", label: "Edmonton (East Castledowns)", zip: "T5X" },
    { value: "Edmonton (East Mill Woods)", label: "Edmonton (East Mill Woods)", zip: "T6L" },
    { value: "Edmonton (East North Central / West Beverly)", label: "Edmonton (East North Central / West Beverly)", zip: "T5B" },
    { value: "Edmonton (East Southeast Industrial / South Clover Bar)", label: "Edmonton (East Southeast Industrial / South Clover Bar)", zip: "T6P" },
    { value: "Edmonton (Ellerslie)", label: "Edmonton (Ellerslie)", zip: "T6X" },
    { value: "Edmonton (Glenora / SW Downtown Fringe)", label: "Edmonton (Glenora / SW Downtown Fringe)", zip: "T5N" },
    { value: "Edmonton (Heritage Valley)", label: "Edmonton (Heritage Valley)", zip: "T6W" },
    { value: "Edmonton (Kaskitayo)", label: "Edmonton (Kaskitayo)", zip: "T6J" },
    { value: "Edmonton (Landbank / Oliver / East Lake District)", label: "Edmonton (Landbank / Oliver / East Lake District)", zip: "T5Y" },
    { value: "Edmonton (Meadows)", label: "Edmonton (Meadows)", zip: "T6T" },
    { value: "Edmonton (North And East Downtown Fringe)", label: "Edmonton (North And East Downtown Fringe)", zip: "T5H" },
    { value: "Edmonton (North Capilano)", label: "Edmonton (North Capilano)", zip: "T6A" },
    { value: "Edmonton (North Central / Queen Mary Park / YXD)", label: "Edmonton (North Central / Queen Mary Park / YXD)", zip: "T5G" },
    { value: "Edmonton (North Clover Bar)", label: "Edmonton (North Clover Bar)", zip: "T6S" },
    { value: "Edmonton (North Downtown)", label: "Edmonton (North Downtown)", zip: "T5J" },
    { value: "Edmonton (North Jasper Place)", label: "Edmonton (North Jasper Place)", zip: "T5P" },
    { value: "Edmonton (North Westmount / West Calder / East Mistatim)", label: "Edmonton (North Westmount / West Calder / East Mistatim)", zip: "T5L" },
    { value: "Edmonton (Riverbend)", label: "Edmonton (Riverbend)", zip: "T6R" },
    { value: "Edmonton (SE Capilano / West Southeast Industrial / East Bonnie Doon)", label: "Edmonton (SE Capilano / West Southeast Industrial / East Bonnie Doon)", zip: "T6B" },
    { value: "Edmonton (South Bonnie Doon / East University)", label: "Edmonton (South Bonnie Doon / East University)", zip: "T6E" },
    { value: "Edmonton (South Downtown / South Downtown Fringe)", label: "Edmonton (South Downtown / South Downtown Fringe)", zip: "T5K" },
    { value: "Edmonton (South Industrial)", label: "Edmonton (South Industrial)", zip: "T6N" },
    { value: "Edmonton (South Westmount / Groat Estate / East Northwest Industrial)", label: "Edmonton (South Westmount / Groat Estate / East Northwest Industrial)", zip: "T5M" },
    { value: "Edmonton (Southgate / North Riverbend)", label: "Edmonton (Southgate / North Riverbend)", zip: "T6H" },
    { value: "Edmonton (West Castledowns)", label: "Edmonton (West Castledowns)", zip: "T6V" },
    { value: "Edmonton (West Clareview / East Londonderry)", label: "Edmonton (West Clareview / East Londonderry)", zip: "T5A" },
    { value: "Edmonton (West Lake District)", label: "Edmonton (West Lake District)", zip: "T5Z" },
    { value: "Edmonton (West Londonderry / East Calder)", label: "Edmonton (West Londonderry / East Calder)", zip: "T5E" },
    { value: "Edmonton (West Mill Woods)", label: "Edmonton (West Mill Woods)", zip: "T6K" },
    { value: "Edmonton (West Northwest Industrial / Winterburn)", label: "Edmonton (West Northwest Industrial / Winterburn)", zip: "T5S" },
    { value: "Edmonton (West University / Strathcona Place)", label: "Edmonton (West University / Strathcona Place)", zip: "T6G" },
    { value: "Edmonton Southwest", label: "Edmonton Southwest", zip: "T6M" },
    { value: "Edmonton West (West Jasper Place / West Edmonton Mall)", label: "Edmonton West (West Jasper Place / West Edmonton Mall)", zip: "T5T" },
    { value: "Edson", label: "Edson", zip: "T7E" },
    { value: "Fort McMurray Inner Central", label: "Fort McMurray Inner Central", zip: "T9J" },
    { value: "Fort McMurray Northwest", label: "Fort McMurray Northwest", zip: "T9K" },
    { value: "Fort McMurray Outer Central", label: "Fort McMurray Outer Central", zip: "T9H" },
    { value: "Fort Saskatchewan", label: "Fort Saskatchewan", zip: "T8L" },
    { value: "Grande Prairie Central", label: "Grande Prairie Central", zip: "T8V" },
    { value: "Grande Prairie East", label: "Grande Prairie East", zip: "T8X" },
    { value: "Grande Prairie South", label: "Grande Prairie South", zip: "T8W" },
    { value: "High River", label: "High River", zip: "T1V" },
    { value: "Hinton", label: "Hinton", zip: "T7V" },
    { value: "Innisfail", label: "Innisfail", zip: "T4G" },
    { value: "International Border Region (Cardston)", label: "International Border Region (Cardston)", zip: "T0K" },
    { value: "Kananaskis Country (Claresholm)", label: "Kananaskis Country (Claresholm)", zip: "T0L" },
    { value: "Lacombe", label: "Lacombe", zip: "T4L" },
    { value: "Leduc", label: "Leduc", zip: "T9E" },
    { value: "Lethbridge North", label: "Lethbridge North", zip: "T1H" },
    { value: "Lethbridge Southeast", label: "Lethbridge Southeast", zip: "T1K" },
    { value: "Lethbridge West And Central", label: "Lethbridge West And Central", zip: "T1J" },
    { value: "Lloydminster", label: "Lloydminster", zip: "T9V" },
    { value: "Medicine Hat Central", label: "Medicine Hat Central", zip: "T1A" },
    { value: "Medicine Hat North", label: "Medicine Hat North", zip: "T1C" },
    { value: "Medicine Hat South", label: "Medicine Hat South", zip: "T1B" },
    { value: "Morinville", label: "Morinville", zip: "T8R" },
    { value: "North Central  (Slave Lake)", label: "North Central  (Slave Lake)", zip: "T0G" },
    { value: "Northeastern  (Fort Chipewyan)", label: "Northeastern  (Fort Chipewyan)", zip: "T0P" },
    { value: "Northwestern  (High Level)", label: "Northwestern  (High Level)", zip: "T0H" },
    { value: "Okotoks", label: "Okotoks", zip: "T1S" },
    { value: "Olds", label: "Olds", zip: "T4H" },
    { value: "Peace River", label: "Peace River", zip: "T8S" },
    { value: "Ponoka", label: "Ponoka", zip: "T4J" },
    { value: "Red Deer Central", label: "Red Deer Central", zip: "T4N" },
    { value: "Red Deer County", label: "Red Deer County", zip: "T4E" },
    { value: "Red Deer North", label: "Red Deer North", zip: "T4P" },
    { value: "Red Deer South", label: "Red Deer South", zip: "T4R" },
    { value: "Redwood Meadows", label: "Redwood Meadows", zip: "T3Z" },
    { value: "Remote Northeast (Fitzgerald)", label: "Remote Northeast (Fitzgerald)", zip: "T0V" },
    { value: "Rocky Mountain House", label: "Rocky Mountain House", zip: "T4T" },
    { value: "Rocky View", label: "Rocky View", zip: "T1Z" },
    { value: "Sherwood Park Central", label: "Sherwood Park Central", zip: "T8E" },
  { value: "Sherwood Park East", label: "Sherwood Park East", zip: "T8G" },
  { value: "Sherwood Park Inner Southwest", label: "Sherwood Park Inner Southwest", zip: "T8C" },
  { value: "Sherwood Park Northwest", label: "Sherwood Park Northwest", zip: "T8H" },
  { value: "Sherwood Park Outer Southwest", label: "Sherwood Park Outer Southwest", zip: "T8B" },
  { value: "Sherwood Park West", label: "Sherwood Park West", zip: "T8A" },
  { value: "Southeastern (Drumheller)", label: "Southeastern (Drumheller)", zip: "T0J" },
  { value: "Spruce Grove North", label: "Spruce Grove North", zip: "T7X" },
  { value: "Spruce Grove South", label: "Spruce Grove South", zip: "T7Y" },
  { value: "St. Albert", label: "St. Albert", zip: "T8N" },
  { value: "St. Albert", label: "St. Albert", zip: "T8T" },
  { value: "Stony Plain", label: "Stony Plain", zip: "T7Z" },
  { value: "Strathmore", label: "Strathmore", zip: "T1P" },
  { value: "Sylvan Lake", label: "Sylvan Lake", zip: "T4S" },
  { value: "Taber", label: "Taber", zip: "T1G" },
  { value: "Vegreville", label: "Vegreville", zip: "T9C" },
  { value: "Vermilion", label: "Vermilion", zip: "T9X" },
  { value: "Wainwright", label: "Wainwright", zip: "T9W" },
  { value: "Wainwright Region (Tofield)", label: "Wainwright Region (Tofield)", zip: "T0B" },
  { value: "Western (Jasper)", label: "Western (Jasper)", zip: "T0E" },
  { value: "Westlock", label: "Westlock", zip: "T7P" },
  { value: "Wetaskiwin", label: "Wetaskiwin", zip: "T9A" },
  { value: "Whitecourt", label: "Whitecourt", zip: "T7S" },
  { value: "Abbotsford East", label: "Abbotsford East", zip: "V3G" },
  { value: "Abbotsford Southeast", label: "Abbotsford Southeast", zip: "V2S" },
  { value: "Abbotsford Southwest", label: "Abbotsford Southwest", zip: "V2T" },
  { value: "Abbotsford West", label: "Abbotsford West", zip: "V4X" },
  { value: "Atlin Region (Atlin)", label: "Atlin Region (Atlin)", zip: "V0W" },
  { value: "Burnaby (Burnaby Heights / Willingdon Heights / West Central Valley)", label: "Burnaby (Burnaby Heights / Willingdon Heights / West Central Valley)", zip: "V5C" },
  { value: "Burnaby (Cascade-Schou / Douglas-Gilpin)", label: "Burnaby (Cascade-Schou / Douglas-Gilpin)", zip: "V5G" },
  { value: "Burnaby (East Big Bend / Stride Avenue / Edmonds / Cariboo-Armstrong)", label: "Burnaby (East Big Bend / Stride Avenue / Edmonds / Cariboo-Armstrong)", zip: "V3N" },
  { value: "Burnaby (Government Road / Lake City / SFU / Burnaby Mountain)", label: "Burnaby (Government Road / Lake City / SFU / Burnaby Mountain)", zip: "V5A" },
  { value: "Burnaby (Lakeview-Mayfield / Richmond Park / Kingsway-Beresford)", label: "Burnaby (Lakeview-Mayfield / Richmond Park / Kingsway-Beresford)", zip: "V5E" },
  { value: "Burnaby (Maywood / Marlborough / Oakalla / Windsor)", label: "Burnaby (Maywood / Marlborough / Oakalla / Windsor)", zip: "V5H" },
  { value: "Burnaby (Parkcrest-Aubrey / Ardingley-Sprott)", label: "Burnaby (Parkcrest-Aubrey / Ardingley-Sprott)", zip: "V5B" },
  { value: "Burnaby (Suncrest / Sussex-Nelson / Clinton-Glenwood / West Big Bend)", label: "Burnaby (Suncrest / Sussex-Nelson / Clinton-Glenwood / West Big Bend)", zip: "V5J" },
  { value: "Campbell River Central", label: "Campbell River Central", zip: "V9W" },
  { value: "Campbell River Outskirts", label: "Campbell River Outskirts", zip: "V9H" },
  { value: "Cariboo And West Okanagan (100 Mile House)", label: "Cariboo And West Okanagan (100 Mile House)", zip: "V0K" },
  { value: "Castlegar", label: "Castlegar", zip: "V1N" },
  { value: "Cedar", label: "Cedar", zip: "V9X" },
  { value: "Central Island (Chemainus)", label: "Central Island (Chemainus)", zip: "V0R" },
  { value: "Central Okanagan And High Country (Revelstoke)", label: "Central Okanagan And High Country (Revelstoke)", zip: "V0E" },
  { value: "Central Saanich", label: "Central Saanich", zip: "V8M" },
  { value: "Chilcotin (Alexis Creek)", label: "Chilcotin (Alexis Creek)", zip: "V0L" },
  { value: "Chilliwack Central", label: "Chilliwack Central", zip: "V2P" },
  { value: "Chilliwack East", label: "Chilliwack East", zip: "V4Z" },
  { value: "Chilliwack West", label: "Chilliwack West", zip: "V2R" },
  { value: "Comox", label: "Comox", zip: "V9M" },
  { value: "Coquitlam North", label: "Coquitlam North", zip: "V3J" },
  { value: "Coquitlam South", label: "Coquitlam South", zip: "V3K" },
  { value: "Courtenay Central", label: "Courtenay Central", zip: "V9N" },
  { value: "Courtenay Northern Outskirts", label: "Courtenay Northern Outskirts", zip: "V9J" },
  { value: "Cranbrook", label: "Cranbrook", zip: "V1C" },
  { value: "Dawson Creek", label: "Dawson Creek", zip: "V1G" },
  { value: "Delta Central", label: "Delta Central", zip: "V4K" },
  { value: "Delta East", label: "Delta East", zip: "V4E" },
  { value: "Delta East Central", label: "Delta East Central", zip: "V4G" },
  { value: "Delta Northeast", label: "Delta Northeast", zip: "V4C" },
  { value: "Delta Southeast", label: "Delta Southeast", zip: "V4L" },
  { value: "Delta Southwest", label: "Delta Southwest", zip: "V4M" },
  { value: "Duncan", label: "Duncan", zip: "V9L" },
  { value: "East Kootenays (Fernie)", label: "East Kootenays (Fernie)", zip: "V0B" },
  { value: "Esquimalt", label: "Esquimalt", zip: "V9A" },
  { value: "Fort St. John", label: "Fort St. John", zip: "V1J" },
  { value: "Harrison Lake Region (Agassiz)", label: "Harrison Lake Region (Agassiz)", zip: "V0M" },
  { value: "Highlands", label: "Highlands", zip: "V9B" },
  { value: "Inside Passage And The Queen Charlottes (Queen Charlotte City)", label: "Inside Passage And The Queen Charlottes (Queen Charlotte City)", zip: "V0T" },
  { value: "Juan De Fuca Shore (Sooke)", label: "Juan De Fuca Shore (Sooke)", zip: "V0S" },
  { value: "Kamloops Central And Southeast", label: "Kamloops Central And Southeast", zip: "V2C" },
  { value: "Kamloops North", label: "Kamloops North", zip: "V2H" },
  { value: "Kamloops Northwest", label: "Kamloops Northwest", zip: "V2B" },
  { value: "Kamloops South And West", label: "Kamloops South And West", zip: "V2E" },
  { value: "Kamloops Southwest", label: "Kamloops Southwest", zip: "V1S" },
  { value: "Kelowna Central", label: "Kelowna Central", zip: "V1Y" },
  { value: "Kelowna East", label: "Kelowna East", zip: "V1P" },
  { value: "Kelowna East Central", label: "Kelowna East Central", zip: "V1X" },
  { value: "Kelowna North", label: "Kelowna North", zip: "V1V" },
  { value: "Kelowna Southwest", label: "Kelowna Southwest", zip: "V1W" },
  { value: "Kelowna West", label: "Kelowna West", zip: "V1Z" },
  { value: "Kimberley", label: "Kimberley", zip: "V1A" },
  { value: "Kitimat", label: "Kitimat", zip: "V8C" },
  { value: "Ladysmith", label: "Ladysmith", zip: "V9G" },
  { value: "Langley City", label: "Langley City", zip: "V3A" },
  { value: "Langley Township East", label: "Langley Township East", zip: "V4W" },
  { value: "Langley Township North", label: "Langley Township North", zip: "V1M" },
  { value: "Langley Township Northwest", label: "Langley Township Northwest", zip: "V2Y" },
  { value: "Langley Township Southwest", label: "Langley Township Southwest", zip: "V2Z" },
  { value: "Lower Skeena (Port Edward)", label: "Lower Skeena (Port Edward)", zip: "V0V" },
  { value: "Maple Ridge East", label: "Maple Ridge East", zip: "V2W" },
  { value: "Maple Ridge Northwest", label: "Maple Ridge Northwest", zip: "V4R" },
  { value: "Maple Ridge West", label: "Maple Ridge West", zip: "V2X" },
  { value: "Merritt", label: "Merritt", zip: "V1K" },
  { value: "Metchosin", label: "Metchosin", zip: "V9C" },
  { value: "Mission East", label: "Mission East", zip: "V2V" },
  { value: "Mission West", label: "Mission West", zip: "V4S" },
  { value: "Nanaimo Central", label: "Nanaimo Central", zip: "V9S" },
  { value: "Nanaimo North", label: "Nanaimo North", zip: "V9T" },
  { value: "Nanaimo Northwest", label: "Nanaimo Northwest", zip: "V9V" },
  { value: "Nanaimo South", label: "Nanaimo South", zip: "V9R" },
  { value: "Nelson", label: "Nelson", zip: "V1L" },
  { value: "New Westminster Northeast", label: "New Westminster Northeast", zip: "V3L" },
  { value: "New Westminster Southwest (Includes Annacis Island)", label: "New Westminster Southwest (Includes Annacis Island)", zip: "V3M" },
  { value: "North Central Island And Bute Inlet Region (Gold River)", label: "North Central Island And Bute Inlet Region (Gold River)", zip: "V0P" },
  { value: "North Vancouver East Central", label: "North Vancouver East Central", zip: "V7J" },
  { value: "North Vancouver Inner East", label: "North Vancouver Inner East", zip: "V7H" },
  { value: "North Vancouver North Central", label: "North Vancouver North Central", zip: "V7K" },
  { value: "North Vancouver Northwest", label: "North Vancouver Northwest", zip: "V7R" },
  { value: "North Vancouver Northwest Central", label: "North Vancouver Northwest Central", zip: "V7N" },
  { value: "North Vancouver Outer East", label: "North Vancouver Outer East", zip: "V7G" },
  { value: "North Vancouver South Central", label: "North Vancouver South Central", zip: "V7L" },
  { value: "North Vancouver Southwest", label: "North Vancouver Southwest", zip: "V7P" },
  { value: "North Vancouver Southwest Central", label: "North Vancouver Southwest Central", zip: "V7M" },
  { value: "Northern (Fort Nelson)", label: "Northern (Fort Nelson)", zip: "V0C" },
  { value: "Oak Bay North", label: "Oak Bay North", zip: "V8R" },
  { value: "Oak Bay South", label: "Oak Bay South", zip: "V8S" },
  { value: "Omineca And Yellowhead (Smithers)", label: "Omineca And Yellowhead (Smithers)", zip: "V0J" },
  { value: "Parksville", label: "Parksville", zip: "V9P" },
  { value: "Penticton", label: "Penticton", zip: "V2A" },
  { value: "Pitt Meadows", label: "Pitt Meadows", zip: "V3Y" },
  { value: "Port Alberni", label: "Port Alberni", zip: "V9Y" },
  { value: "Port Coquitlam Central", label: "Port Coquitlam Central", zip: "V3B" },
  { value: "Port Coquitlam North", label: "Port Coquitlam North", zip: "V3E" },
  { value: "Port Coquitlam South", label: "Port Coquitlam South", zip: "V3C" },
  { value: "Port Moody", label: "Port Moody", zip: "V3H" },
  { value: "Powell River", label: "Powell River", zip: "V8A" },
  { value: "Prince George East Central", label: "Prince George East Central", zip: "V2L" },
  { value: "Prince George North", label: "Prince George North", zip: "V2K" },
  { value: "Prince George South", label: "Prince George South", zip: "V2N" },
  { value: "Prince George West Central", label: "Prince George West Central", zip: "V2M" },
  { value: "Prince Rupert", label: "Prince Rupert", zip: "V8J" },
  { value: "Qualicum Beach", label: "Qualicum Beach", zip: "V9K" },
  { value: "Quesnel", label: "Quesnel", zip: "V2J" },
  { value: "Richmond (Sea Island / YVR)", label: "Richmond (Sea Island / YVR)", zip: "V7B" },
  { value: "Richmond Central", label: "Richmond Central", zip: "V6Y" },
  { value: "Richmond North", label: "Richmond North", zip: "V6X" },
  { value: "Richmond Northeast", label: "Richmond Northeast", zip: "V6V" },
  { value: "Richmond South", label: "Richmond South", zip: "V7A" },
  { value: "Richmond Southeast", label: "Richmond Southeast", zip: "V6W" },
  { value: "Richmond Southwest", label: "Richmond Southwest", zip: "V7E" },
  { value: "Richmond West", label: "Richmond West", zip: "V7C" },
  { value: "Saanich Central", label: "Saanich Central", zip: "V8Z" },
  { value: "Saanich East", label: "Saanich East", zip: "V8N" },
  { value: "Saanich North", label: "Saanich North", zip: "V8Y" },
  { value: "Saanich South", label: "Saanich South", zip: "V8X" },
  { value: "Saanich Southeast", label: "Saanich Southeast", zip: "V8P" },
  { value: "Saanich West", label: "Saanich West", zip: "V9E" },
  { value: "Salmon Arm", label: "Salmon Arm", zip: "V1E" },
  { value: "Saltspring Island", label: "Saltspring Island", zip: "V8K" },
  { value: "Sidney", label: "Sidney", zip: "V8L" },
  { value: "Similkameen (Hope)", label: "Similkameen (Hope)", zip: "V0X" },
  { value: "Sooke", label: "Sooke", zip: "V9Z" },
  { value: "South Okanagan (Summerland)", label: "South Okanagan (Summerland)", zip: "V0H" },
  { value: "Squamish", label: "Squamish", zip: "V8B" },
  { value: "Surrey East", label: "Surrey East", zip: "V3S" },
  { value: "Surrey Inner Northwest", label: "Surrey Inner Northwest", zip: "V3T" },
  { value: "Surrey Lower West", label: "Surrey Lower West", zip: "V3X" },
  { value: "Surrey North", label: "Surrey North", zip: "V3R" },
  { value: "Surrey Northeast", label: "Surrey Northeast", zip: "V4N" },
  { value: "Surrey Outer Northwest", label: "Surrey Outer Northwest", zip: "V3V" },
  { value: "Surrey South", label: "Surrey South", zip: "V4P" },
  { value: "Surrey Southwest", label: "Surrey Southwest", zip: "V4A" },
  { value: "Surrey Upper West", label: "Surrey Upper West", zip: "V3W" },
  { value: "Terrace", label: "Terrace", zip: "V8G" },
  { value: "Trail", label: "Trail", zip: "V1R" },
  { value: "Upper Columbia Region (Golden)", label: "Upper Columbia Region (Golden)", zip: "V0A" },
  { value: "Vancouver (Bentall Centre)", label: "Vancouver (Bentall Centre)", zip: "V7X" },
  { value: "Vancouver (Central Kitsilano)", label: "Vancouver (Central Kitsilano)", zip: "V6K" },
  { value: "Vancouver (Chaldecutt / South University Endowment Lands)", label: "Vancouver (Chaldecutt / South University Endowment Lands)", zip: "V6S" },  
]