import React from 'react'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

const CustomArrow = ({ next, previous, title }) => {
  return (

    <>
        <div className='slide--arow slide--arrow--aside'>
          <button className='left--arow' onClick={() => previous()}><BsArrowLeft /></button>
          <button className='right--arow' onClick={() => next()}><BsArrowRight /></button>
        </div>
      <div className='slider--heading'>
        <h2 className='playFairDisplay'>{title}</h2>
      </div>
    </>
  );
};

export default CustomArrow

