import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Cart from "./component/Cart";
import Home from "./pages/Home";
import SignUp from "./pages/auth/SignUp";
import Login from "./pages/auth/Login";

import AOS from "aos";
import "aos/dist/aos.css";
import LandingPage from "./pages/LandingPage";
import CreateTask from "./component/CreateTask";
import Task from "./component/Task";
import ViewTask from "./component/ViewTask";
import Layout from "./Layout/Layout";
import BecomeHustlerSignUp from "./pages/become-a-hustler";
import BecomeDirector from "./pages/become-a-director";
import DirectorCreateTask from "./pages/become-a-director/DirectorCreateTask";
import DirectorLayout from "./Layout/DirectorLayout";
import { useSelector } from "react-redux";
import DirectorJobs from "./pages/become-a-director/DirectorJobs";
import DirectorSearchHustler from "./pages/become-a-director/DirectorSearchHustler";
import HustlerLayout from "./Layout/HustlerLayout";
import HustlerHome from "./pages/become-a-hustler/HustlerHome";
import HustlerJobs from "./pages/become-a-hustler/HustlerJobs";
import Taskpropsal from "./pages/become-a-director/Taskpropsal";
import HustlerMessage from "./pages/become-a-director/HustlerMessage";
import HustlersLists from "./pages/become-a-director/HustlersLists";
import DirectorMessage from "./pages/become-a-hustler/DirectorMessage";
import DirectorNotifications from "./pages/become-a-director/DirectorNotifications";
import DirectorThankyouPage from "./common/DirectorThankyouPage";
import HustlerProfileView from "./pages/become-a-director/HustlerProfileView";
import InterstedHustler from "./pages/become-a-director/InterstedHustler";
import HustlerProfile from "./common/HustlerProfile";
import DirectorProfile from "./common/DirectorProfile";
import Chat from "./common/Chat";
import Error404 from "./common/Error404";
import HustlerBooking from "./pages/become-a-director/HustlerBooking";
import AllNotifications from "./common/AllNotifications";
import CommonLayout from "./Layout/CommonLayout";
import ForgotPassword from "./pages/auth/ForgotPassword";
import EmailTemplate from "./pages/email";
import SendOffer from "./pages/become-a-director/SendOffer";
import ScrollToTop from "./common/ScrollToTop";
import ViewOffer from "./pages/become-a-director/ViewOffer";
import TaskDetailsView from "./pages/become-a-hustler/TaskDetailsView";
import PaymentInvoice from "./pages/become-a-director/PaymentInvoice";
import AboutUs from "./pages/AboutUs";
import PaymentFailed from "./pages/become-a-director/PaymentFailed";
import SubscriptionTaskDetails from "./pages/become-a-director/SubscriptionTaskDetails";
import DirectorProfileView from "./pages/become-a-hustler/DirectorProfileView";
import HomePage from "./common/HomePage";
import TaskJobInfo from "./pages/become-a-hustler/TaskJobInfo";
import SubscriptionInvoice from "./pages/become-a-director/SubscriptionInvoice";
import ChangePassword from "./pages/auth/ChangePassword";



function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  const { accessToken } = useSelector((state) => state.auth);

  const userType = useSelector((state) => state.auth?.user?.userType);
  console.warn = () => { };
  console.error = () => { };
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />

        {accessToken && userType === "DIRECTOR" ? (
          <Route
            path="/home"
            element={
              <CommonLayout pageName="director-home">
                <BecomeDirector />
              </CommonLayout>
            }
          />
        ) : accessToken && userType === "HUSTLER" ? (
          <Route
            path="/home"
            element={
              <CommonLayout>
                <HustlerHome />
              </CommonLayout>
            }
          />
        ) : (
          <Route path="/home" element={<HomePage />} />
        )}

        {accessToken && userType === "DIRECTOR" ? (
          <Route
            path="/subscription-job-details"
            element={
              <CommonLayout pageName="inner">
                <SubscriptionTaskDetails />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

        {accessToken && userType === "DIRECTOR" && (
          <Route
            path="/subscription-invoice"
            element={
              <CommonLayout pageName="inner">
                <SubscriptionInvoice />
              </CommonLayout>
            }
          />
        )}

        <Route path="/email-template" element={<EmailTemplate />} />

        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {(accessToken && userType === "DIRECTOR") || "HUSTLER" ? (
          <Route
            path="/messages"
            element={
              <CommonLayout>
                <Chat />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

        {accessToken ? (
          <Route path="/view-task/:id" element={<ViewTask />} />
        ) : (
          <></>
        )}

        {accessToken ? (
          <Route
            path="/hustlr-jobs"
            element={
              <CommonLayout pageName="inner">
                <HustlerJobs />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

        {accessToken ? (
          <Route
            path="/create-task"
            element={
              <CommonLayout>
                <DirectorCreateTask />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

<Route path="/about-us" element={
          <Layout>
            <AboutUs />
          </Layout>
        } />

        <Route
          path="/become-a-creatr"
          element={
            <Layout pageName="inner">
              <Home />
            </Layout>
          }
        />

         <Route
          path="/become-a-hustlr"
          element={
            <Layout>
              <BecomeHustlerSignUp />
            </Layout>
          }
        />

        {accessToken ? (
          <Route
            path="/director-thanks"
            element={
              <CommonLayout pageName="thankyou">
                <DirectorThankyouPage />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

        <Route
          path="/payment-failed"
          element={
            <CommonLayout pageName="paymentfailed">
              <PaymentFailed />
            </CommonLayout>
          }
        />

        {accessToken && userType === "DIRECTOR" && (
          <Route
            path="/payment-invoice"
            element={
              <CommonLayout pageName="inner">
                <PaymentInvoice />
              </CommonLayout>
            }
          />
        )}

        <Route
          path="*"
          element={
            // <CommonLayout pageName="error404">
            <Error404 />
            // </CommonLayout>
          }
        />

        {}

        {accessToken ? (
          <Route
            path="/hustlrs-lists"
            element={
              <CommonLayout>
                <HustlersLists />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

        {accessToken && userType === "DIRECTOR" ? (
          <Route
            path="/profile"
            element={
              <CommonLayout>
                <DirectorProfile />
              </CommonLayout>
            }
          />
        ) : accessToken && userType === "HUSTLER" ? (
          <Route
            path="/profile"
            element={
              <CommonLayout>
                <HustlerProfile />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

{/* ========================= */}
{accessToken && userType === "DIRECTOR" ? (
          <Route
            path="/ChangePassword"
            element={
              <CommonLayout>
                <ChangePassword accessToken={accessToken} userType={userType} />
              </CommonLayout>
            }
          />
        ) : accessToken && userType === "HUSTLER" ? (
          <Route
            path="/ChangePassword"
            element={
              <CommonLayout>
                                <ChangePassword accessToken={accessToken} userType={userType} />

              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

{/*==========================*/}      
  {accessToken ? (
          <Route
            path="/your-task-list"
            element={
              <CommonLayout>
                <DirectorJobs />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

        {accessToken ? (
          <Route
            path="/task/:id"
            element={
              <CommonLayout>
                <Taskpropsal />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

        {accessToken ? (
          <Route
            path="/job-details/:id"
            element={
              <CommonLayout>
                <TaskDetailsView />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}
        {accessToken ? (
          <Route
            path="/job-info/:id"
            element={
              <CommonLayout>
                <TaskJobInfo />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

        {accessToken ? (
          <Route
            path="/all-notifications"
            element={
              <CommonLayout>
                <AllNotifications />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

        {accessToken ? (
          <Route
            path="/search-hustlr/:name/:province/:city"
            element={
              <CommonLayout>
                <DirectorSearchHustler />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

        {accessToken ? (
          <Route
            path="/hustlr-booking/:id"
            element={
              <CommonLayout>
                <HustlerBooking />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

        {accessToken && userType === "DIRECTOR" ? (
          <Route
            path="/user-info/:id"
            element={
              <CommonLayout>
                <HustlerProfileView />
              </CommonLayout>
            }
          />
        ) : accessToken && userType === "HUSTLER" ? (
          <Route
            path="/user-info/:id"
            element={
              <CommonLayout>
                <DirectorProfileView />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

        {accessToken ? (
          <Route
            path="/intersted-hustlr-profile/:hustlerId/:taskId"
            element={
              <CommonLayout>
                <InterstedHustler />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

        {accessToken ? (
          <Route
            path="/send-offer/:hustlerId/:taskId"
            element={
              <CommonLayout>
                <SendOffer />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}

        {accessToken ? (
          <Route
            path="/view-offer/:hustlerId/:taskId"
            element={
              <CommonLayout>
                <ViewOffer />
              </CommonLayout>
            }
          />
        ) : (
          <></>
        )}
      </Routes>
    </Router>
  );
}

export default App;
