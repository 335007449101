import React from "react";

const Wishlistmodal = ({ setopenModal, handleAddWishlistitem }) => {
  return (
    <div>
      <div
        className={`deletemodal modal ${handleAddWishlistitem ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-center">Please Confirm</h4>
              <button
                type="button"
                className="close"
                onClick={() => setopenModal(false)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-5 pb-5 confirmation--modal">
              <h5 className="mb-0">
            Do you really want to add this job in your saved jobs?
              </h5>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn"
                onClick={() => setopenModal(false)}
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                className="btn btn-dark darkbtn-hover"
                onClick={() => handleAddWishlistitem()}
              >
                <span>Add</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${handleAddWishlistitem ? "show" : ""}`}
      ></div>
    </div>
  );
};

export default Wishlistmodal;
