import React, { useState } from "react";
import { useSelector } from "react-redux";

const ConfirmDeniedJobModel = ({
  setshowdenieJobModel,
  handleCancelDeniedJob,
  handleDeniedJob,
}) => {
  const [reason, setreason] = useState("");

  const userType = useSelector((state) => state.auth?.user?.userType);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleDeniedJob(reason);
  };

  return (
    <div>
      <div
        className={`deletemodal modal ${setshowdenieJobModel ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <form
          onSubmit={handleSubmit}
          className="modal-dialog modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-center">Please Confirm</h4>
              <button
                type="button"
                className="close"
                onClick={handleCancelDeniedJob}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-5 pb-5 confirmation--modal">
              {userType == "DIRECTOR" ? (
                <h5 className="mb-0">
                  Are you sure you want to decline this job for this hustlr ?
                </h5>
              ) : (
                <h5 className="mb-0">
                  Are you sure you want to decline this job ?
                </h5>
              )}

              <br />
              <p style={{ textAlign: "left" }}>
                <strong>
                  Your Reason <span class="mendotry-field">*</span>{" "}
                </strong>
              </p>
              <textarea
                style={{
                  width: "100%",
                  border: "0px",
                  borderRadius: "10px",
                  padding: "10px 15px",
                  background: "var(--lightgray2)",
                }}
                onChange={(e) => setreason(e.target.value)}
                value={reason}
                required
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn"
                onClick={handleCancelDeniedJob}
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="btn btn-dark darkbtn-hover"
                // onClick={()=>handleDeniedJob(reason)}
              >
                <span>Confirm</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div
        className={`modal-backdrop ${setshowdenieJobModel ? "show" : ""}`}
      ></div>
    </div>
  );
};

export default ConfirmDeniedJobModel;
