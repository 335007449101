import { useEffect, useState } from "react";

export default function usePasswordValidation(
  password = "",
  minLength = 8,
  maxLength = 25
) {
  const [hasValidLength, setValidLength] = useState(null);
  const [hasNumber, setNumber] = useState(null);
  const [hasUpperCase, setUpperCase] = useState(null);
  const [hasLowerCase, setLowerCase] = useState(null);
  const [hasSpecialCharacter, setSpecialCharacter] = useState(null);

  

  useEffect(() => {
    setValidLength(password.length >= minLength && password.length < maxLength);
    setUpperCase(password.toLowerCase() !== password);
    setLowerCase(password.toUpperCase() !== password);
    setNumber(/\d/.test(password));
    setSpecialCharacter(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password));
  }, [
    password,
    minLength,
    maxLength
    // setValidLength,
    // setNumber,
    // setLowerCase,
    // setUpperCase
  ]);

  return { hasValidLength, hasNumber, hasUpperCase, hasLowerCase, hasSpecialCharacter };
}
