import React from 'react';

const DirectorJobFrequently = ({ item }) => {
    const counterOfferIds = item?.counterOfferIds?.length && !item.cancel && item.counterOfferAccepted === false ? 'cancelled--interested-job' : '';
    const directorAsked = item?.directorAsked ? 'waiting--msg' : '';
    const cancelClass = item?.cancel ? 'cancelled--interested-job' : '';
    const isMarkedCompleted = item?.taskCompletionId?.hustlerMarkedCompleted ? 'mark-status' : '';
    const stripePaymentClass = !item?.stripePaymentStatus ? 'confirmation-task' : '';
    const reasonForNotCompleted = item.taskCompletionId?.reasonForNotCompleted ? 'cancelled--interested-job' : '';

    const className = `${directorAsked} ${counterOfferIds} ${cancelClass} ${stripePaymentClass} ${isMarkedCompleted} ${reasonForNotCompleted}`.trim() || 'working--process';



    return (
        <div className={`task--items-down--footer ${className}`}>
            <p>Please note that this is a <b>{item?.subscriptionInterval}ly</b> job, and payment will be made on a <b>{item?.subscriptionInterval}ly</b> basis upon completion period. </p>
        </div>
    );
};

export default DirectorJobFrequently;
