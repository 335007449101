import React, { useEffect, useState } from 'react'
import { FaArrowDownLong, FaArrowRightLong, FaArrowUpLong } from "react-icons/fa6";
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authAxios } from '../services/config';
import { handleDecimalNumber } from '../utils/helper';
import ConfirmProgressTask from '../pages/become-a-director/ConfirmProgressTask';
import DirectorDeniedJobModel from './Modal/DirectorDeniedJobModel';
import { useSocket } from '../contexts/SocketContext';
import { setReportFormatDate } from '../assets/Helper';

const TaskNotificationDetails = () => {
    const location = useLocation();
    const socket = useSocket();
    const [toggleTaskList, setToggleTaskList] = useState(false);
    const [ProgressTask, setProgressTask] = useState([]);
    const [showConfirmProgressTask, setshowConfirmProgressTask] = useState(false);
    const [showDirectorDeniedJobModel, setshowDirectorDeniedJobModel] = useState(false);
    const [checkboxValue, setcheckboxValue] = useState("");
    const [ProgressTaskId, setProgressTaskId] = useState("");

    const toggleTaskListHandler = () => {
        setToggleTaskList(!toggleTaskList);
    }

    const fetchProgressTask = async () => {
        await authAxios()
            .get("/bookhustler/get-in-progress-task")
            .then((response) => {
                const resData = response.data;
                if (resData.status == 1) {
                    const filteredData = resData.data.filter(item => item.taskCompletionId && !item.taskCompletionId.reasonForNotCompleted);
                    setProgressTask(filteredData);
                } else {
                    toast.error(resData.message);
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            });
    };

    const handleSubmitProgressTask = async () => {
        await authAxios()
            .post(`/bookhustler/completed/${ProgressTaskId}`)
            .then((response) => {
                const resData = response.data;
                if (resData.status == 1) {
                    socket.emit("taskCompletedbyDirector", resData.data);
                    console.log(resData.data, "complete data");
                    toast.success(resData.message);
                    setshowConfirmProgressTask(false);
                    fetchProgressTask();
                } else {
                    toast.error(resData.message);
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    const handleDeniedProgressTask = async (id) => {
        setshowDirectorDeniedJobModel(true);
        setProgressTaskId(id);
        if (showDirectorDeniedJobModel == true) {
            const cancelledJobreason = id;
            const cancelPayloadreason = {
                reasonForNotCompleted: cancelledJobreason,
            };
            await authAxios()
                .post(
                    `/bookhustler/not-completed/${ProgressTaskId}`,
                    cancelPayloadreason
                )
                .then((response) => {
                    const resData = response.data;
                    if (resData.status == 1) {
                        socket.emit("taskNotCompleted", resData.data);
                        console.log(resData.data, "taskCompleted");
                        toast.success(resData.message);
                        setshowDirectorDeniedJobModel(false);
                        fetchProgressTask();
                        console.log(resData, "checking denied");
                    } else {
                        toast.error(resData.message);
                    }
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                });
        }
    };

    const handleShowProgressTaskPopup = (id) => {
        setcheckboxValue(id === checkboxValue ? "" : id);
        setProgressTaskId(id);
        setshowConfirmProgressTask(true);
    };

    useEffect(() => {
        fetchProgressTask();
    }, [])

    useEffect(() => {
        setToggleTaskList(false);
    }, [location]);

    return (
        <>
            {ProgressTask && ProgressTask?.length > 0 ? (
                <>
                    <div className={`task--completed--notification ${toggleTaskList === true ? 'active' : ''}`}>
                        <div className={`task--completed--header ${toggleTaskList === true ? 'active' : ''}`}>
                            <p className='heading--completed--task'>
                                <span className='marqu--mobile'>Your hustlr has completed their job. Please take immediate action to approve or decline</span> {toggleTaskList !== true ? <FaArrowRightLong /> : ''}
                            </p>
                            <button
                                className='toggle--notification'
                                onClick={toggleTaskListHandler}
                            >
                                <span className='button--text'>{toggleTaskList !== true ? 'Take Action' : 'Go Back'}</span>
                                {toggleTaskList !== true ?
                                    <FaArrowUpLong className='arrow--animation--up' />
                                    :
                                    <FaArrowDownLong className='arrow--animation--down' />
                                }
                            </button>
                        </div>
                        {console.log(ProgressTask,'ProgressTaskProgressTaskProgressTask')}
                        <div className='notifications--task--list custom--scroll'>
                            {
                                ProgressTask.map((item, index) => (
                                    <div className='notifications--task--list--item' key={index}>
                                        <div className='info--task--head'>
                                            <div className='profile--hustler'>
                                                <Link
                                                    to={`/user-info/${item?.hustlerId?._id}`}
                                                >
                                                    <img
                                                        src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.hustlerId?.profilePic}`}
                                                        className="profile-img"
                                                    />
                                                </Link>
                                                <div className='details--profile'>
                                                    <p>{item?.hustlerId?.fname} {item?.hustlerId?.lname}</p>
                                                    <img src="/assets/images/hustler-star.png" /> {' '}
                                                    {handleDecimalNumber(
                                                        item?.hustlerId?.hustlerDetails
                                                            ?.ratings
                                                    )}
                                                </div>
                                            </div>

                                            <div className='task--status'>
                                                Completed on {setReportFormatDate(item?.dueDate)}
                                            </div>

                                            <div className='task--status--action'>
                                                <Link className='link--task' to={`/task/${item._id}`}>Link</Link>


                                                <button
                                                    className="custom--btn darkbtn-hover minbutton"
                                                    onClick={() =>
                                                        handleShowProgressTaskPopup(item?._id)
                                                    }
                                                >
                                                    <span>
                                                        Mark as Completed
                                                    </span>
                                                </button>
                                                <button
                                                    className="custom--btn darkbtn-hover minbutton"
                                                    onClick={() =>
                                                        handleDeniedProgressTask(item?._id)
                                                    }
                                                >
                                                    <span>
                                                        Deny Completion
                                                    </span>
                                                </button>

                                            </div>
                                        </div>

                                        <div className='task--details'>
                                            <p className='head--task'><strong>{item?.title}</strong></p>
                                            <p>{item?.description
                                                ? item?.description?.length > 400 ? `${item?.description?.substring(0, 400)}...` : `${item?.description}`
                                                : "No description available"}</p>
                                        </div>
                                    </div>
                                ))
                            }


                        </div>
                    </div>
                </>
            ) : null}

            {showConfirmProgressTask && (
                <ConfirmProgressTask
                    setcheckboxValue={setcheckboxValue}
                    handleSubmitProgressTask={handleSubmitProgressTask}
                    setshowConfirmProgressTask={setshowConfirmProgressTask}
                />
            )}

            {showDirectorDeniedJobModel && (
                <DirectorDeniedJobModel
                    handleDeniedProgressTask={handleDeniedProgressTask}
                    setshowDirectorDeniedJobModel={setshowDirectorDeniedJobModel}
                />
            )}
        </>
    )
}

export default TaskNotificationDetails