import React, { useEffect, useState } from "react";
import { BsChatRightText } from "react-icons/bs";
import { FaSquare } from "react-icons/fa";
import { authAxios } from "../services/config";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setReportFormatDate } from "../assets/Helper";
import { Link, useNavigate } from "react-router-dom";
import { IoCheckmarkOutline } from "react-icons/io5";
import {
  setallnotifications,
  setnoficationLength,
} from "../Redux/Reducers/authSlice";
import { setParticipantDetails } from "../Redux/Reducers/chatSlice";
import { IoIosBriefcase } from "react-icons/io";
import Pagination from "./Pagination";
import IsLoadingHOC from "./IsLoadingHOC";

const AllNotifications = (props) => {
  const { setLoading } = props;
  const navigate = useNavigate();
  const userId = useSelector((state) => state.auth?.user?.userId);
  const userType = useSelector((state) => state.auth?.user?.userType);
  const [currentPage, setcurrentPage] = useState(1);
  const [ongoingPagination, setongoingPagination] = useState("unread");
  const [postsPerPage, setpostsPerPage] = useState(5);
  const [totalPosts, settotalPosts] = useState(0);
  const paginate = (pageNumber) => setcurrentPage(pageNumber);
  const dispatch = useDispatch();

  const allUnreadNotifications = useSelector(
    (state) => state?.auth?.allNotifications
  );

  const [activeButton, setActiveButton] = useState("unread");
  const [allReadNotifications, setallReadNotifications] = useState([]);

  const handlefetchReadNotification = async (number) => {
    setongoingPagination("read");
    if (number) {
      setcurrentPage(1);
    }
    setActiveButton("read");
    setLoading(true);
    await authAxios()
      .post(
        `/notifications/get-all-notification?page=${number || currentPage
        }&limit=${postsPerPage}`,
        {
          notificationsStatus: true,
        }
      )
      .then((response) => {
        const resData = response.data;

        setLoading(false);
        if (resData.status == 1) {
          settotalPosts(resData.count.total);
          setallReadNotifications(resData?.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const handlefetchUnReadNotification = async (number) => {
    if (number) {
      setcurrentPage(1);
    }
    setLoading(true);
    setActiveButton("unread");
    setongoingPagination("unread");
    await authAxios()
      .post(
        `/notifications/get-all-notification?page=${number || currentPage
        }&limit=${postsPerPage}`,
        {
          notificationsStatus: false,
        }
      )
      .then((response) => {
        const resData = response.data;
        setLoading(false);
        if (resData.status == 1) {
          dispatch(setnoficationLength(response.data.count.total));

          dispatch(setallnotifications(resData?.data));
          settotalPosts(resData.count.total);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const handleNotificationClick = async (notificationId, check) => {
    try {
      await authAxios().post(
        `/notifications/mark-notification-seen/${notificationId}`
      );

      if (check == "unread") {
        handlefetchUnReadNotification(1);
      } else if (check == "read") {
        handlefetchUnReadNotification(1);
      } else {
      }
    } catch (error) {
      console.error("Error marking notification as seen:", error);
    }
  };
  /*
  const handleallUnreadNotificationSeen = async () => {
    try {
      const notificationIds = allUnreadNotifications.map(
        (notification) => notification?._id
      );

      await Promise.all(
        notificationIds.map((notificationId) =>
          authAxios().post(
            `notifications/mark-notification-to-seen/${notificationId}`
          )
        )
      );
      setcurrentPage(1);

      handlefetchUnReadNotification(1);
    } catch (error) {
      console.error("Error marking all notifications as seen:", error);
    }
  };
  */

  const handleallUnreadNotificationSeen = async () => {
    await authAxios()
      .post(`/notifications/mark-notification-to-seen/${userId}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setcurrentPage(1);
          handlefetchUnReadNotification(1);
          toast.success(resData.message);
        } else {
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleSendDirectorMessage = (item) => {

    const payload = {
      participant_id: item.otherParticipants._id,
      taskId: item.taskId._id,
      profilePic: item.otherParticipants.profilePic,
      participant_name:
        item.otherParticipants.fname + " " + item.otherParticipants.lname,
      showWelcomeMessage: true,
    };


    dispatch(setParticipantDetails(payload));
    navigate("/messages");
  };

  const handleSendHustlerMessage = (item) => {

    const payload = {
      participant_id: item.otherParticipants._id,
      taskId: item.taskId._id,
      profilePic: item.otherParticipants.profilePic,
      participant_name:
        item.otherParticipants.fname + " " + item.otherParticipants.lname,
      showWelcomeMessage: true,
    };

    dispatch(setParticipantDetails(payload));
    navigate("/messages");
  };

  useEffect(() => {
    if (ongoingPagination == "unread") {
      handlefetchUnReadNotification();
    } else if (ongoingPagination == "read") {
      handlefetchReadNotification();
    }
  }, [currentPage]);

  useEffect(() => {
    handlefetchUnReadNotification();
  }, []);

  return (
    <>
      <div className="notification--container pt-117 px-3">
        <div className="heading--page">
          <h1 className="text--dark playFairDisplay heading-h1 mb-2">
            Notifications
          </h1>
        </div>

        <div className="notifications--lists">
          <div className="notifications--toggle">
            <div className="toggle--button">
              <button
                onClick={() => handlefetchUnReadNotification(1)}
                //  onClick={() => fetchAllnotificationlist(false)}
                className={`custom--btn transparent--btn minbutton ${activeButton == "unread"
                  ? "darkbtn-hover-active"
                  : "ligghtbtn-hover"
                  }`}
              >
                <span>
                  <BsChatRightText /> Unread
                </span>
              </button>

              <button
                //  onClick={() => fetchAllnotificationlist(true)}
                className={`custom--btn transparent--btn minbutton ${activeButton == "read"
                  ? "darkbtn-hover-active"
                  : "ligghtbtn-hover"
                  }`}
                onClick={() => handlefetchReadNotification(1)}
              >
                <span>
                  <BsChatRightText /> Read
                </span>
              </button>
            </div>
            {activeButton == "unread" &&
              allUnreadNotifications &&
              allUnreadNotifications?.length > 0 && (
                <div className="mark--all--read">
                  <button
                    onClick={handleallUnreadNotificationSeen}
                    className="custom--btn transparent--btn minbutton darkbtn-hover-active"
                  >
                    <span>
                      <IoCheckmarkOutline /> Mark All as Seen
                    </span>
                  </button>
                </div>
              )}
          </div>
          <ul>
            {activeButton == "unread" && (
              <>
                {allUnreadNotifications &&
                  allUnreadNotifications?.length > 0 ? (
                  allUnreadNotifications &&
                  allUnreadNotifications.map((item) => (
                    <>
                      <li>
                        <div className="notification--content--item">
                          <div
                            className={`check--notification ${item?.seen ? "marked--read" : ""
                              }`}
                            onClick={() =>
                              handleNotificationClick(item._id, "unread")
                            }
                          >
                            <FaSquare style={{ fill: "#E1E1E1" }} />
                          </div>
                          <div className="notification--content">
                            <p>{item?.message}</p>
                            <span>{setReportFormatDate(item?.createdAt)}</span>
                          </div>
                        </div>

                        {userType === "DIRECTOR" ? (
                          <>
                            {item?.message?.includes("message") ||
                              item?.message?.includes("image") ? (
                              <button
                                onClick={() => handleSendDirectorMessage(item)}
                                //onClick={() => navigate("/messages")}
                                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    minWidth: "100px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <BsChatRightText /> Message
                                </span>
                              </button>
                            ) : (
                              <Link
                                to={{
                                  pathname: "/your-task-list",
                                }}
                                state={{
                                  id:
                                    item?.taskId?.status == "open"
                                      ? "new-jobs"
                                      : item?.taskId?.status == "assigned"
                                        ? "in-progress"
                                        : item?.taskId?.status == "in-progress"
                                          ? "in-progress"
                                          : item?.taskId?.status == "completed"
                                            ? "completed-task" 
                                            : item?.taskId?.status == "closed"
                                            ? "in-closed"
                                            : "",
                                }}
                                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    minWidth: "100px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <IoIosBriefcase /> View Jobs
                                </span>
                              </Link>
                            )}
                          </>
                        ) : (
                          <>
                            {item?.message?.includes("message") ||
                              item?.message?.includes("image") ? (
                              <button
                                onClick={() => handleSendHustlerMessage(item)}
                                //onClick={() => navigate("/messages")}
                                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    minWidth: "100px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <BsChatRightText /> Message
                                </span>
                              </button>
                            ) : (
                              <>
                                <Link
                                  to={{
                                    pathname:
                                      !item?.taskProposalDetails
                                        ?.cancellation == ""
                                        ? "/hustlr-jobs"
                                        : item?.taskId?.status == "open"
                                          ? "/home"
                                          : item?.taskId?.status == "assigned"
                                            ? "/home"
                                            : item.taskId.cancel == true
                                              ? "/home"
                                              : "/hustlr-jobs",
                                  }}
                                  state={{
                                    id:
                                      item.taskId.cancel == true
                                        ? "incoming-jobs"
                                        : !item?.taskProposalDetails
                                          ?.cancellation == ""
                                          ? "cancelled-jobs"
                                          : item?.taskId?.status == "open"
                                            ? "interested-jobs"
                                            : item?.taskId?.status == "assigned"
                                              ? "incoming-jobs"
                                              : item?.taskId?.status == "in-progress"
                                                ? "upcoming"
                                                : item?.taskId?.status == "completed"
                                                  ? "completed-jobs"
                                                  : "",
                                  }}
                                  className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                >
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                      minWidth: "100px",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <IoIosBriefcase /> View Jobs
                                  </span>
                                </Link>
                              </>
                            )}
                          </>
                        )}
                      </li>
                    </>
                  ))
                ) : (
                  <div className="notifications--not--found">
                    <h5>There are currently no notifications available.</h5>
                  </div>
                )}
              </>
            )}
          </ul>
          <ul>
            {activeButton == "read" && (
              <>
                {allReadNotifications && allReadNotifications?.length > 0 ? (
                  allReadNotifications &&
                  allReadNotifications.map((item) => (
                    <>
                      <li>
                        <div className="notification--content--item">
                          <div
                            className={`check--notification ${item?.seen ? "marked--read" : ""
                              }`}
                          >
                            <FaSquare style={{ fill: "#E1E1E1" }} />
                          </div>

                          <div className="notification--content">
                            <p>{item?.message}</p>
                            <span>{setReportFormatDate(item?.createdAt)}</span>
                          </div>
                        </div>

                        {userType === "DIRECTOR" ? (
                          <>
                            {item?.message?.includes("message") ||
                              item?.message?.includes("image") ? (
                              <button
                                onClick={() => handleSendDirectorMessage(item)}
                                //onClick={() => navigate("/messages")}
                                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    minWidth: "100px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <BsChatRightText /> Message
                                </span>
                              </button>
                            ) : (
                              <Link
                                to={{
                                  pathname: "/your-task-list",
                                }}
                                state={{
                                  id:
                                    item?.taskId?.status == "open"
                                      ? "new-jobs"
                                      : item?.taskId?.status == "assigned"
                                        ? "in-progress"
                                        : item?.taskId?.status == "in-progress"
                                          ? "in-progress"
                                          : item?.taskId?.status == "completed"
                                            ? "completed-task"
                                            : "",
                                }}
                                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    minWidth: "100px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <IoIosBriefcase /> View Jobs
                                </span>
                              </Link>
                            )}
                          </>
                        ) : (
                          <>
                            {item?.message?.includes("message") ||
                              item?.message?.includes("image") ? (
                              <button
                                onClick={() => handleSendHustlerMessage(item)}
                                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    minWidth: "100px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <BsChatRightText /> Message
                                </span>
                              </button>
                            ) : (
                              <Link
                                to={{
                                  pathname:
                                    !item?.taskProposalDetails?.cancellation ==
                                      ""
                                      ? "/hustlr-jobs"
                                      : item?.taskId?.status == "open"
                                        ? "/home"
                                        : item?.taskId?.status == "assigned"
                                          ? "/home"
                                          : item.taskId.cancel == true
                                            ? "/home"
                                            : "/hustlr-jobs",
                                }}
                                state={{
                                  id:
                                    item.taskId.cancel == true
                                      ? "incoming-jobs"
                                      : !item?.taskProposalDetails
                                        ?.cancellation == ""
                                        ? "cancelled-jobs"
                                        : item?.taskId?.status == "open"
                                          ? "interested-jobs"
                                          : item?.taskId?.status == "assigned"
                                            ? "incoming-jobs"
                                            : item?.taskId?.status == "in-progress"
                                              ? "upcoming"
                                              : item?.taskId?.status == "completed"
                                                ? "completed-jobs"
                                                : "",
                                }}
                                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    minWidth: "100px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <IoIosBriefcase /> View Jobs
                                </span>
                              </Link>
                            )}
                          </>
                        )}
                      </li>
                    </>
                  ))
                ) : (
                  <div className="notifications--not--found">
                    <h5>There are currently no notifications available.</h5>
                  </div>
                )}
              </>
            )}
          </ul>

          <Pagination
            currentPage={currentPage}
            totalPosts={totalPosts}
            paginate={paginate}
            postsPerPage={postsPerPage}
          />
        </div>
      </div>

      {activeButton == "unread" && (
        <>
          {allUnreadNotifications && allUnreadNotifications?.length > 0 ? (
            ""
          ) : (
            <div className="not--data--dedected"></div>
          )}
        </>
      )}

      {activeButton == "read" && (
        <>
          {allReadNotifications && allReadNotifications?.length > 0 ? (
            ""
          ) : (
            <div className="not--data--dedected"></div>
          )}
        </>
      )}
    </>
  );
};

export default IsLoadingHOC(AllNotifications);
