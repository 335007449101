import React from 'react'

const VideoHome = () => {
    return (
        <>
            <section className='video--home--container py-50' data-aos="fade-up" 
            data-aos-delay="100" data-aos-duration="1100">
                <div className='custom--container m-auto'>
                    <div className='video--home--content'>

                    <h2 className='text-white playFairDisplay heading-h2 text-center'  style={{marginTop:"-20px"}}
                   data-aos="fade-up"
                    data-aos-delay="10"
                     data-aos-duration="1000"
                     >
                    We Get It </h2>

<h5 className='text-white playFairDisplay heading-h2 text-center mb-4'
data-aos="fade-up"
 data-aos-delay="300" data-aos-duration="1300">
SideHustl was born from the need for an easier way. Being a Creatr is about reclaiming time and focusing on what matters most.
                                    </h5>
                        

                        <div className='video--section'>
                            {/* <img src='./assets/images/video--img.png' className='img-fluid' alt="video image" /> */}
                            <img src='./assets/images/weGet-transformed.jpg' className='img-fluid' style={{width:"100%"}} alt="video image" />

                        </div>

                        <div className='video--content'>
                            <h2 className='text-white playFairDisplay heading-h1 text-center' data-aos="fade-up" data-aos-delay="300" data-aos-duration="1300">
                            Your everyday life can be easier. 
                            </h2>
                            <div className='video--info flex sm-flex-col'>
                                                           
                                {/* <div
                                    className="v--info--box text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="300"
                                    data-aos-duration="1500"
                                >
                                    <img src="./assets/images/cost.png" alt="cost" />
                                    <h4 className="text-white">Trust</h4>
                                    <p>
                                    Hustlrs can opt for Certn verification, ensuring credibility and trust on our platform.
                                    </p>
                                </div> */}
                                
                                <div
                                    className="v--info--box text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="300"
                                    data-aos-duration="1800"
                                >
                                    <img src="./assets/images/secure.png" alt="cost" />
                                    <h4 className="text-white">Safety</h4>
                                    <p>
                                    Your information and data are protected while payments are always secure. 
                                    </p>
                                </div>
                                <div
                                    className="v--info--box text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="300"
                                    data-aos-duration="2100"
                                >
                                    <img src="./assets/images/verify.png" alt="cost" />
                                    <h4 className="text-white">Quality</h4>
                                    <p>
                                    We stand behind our Hustlrs and a job worth a referral is top priority. 
                                    </p>
                                </div>
                                <div
                                    className="v--info--box text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="300"
                                    data-aos-duration="2100"
                                >
                                    <img src="./assets/images/community.png" alt="cost" />
                                    <h4 className="text-white">Community</h4>
                                    <p>
                                    SideHustl has no hierarchy, everyone is equal and depends on one another. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VideoHome