import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { displayBudget, payloadBudget } from "../utils/helper";
import TextareaAutosize from "react-textarea-autosize";
import { setReportFormatDate } from "../assets/Helper";

const DirectorFirstCounterOfferModel = ({
  setfirstTimeCounterOffer,
  TaskDetail,
  CounterOffer,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [formdata, setformdata] = useState({
    reason: "",
    offerprice: "",
    offerdate: `${startDate}`,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformdata((prev) => ({
      ...prev,

      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    const payload = {
      hustlerId: TaskDetail?.hustlerId,
      taskId: TaskDetail.task._id,
      counterOffer: {
        reason: formdata.reason,
        offerPrice: payloadBudget(`${formdata.offerprice}`),

        proposalDate: startDate,
      },
    };


      CounterOffer(payload);
  };

  // console.log("sfd",TaskDetail)

  useEffect(() => {
    setStartDate(new Date(TaskDetail?.task?.dueDate));
  }, []);

  const handleCancel = () => {};
  return (
    <>
      <div
        className={`deletemodal modal ${
          setfirstTimeCounterOffer ? "show" : ""
        }`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header flex-column">
                <h4 className="modal-title w-100 text-center">
                  Please Confirm
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setfirstTimeCounterOffer(false)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pt-5 pb-5 confirmation--modal">
                <h5 className="mb-0 wordkeep">
                  Are you sure you want to make a counter offer?
                </h5>

                <div className="offerright--side">
                  <div className="request--task--work">
                    <div class="field--div" style={{zIndex:'2'}}>
                      <label>
                        Enter Your Job Date{" "}
                        <span className="mendotry-field">*</span>
                      </label>
                      <DatePicker
                        className="form-control"
                        minDate={new Date()}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="MMM-dd-yy"
                        required
                      />
                    </div>
                    <div class="field--div">
                      <label>
                        Enter Your Offer Price{" "}
                        <span className="mendotry-field">*</span>
                      </label>
                      <div className="input--dolldar">
                        <span className="dollar-icon">$</span>
                        <input
                          type="text"
                          name="offerprice"
                          value={displayBudget(`${formdata?.offerprice}`)}
                          onChange={handleChange}
                          required
                          placeholder="0"
                        />
                      </div>
                    </div>
                    <div class="field--div">
                      <label>
                        Reason <span className="mendotry-field">*</span>
                      </label>
                      <TextareaAutosize
                        required
                        minRows={3}
                        maxRows={4}
                        value={formdata.reason}
                        onChange={handleChange}
                        name="reason"
                      />
                    </div>

                    <div
                      className="modal-footer"
                      style={{
                        width: "100%",
                        padding: "0px",
                      }}
                    >
                      <button
                        type="button"
                        className="custom--btn ligghtbtn-hover transparent--btn"
                        onClick={() => setfirstTimeCounterOffer(false)}
                      >
                        <span>Cancel</span>
                      </button>
                      <button
                        type="submit"
                        className="btn btn-dark darkbtn-hover"
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${setfirstTimeCounterOffer ? "show" : ""}`}
      ></div>
    </>
  );
};

export default DirectorFirstCounterOfferModel;
