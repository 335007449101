import React from 'react'
import { BsChatRightText } from 'react-icons/bs'
import { Link } from "react-router-dom"
import IsLoadingHOC from './IsLoadingHOC'

const Error404 = () => {
    return (
        <>
            <div className='thankyou--container'>
                <div className='thankyou--col error--page'>
                    <img src="/assets/images/404-img.png" />
                    <h1 className='text--dark playFairDisplay'>000000ps</h1>
                    <p>Page Not Found</p>
                    <div className='button--thankyou'>

                        <Link to='/' className="custom--btn ligghtbtn-hover transparent--btn minbutton">
                            <span>
                                Home
                            </span>
                        </Link>

                    </div>
                </div>
            </div>
        </>
    )
}

export default IsLoadingHOC(Error404)