import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CustomArrow from './TrendingServicesArrow';
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";



function TrendingSlider() {

    const TrendingService = [
        {
            text: "Tree Services",
            image: "./assets/images/slider/TreeServices.jpg",
        },
        {
            text: "Interior stain and paint",
            image: "./assets/images/slider/slide-2.png",
        },
        {
            text: "Moving & Delivery",
            image: "./assets/images/slider/deliverys.jpg",
        },
        {
            text: "Furniture Assembly",
            image: "./assets/images/slider/slide-4.png",
        },
        {
            text: "Lawn Care",
            image: "./assets/images/slider/lawncare.jpg",
        },
        {
            text: "Window Cleaning",
            image: "./assets/images/slider/WindowCleaning.jpg",
        }
    ];

    return (
        <>
            <section className='trending--services py-25'>

                <div className='slider--container' data-aos="fade-up" 
                data-aos-offset="50" data-aos-duration="1000">
                    {/* <div className='slider--heading'>
                        <h2 className='playFairDisplay'>Trending Services</h2>
                        <div className='slide--arow'>
                            <button><BsArrowLeft /></button>
                            <button><BsArrowRight /></button>
                        </div>
                    </div> */}

                    <Carousel
                        additionalTransfrom={0}
                        autoPlaySpeed={3000}
                        arrows={false}
                        containerClass="carousel-container services--list"
                        renderButtonGroupOutside={true}
                        customButtonGroup={<CustomArrow title={'Explore Local Hustlrs By Service'} />}
                        infinite
                        draggable={false}
                        partialVisible
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 1200
                                },
                                items: 4,
                                // partialVisibilityGutter: 30
                            },
                            
                            mobile: {
                                breakpoint: {
                                    max: 767,
                                    min: 0
                                },
                                items: 1,
                                // partialVisibilityGutter: 30
                            },
                            tablet: {
                                breakpoint: {
                                    max: 1200,
                                    min: 767
                                },
                                items: 3,
                                // partialVisibilityGutter: 30
                            }
                        }}
                        slidesToSlide={1}
                        swipeable={false}
                    >
                        {TrendingService.map((item, index) => {
                            if (item.text.length > 0) {
                                return (
                                    <div key={index} className='service--item'>
                                        <h3>{item.text}</h3>
                                        <img src={item.image} alt={item.text} 
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={index}>
                                        <p>This item has no text.</p>
                                    </div>
                                );
                            }
                        })}
                    </Carousel>
                </div>

                {/* <div className='custom--container m-auto text-center' data-aos="fade-up" data-aos-offset="10" data-aos-duration="1100">
                    <button style={{padding:"5px 15px",fontSize: "12px",maxWidth:"100px"}}
                    className='custom--btn--radius mt-25 darkbtn-hover'>
                        <span>See More</span></button>
                </div> */}

                <Link to="/signup" 
                state={{ id: "director" }}
                data-aos="fade-up"
                data-aos-duration="1000"

                 style={{marginTop: "20px", display: "block", textAlign: "center",justifyContent:'center'}} 
                 className='custom--btn darkbtn-hover flex mx-auto '

>
    <span>See More</span>
</Link>

            </section>
        </>
    );
}

export default TrendingSlider;
