
import { combineReducers } from 'redux';
import appReducer from './appSlice';
import authReducer from './authSlice';
import chatReducer from './chatSlice'


const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  chat: chatReducer
});

export default rootReducer;
