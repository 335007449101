import React from "react";

const TaskCompletionRejectedModal = ({
  rejectedTaskModal,
  setRejectedTaskModal,
}) => {
  console.log(rejectedTaskModal);
  return (
    <div>
      <div
        className={`deletemodal modal ${rejectedTaskModal ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document" style={{maxWidth:'525px'}}>
          <div className="modal-content">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-left">
                Why was this job labeled as incomplete?
              </h4>
              <button
                type="button"
                className="close"
                onClick={() =>
                  setRejectedTaskModal({ model: false, reason: "" })
                }
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body pt-3 pb-4">
              <p className="mb-0">
                {rejectedTaskModal.reason}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${rejectedTaskModal ? "show" : ""}`}
      ></div>
    </div>
  );
};

export default TaskCompletionRejectedModal;
