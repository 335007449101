import { io } from "socket.io-client";

const API_ENDPOINT = `${process.env.REACT_APP_BASEURL}`;

export const socketService = {
    connect,
};
function connect() {
    return new Promise((resolve, reject) => {
        const socketUrl = API_ENDPOINT 
        //|| 'wss://hustlenode.makemagento.com/socket.io/';
        const socket = io(socketUrl, {
            transports: ['websocket'], // Only use WebSocket transport
        });

        socket.on("connect", () => {
            resolve(socket);
        });
        // socket.on("disconnect", () => {
        //     console.log("Socket disconnected");
        // });
        // Don't disconnect the socket immediately after connecting
        //  socket.disconnect();
    });
}
// function connect() {
//     return new Promise((resolve, reject) => {
//         const socketUrl =API_ENDPOINT || 'wss://hustlenode.makemagento.com/socket.io/';
// const socket = io(socketUrl, {
//     transports: ['websocket']
// });
//         // const socket = io(API_ENDPOINT, {
//         //     autoConnect: false,
//         //   },{
//         //     transports: ['websocket'], // Only use WebSocket transport
//         //   });
//         // const socket = io('https://hustlenode.makemagento.com', {
//         //     transports: ['websocket'],
//         //     reconnection: false,
//         // });
//         // const socket = io(API_ENDPOINT, {
//         //     autoConnect: false,
//         //     transports: ['websocket', 'polling'],
//         // });
//           socket.connect(); 
//         // const socket = io({
//         //     transports: ['websocket'], // Only use WebSocket transport
//         //   });
//         socket.on("connect", () => {
//             resolve(socket);
//         });
//         socket.disconnect(); 
//     });
// }


