import React, { useState } from "react";
import { toast } from "react-toastify";
import { authAxios } from "../../services/config";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { setReportFormatDate } from "../../assets/Helper";
import { displayBudget, payloadBudget } from "../../utils/helper";

const RejectedJobReason = ({ setRejectedJobModal, rejectedJobDetails, setLoading }) => {
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [directorFinalPrice, setDirectorFinalPrice] = useState("");

  const handleReassignHustler = () => {
    setShowReassignModal(true);
  };

  const handleReaskHustler = async () => {

    try {
      if (!directorFinalPrice.trim()) {
        toast.error("Creatr final price is required");
        return;
      }
      setLoading(true);
      setRejectedJobModal(false);
      const { taskId, hustlerId } = rejectedJobDetails[0];
      const response = await authAxios().post("/bookhustler/reassign-hustler", {
        taskId,
        hustlerId,
        budget: payloadBudget(`${directorFinalPrice}`)
      });
      const resData = response.data;
      console.log(resData, "gfdhgjgkjghiu")

      if (resData.status === 1) {
        setLoading(false);
        toast.success(resData.message);
      } else {
        setLoading(false);
        toast.error(resData.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const handleCloseTask = async () => {
    await authAxios()
      .put(`/task/close-task/${rejectedJobDetails.taskId}`)
      .then((response) => {
        const resData = response.data;

        if (resData.status == 1) {
          toast.success(resData.message);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <div>
        <div
          className={`deletemodal modal ${setRejectedJobModal ? "show" : ""}`}
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header flex-column">
                <h4 className="modal-title w-100 text-left">
                  Rejected Task Reason
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setRejectedJobModal(false)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pt-4 pb-4">
                <ul className="list-group">
                  <li className="list-group-item" style={{ maxHeight: '50vh', overflowY: 'scroll' }}>
                    {rejectedJobDetails && rejectedJobDetails.map((detail, index) => (
                      <div key={index}>
                        {/* <p className="mb-1">
                        Expected budget: <strong>${displayBudget(`${detail.finalPrice}`)}</strong>
                      </p> */}
                        <p className="mb-0">
                          <strong>Reason:</strong> {detail.reason}
                        </p>
                        {/* <p className="mb-0">
                        Date: <strong>{setReportFormatDate(detail.createdAt)}</strong>
                      </p> */}
                      </div>
                    ))}
                  </li>
                </ul>
              </div>
              {/* {showReassignModal && (
                <div className="modal-body confirmation--modal pt-0">
                  <div className="request--task--work mt-0">
                    <>
                      <div className="field--div">
                        <label>Your final price:</label>
                        <input
                          type="text"
                          name="title"
                          onChange={(e) => setDirectorFinalPrice(e.target.value)}
                          value={displayBudget(directorFinalPrice)}
                          required
                        />
                      </div>
                    </>
                  </div>
                </div>
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  className="custom--btn ligghtbtn-hover transparent--btn"
                  onClick={handleCloseTask}
                >
                  <span>Close Job</span>
                </button>
                <button
                  type="submit"
                  onClick={showReassignModal ? handleReaskHustler : handleReassignHustler}
                  className="btn btn-dark darkbtn-hover"
                >
                  <span>{showReassignModal ? "Re-ask Hustler" : "Re-assign Hustler"}</span>
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <div
          className={`modal-backdrop ${setRejectedJobModal ? "show" : ""}`}
        ></div>
      </div>
    </>
  );
};

export default IsLoadingHOC(RejectedJobReason);
