import React from 'react'
import { Link } from "react-router-dom"
import IsLoadingHOC from '../../common/IsLoadingHOC'

const PaymentFailed = () => {
    return (
        <div className='thankyou--container'>
            <div className='thankyou--col payment--failed'>
                <h2 className='text--dark playFairDisplay'>Payment Failed</h2>
                <p>Please try again.</p>
                <div className='button--thankyou'>
                    <Link
                        to={{
                            pathname: '/your-task-list',
                        }}
                        state={{ id: "in-progress" }}
                        className="custom--btn ligghtbtn-hover transparent--btn minbutton">
                        <span>
                            Go Back
                        </span>
                    </Link>

                </div>
            </div>
        </div>
    )
}

export default IsLoadingHOC(PaymentFailed)