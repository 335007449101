// searchSlice.js
import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "search",
  initialState: {
    combineTask:[],
    tasks: [],
    searchJobs: [],
    keywords: "",
    city: [],
    services: [],
    interestedTask: "",
  },

  reducers: {
    CombineTaskOptions: (state, action) => {
      state.combineTask = action.payload;
    },
    saveTaskOptions: (state, action) => {
      state.tasks = action.payload;
    },
    saveSearchJobs: (state, action) => {
      state.searchJobs = action.payload;
    },
    saveKeywords: (state, action) => {
      state.keywords = action.payload;
    },
    saveCity: (state, action) => {
      state.city = action.payload;
    },
    saveServices: (state, action) => {
      state.services = action.payload;
    },
    removeData: (state) => {
      state.searchJobs = [];
      state.keywords = "";
      state.city = "";
    },
    setInterestTask: (state, action) => {
      state.interestedTask = action.payload;
    },
  },
});

export const {
  CombineTaskOptions,
  saveTaskOptions,
  removeData,
  saveSearchJobs,
  saveKeywords,
  saveCity,
  saveServices,
  setInterestTask
} = appSlice.actions;

export default appSlice.reducer;
