import React, { useEffect, useState } from "react";
import Select from "react-select";
import { authAxios } from "../../services/config";
import { IoIosCloseCircle, IoIosSearch } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { toast } from "react-toastify";
import { CanadianCity } from "../../utils/constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { State, City } from "country-state-city";

import {
  handleDecimalNumber,
  sortByBudget,
  sortByPrice,
  staticRatingFilter,
} from "../../utils/helper";
import { BiSolidCaretLeftSquare } from "react-icons/bi";
import Pagination from "../../common/Pagination";
import { GoArrowRight } from "react-icons/go";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
const DirectorSearchHustler = (props) => {
  const navigate = useNavigate();

  const taskServices = useSelector((state) => state.app?.services);

  const params = useParams();

  const { setLoading } = props;
  const [selectedService, setSelectedService] = useState(null);

  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedSort, setSelectedSort] = useState("");
  const taskOptions = useSelector((state) => state.app.tasks);
  const [filteredData, setFilteredData] = useState([]);
  const [hustler, sethustler] = useState([]);

  const [selectPricevalue, setselectPricevalue] = useState(null);
  const [selectBudgetvalue, setselectBudgetvalue] = useState([0, 150]);
  const [baseProfileBase, setBaseProfileBase] = useState("");
  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [stateCode, setStateCode] = useState("");
  //pagination

  const [currentPage, setcurrentPage] = useState(1);
  const [ongoingPagination, setongoingPagination] = useState("");
  const [postsPerPage, setpostsPerPage] = useState(3);
  const [totalPosts, settotalPosts] = useState(0);
  const paginate = (pageNumber) => setcurrentPage(pageNumber);
  const [favoriteHustlers, setFavoriteHustlers] = useState([]);

  const userType = useSelector((state) => state?.auth?.user?.userType);

  useEffect(() => {
    setSelectedService(params.name.replace(/-/g, " "));
    setSelectedCity({
      label: params.city.replace(/-/g, " "),
      value: params.city.replace(/-/g, " "),
    });
    setselectedProvince({
      label: params.province.replace(/-/g, " "),
      value: params.province.replace(/-/g, " "),
    });

    const payload={
      query: params.name.replace(/-/g, " "),
      location: params.city.replace(/-/g, " "),
      province: params.province.replace(/-/g, " "),
    }
    handleSearchHustlers(payload,1);
    setongoingPagination("all-searching")
  }, []);

  const fetchallUsers = async (number) => {
    setLoading(true);
    await authAxios()
      .get(
        `/search/get-all-hustlers?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      // .get(`/search/get-all-hustlers`)
      .then((response) => {
        const resData = response?.data;

        console.log(resData);

        setLoading(false);

        if (resData.status == 1) {
          settotalPosts(resData?.count?.total);
          sethustler(resData?.data);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleSelectProvince = (e) => {
    if (e) {
      setStateCode(e?.value);
      setselectedProvince(e);
      setSelectedCity([]);
    } else {
      setcityList([]);
      setSelectedCity([]);
      setStateCode("");
      setselectedProvince([]);
    }
  };

  const handleButtonSearch = async () => {
    const payload = {
      query: selectedService,
      location: selectedCity?.label,
      province: selectedProvince?.label,
      sort: selectedSort?.value,
    };
    handleSearchHustlers(payload, 1);

    setongoingPagination("all-searching");
    setcurrentPage(1);
  };

  const handleSearchSortBy = (item) => {
    if (item.value == selectedSort.value) {
      setSelectedSort("");
      if (
        selectedService == "" &&
        !selectedCity?.label &&
        !selectedProvince?.label
      ) {
        fetchallUsers(1);
        setongoingPagination("fetchallUsers");
      } else {
        const payload = {
          query: selectedService,
          location: selectedCity?.label,
          province: selectedProvince?.label,
        };
        handleSearchHustlers(payload, 1);

        setongoingPagination("all-searching");
        setcurrentPage(1);
      }
    } else {
      const payload = {
        query: selectedService,
        location: selectedCity?.label,
        province: selectedProvince?.label,
        sort: item?.value,
      };
      setSelectedSort(item);
      handleSearchHustlers(payload, 1);
      setongoingPagination("all-searching");
      setcurrentPage(1);
    }
  };

  const handleSearchHustlers = async (data, number) => {
    const previousData = {
      query: selectedService,
      location: selectedCity?.label,
      province: selectedProvince?.label,
      sort: selectedSort?.value,
    };

    setLoading(true);
    await authAxios()
      .post(
        `/search/search-and-sort-hustlers?page=${
          number || currentPage
        }&limit=${postsPerPage}`,
        data
      )
      .then((response) => {
        setLoading(false);
        const resData = response?.data;

        console.log("resData", resData);

        if (resData.status === 1) {
          sethustler(resData?.data?.hustlers);
          settotalPosts(resData?.data?.totalCount);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchFavorites = async () => {
    if(ongoingPagination=="favourite-hustler"){
      setSelectedSort("");
     setcurrentPage(1);
     fetchallUsers(1);
     setongoingPagination("fetchallUsers");
     setSelectedService("");
     setSelectedCity("");
     setselectedProvince("");
   }else{
     setSelectedSort("");
     setcurrentPage(1);
     handlefetchFavouriteHustlers(1);
     setongoingPagination("favourite-hustler");
     setSelectedService("");
     setSelectedCity("");
     setselectedProvince("");
   }
  };

  const handleSecondFavourites=()=>{
    setSelectedSort("");
    setcurrentPage(1);
    handlefetchFavouriteHustlers(1);
    setongoingPagination("favourite-hustler");
    setSelectedService("");
    setSelectedCity("");
    setselectedProvince("");
  }

  const handlefetchFavouriteHustlers = async (number) => {
    setLoading(true);
    await authAxios()
      .get(
        `/fav-hustler/get-fav-hustler?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        const resData = response?.data;
        console.log(resData, "fcffhgvhjbk");

        setLoading(false);

        if (resData.status === 1) {
          sethustler(resData?.data?.hustlers);
          settotalPosts(resData?.data?.totalCount);
        } else {
          //toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        //toast.error(error?.response?.data?.message);
      });
  };

  const handleProfileBaseMode = (buttonName) => {
    setBaseProfileBase(buttonName === baseProfileBase ? null : buttonName);
  };

  const handleToggleFavorite = async (userId) => {
    try {
      const checkFavorite = favoriteHustlers.some(
        (item) => item.user.id === userId
      );

      if (checkFavorite) {
        await authAxios().delete(`/fav-hustler/remove-favorites/${userId}`);
        toast.success("Hustler removed from favorites.");
        if (ongoingPagination == "favourite-hustler") {
          setFavoriteHustlers((prevHustlers) =>
            prevHustlers.filter((item) => item.user.id !== userId)
          );
          handleSecondFavourites();
        } else {
          setFavoriteHustlers((prevHustlers) =>
            prevHustlers.filter((item) => item.user.id !== userId)
          );
        }
      } else {
        await authAxios().post(`/fav-hustler/add-to-fav/${userId}`);
        toast.success("Hustler added to favorites.");
        setFavoriteHustlers((prevHustlers) => [
          ...prevHustlers,
          { user: { id: userId } },
        ]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred.");
    }
  };

  const checkFavoriteHustler = async () => {
    try {
      const response = await authAxios().get(`/fav-hustler/get-fav-hustler`);
      const resData = response?.data;
      setFavoriteHustlers(resData?.data?.hustlers || []);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Failed to fetch favorite hustlrs."
      );
    }
  };

  // useEffect(() => {
  //   checkFavoriteHustler();
  //   setprovinceList(
  //     State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
  //       value: isoCode,
  //       label: name,
  //     }))
  //   );
  // }, []);

  // useEffect(() => {
  //   if (stateCode) {
  //     setcityList(
  //       City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
  //         value: name,
  //         label: name,
  //       }))
  //     );
  //   }
  // }, [stateCode]);

  useEffect(()=>{
    setprovinceList(
 [ { value: "BC", label: "British Columbia" },
]
);

setcityList(
  [ {"value": "Kamloops", "label": "Kamloops"},
    {"value": "Kelowna","label": "Kelowna"},
    {"value": "Peachland","label": "Peachland"},
    {"value": "Penticton","label": "Penticton"},
    {"value": "Vernon","label": "Vernon"},
    {"value": "West Kelowna","label": "West Kelowna"},
  ]
)

},[])



  useEffect(() => {
    if (ongoingPagination == "fetchallUsers") {
      fetchallUsers();
    } else if (ongoingPagination == "favourite-hustler") {
      console.log(" handlefetchFavouriteHustlers called");
      handlefetchFavouriteHustlers();
    } else if (ongoingPagination == "all-searching") {
      handleSearchHustlers();
    } else {
    }
  }, [currentPage]);

  return (
    <>
      <section className="director--search--hustler pt-117">
        <div className="custom--container m-auto pt-0 pt-sm-5 pb-5">
          <div className="container--filter">
            <div className="filter--data">
              <h2>
                Filter
                <span className="down--arrow">
                  <BsChevronDown />
                </span>
                <div
                  className="arrow--view--profilebase"
                  onClick={() => handleProfileBaseMode("profile--toggle")}
                >
                  <BiSolidCaretLeftSquare />
                </div>
              </h2>

              <div
                className={`filter--data--div hustler--filter mt-0 ${
                  baseProfileBase === "profile--toggle"
                    ? "active--filter--toggle"
                    : ""
                }`}
              >
                <div
                  className="close c-pointer"
                  onClick={() => setBaseProfileBase(null)}
                >
                  <IoIosCloseCircle />
                </div>

                <div className="select--price">
                  <p className="filter--heading">Sort By</p>
                  <div className="checkbox--task">
                    <ul>
                      {staticRatingFilter &&
                        staticRatingFilter.length > 0 &&
                        staticRatingFilter.map((item, index) => (
                          <>
                            <li key={index}>
                              <div className="checkbox--custom">
                                <label
                                  htmlFor={`checkbox-${index}`}
                                  style={{ cursor: "pointer" }}
                                >
                                  {item.label}
                                </label>

                                <input
                                  type="checkbox"
                                  id={`checkbox-${index}`}
                                  checked={selectedSort?.value === item.value}
                                  onChange={() => handleSearchSortBy(item)}
                                />
                                <span className="checkmark"></span>
                              </div>
                            </li>
                          </>
                        ))}
                    </ul>
                  </div>
                  <div className="add--favorite">
                    <button
                      onClick={fetchFavorites}
                      className={`custom--btn minbutton ${
                        ongoingPagination === "favourite-hustler"
                          ? "darkbtn-hover"
                          : "ligghtbtn-hover transparent--btn"
                      }`}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        Favorite Hustlrs{" "}
                        {ongoingPagination === "favourite-hustler" ? (
                          <GoArrowRight />
                        ) : null}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="filter--data--search">
              <div className="filter--search--col">
                <div className="search--bar--banner flex sm-flex-col sm-justify-start responsive--search--bar">
                  <div className="need--select">
                    <input
                      required
                      placeholder="I need help with..."
                      className="need--select custom--input"
                      value={selectedService}
                      onChange={(e) => setSelectedService(e.target.value)}
                    />
                  </div>
                  <div className="city--zip">
                    <Select
                      options={provinceList}
                      className="select--custom province--select"
                      value={selectedProvince}
                      placeholder={`Select Province`}
                      onChange={handleSelectProvince}
                      // isSearchable={false}
                      isClearable
                    />
                  </div>
                  <div className="city--zip">
                    <Select
                      options={selectedProvince ? cityList : []}
                      className="select--custom"
                      value={selectedCity}
                      placeholder={`Select City`}
                      onChange={(option) => setSelectedCity(option)}
                      // isSearchable={false}
                      isClearable
                    />
                  </div>

                  {selectedService?.length > 0 || selectedProvince?.label ? (
                        <button
                          className="btn btn-dark submit--need"
                          onClick={handleButtonSearch}
                        >
                          Search Hustlr
                        </button>
                      ) : (
                        <button
                          className="btn btn-dark submit--need"
                          // style={{ cursor: "not-allowed" }}
                          onClick={() => fetchallUsers(1)}
                        >
                          Search Hustlr
                        </button>
                      )}
                </div>
              </div>

              {/* changecode */}

              {hustler && hustler.length > 0 ? (
                hustler.map((item, index) => (
                  <div className="filter--data--value" key={index}>
                    <div className="search--list--items">
                      <div
                        className="header--search--list--items"
                        style={{ justifyContent: "space-between" }}
                      >
                        <Link
                          to={`/user-info/${item?.user?.id}`}
                          className="thumb--header--search--list--items"
                        >
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.user?.profilePic}`}
                            alt="Profile"
                          />
                        </Link>

                        <div className="thumb--header--search--list--start">
                          <div className="div-1">
                            <h2
                              className="hustler--name"
                              style={{ marginBottom: "0px" }}
                            >
                              {item?.user?.fname} {item?.user?.lname}
                            </h2>
                            <p
                              style={{
                                marginBottom: "8px",
                                fontSize: "12px",
                                color: " #949494",
                                wordBreak: "keep-all",
                              }}
                            >
                              {item?.user?.hustlerDetails?.province},{" "}
                              {item?.user?.hustlerDetails?.location}
                            </p>
                            <h3 className="hustler--category">
                              {/* {item?.user?.hustlerDetails?.serviceName} */}
                              {item?.user?.hustlerDetails?.services.length >
                                0 &&
                                item?.user?.hustlerDetails?.services.map(
                                  (service, index, array) => (
                                    <span key={service._id} style={{width:'auto'}}>
                                      {service.serviceName}
                                      {index !== array.length - 1 && ", "}
                                    </span>
                                  )
                                )}
                              {/* {item?.user?.hustlerDetails?.services &&
                                item.user.hustlerDetails.services[0] && (
                                  <span
                                    key={
                                      item.user.hustlerDetails.services[0]._id
                                    }
                                  >
                                    {
                                      item.user.hustlerDetails.services[0]
                                        .serviceName
                                    }
                                  </span>
                                )} */}
                            </h3>
                          </div>
                          <tooltip
                            id="task--detail-id"
                            className="tooltip--task--details"
                          />
                          <div
                            data-tooltip-id="task--detail-id"
                            data-tooltip-content={
                              favoriteHustlers?.some(
                                (favorite) =>
                                  favorite?.user?.id === item?.user?.id
                              )
                                ? "Remove Favorite"
                                : "Add Favorite"
                            }
                            data-tooltip-place="top"
                            className="add--favorite--item"
                            onClick={() => handleToggleFavorite(item?.user?.id)}
                          >
                            {favoriteHustlers?.some(
                              (favorite) =>
                                favorite?.user?.id === item?.user?.id
                            ) ? (
                              <AiFillHeart />
                            ) : (
                              <AiOutlineHeart />
                            )}
                          </div>
                          {/*
                          <p>{item?.user?.hustlerDetails?.location}</p>
                          
                          <div className="div-2">
                            <p>
                              <small>Starting at:</small> $
                              {item?.user?.hustlerDetails?.price || "20"}.00/h
                            </p>
                          </div>
                           */}
                        </div>

                        <div className="thumb--header--search--list--middle">
                          <p>
                            <img src="/assets/images/hustler--taskdone.png" />{" "}
                            {item?.user?.hustlerDetails?.completedTask}{" "}
                            Completed Tasks
                          </p>
                          <p>
                            <img src="/assets/images/hustler-star.png" />{" "}
                            {handleDecimalNumber(
                              item?.user?.hustlerDetails?.ratings
                            )}
                          </p>
                        </div>

                        <div className="thumb--header--search--list--end">
                          <Link
                            to={`/hustlr-booking/${item?.user?.id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <button className="custom--btn btn btn-dark darkbtn-hover">
                              <span>View Profile</span>
                            </button>
                          </Link>
                          {/*  <p className="save--later">Save for later</p>*/}
                        </div>
                      </div>

                      <div className="middle--search--list--items">
                        <ul>
                          {/* {item?.user?.hustlerDetails?.category.map(
                            (item, index) => (
                              <>
                                <li key={index}>{item?.value}</li>
                              </>
                            )
                          )} */}
                          {/* {item?.user?.hustlerDetails?.services && item.user.hustlerDetails.services.length > 0 && (
                            <>
                              {item.user.hustlerDetails.services[0].category.slice(0, 2).map(category => (
                                <li key={category._id}>{category.label}</li>
                              ))}
                            </>
                          )} */}
                          {/* {item?.user?.hustlerDetails?.services &&
                            item.user.hustlerDetails.services.length > 0 && (
                              <>
                                {item.user.hustlerDetails.services[0].category
                                  .slice(0, 2)
                                  .map((category) => (
                                    <li key={category._id}>{category.label}</li>
                                  ))}
                              </>
                            )} */}

                          {item?.user?.hustlerDetails?.services.map((service) =>
                            service.category.map((category) => (
                              <li
                                key={category._id}
                                className="category--profile--list"
                              >
                                {category.label}
                              </li>
                            ))
                          )}
                        </ul>
                      </div>

                      <div className="description--end--search--list--items">
                        <p>{item?.user?.description}</p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    padding: "clamp(15px, 2vw, 30px) 15px",
                  }}
                >
                  No hustlrs found
                </div>
              )}
              <div
                style={{
                  paddingTop: "20px",
                }}
              >
                <Pagination
                  currentPage={currentPage}
                  totalPosts={totalPosts}
                  paginate={paginate}
                  postsPerPage={postsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {baseProfileBase === "profile--toggle" && (
        <div className="hustler--filter--overlay"></div>
      )}
    </>
  );
};

export default IsLoadingHOC(DirectorSearchHustler);