import React from "react";
import { Link } from "react-router-dom";

const WhyHustler = () => {
  return (
    <>
    {/* Hustl. Earn. Repeat. 
SideHustl was built for those who want to get things done efficiently and with skilled people. We believe that hard work should be rewarded and jobs should be easy to find.

Land the job and get paid the same day, with SideHustl. */}

                
      <section
        className="video--home--container py-50"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1300"
      >
        <div className="custom--container m-auto ">
          <div className="video--home--content">
          {/* <h2
                className="text-white playFairDisplay heading-h1 text-center"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1300"
              >
                  
              </h2> */}
              <h2 className='text-white playFairDisplay heading-h2 text-center' 
               style={{marginTop:"-20px"}}
              //  data-aos-delay="10"
                // data-aos-duration="800"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1300"


               >
              Hustl. Earn. Repeat.</h2>

              <h5 className='text-white playFairDisplay text-center heading-h2  mb-4' 
              style={{marginLeft:"50px", marginRight:"40px"}} 
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1300"
            >
              At SideHustl, we make finding the right job simple and ensure your hard work is rewarded.
              </h5>

                                  {/* <h6 className='text-white playFairDisplay text-center heading-h2  mb-4' 
                                  data-aos-delay="300" data-aos-duration="1300" style={{marginLeft:"50px"}}>
                                 Land the job and get paid with side hustl      
                                  </h6> */}
                     

        {/* <div data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1300">
              <h5 className='text-white playFairDisplay  text-center'>
              SideHustl was built for those who want to get things done efficiently and with skilled people.<br/> We believe that hard work should be rewarded and jobs should be easy to find.
              </h5>
              <h5 className='text-white playFairDisplay text-center mb-4'>
             Land the job and get paid the same day, with SideHustl.      

               </h5>

               </div> */}
               
            <div className="video--section">
              <img
                // src="./assets/images/whyhustler.png"
                src="./assets/images/earn.jpg"
                style={{width:"100%"}}

                className="img-fluid"
                alt="why hustler image"
              />

            </div>
            <div className="video--content">
              <h2
                className="text-white playFairDisplay heading-h1 text-center"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1300"
              >
                You bring the skill, we’ll do the rest. 
              </h2>
              <div className="video--info flex">
              <div
                  className="v--info--box text-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="1800"
                >



                  <img src='./assets/images/secure.png' alt="cost" />
                  <h4 className='text-white'>Safety</h4>
                  <p>Your profile is protected and <br></br> pre-payment  is held securely to ensure you get paid on time. 
                  </p>
                </div>
                <div
                  className="v--info--box text-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="1500"
                >
                                    

                  <img src='./assets/images/verify.png' alt="cost" />

                  <h4 className='text-white'>Quality</h4>
                  <p>We stand behind our Hustlrs’ good work and ensure a job well done is recognized.
                  </p>
                </div>


                <div
                  className="v--info--box text-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="2100"
                >
                  {/* <img src='./assets/images/verify.png' alt="cost" /> */}
                  <img src='./assets/images/community.png' alt="cost" />

                  <h4 className='text-white'>Community</h4>
                  <p>SideHustl has no hierarchy, everyone is equal and depends on one another.</p>
                </div>
              </div>


              {/* <Link
                to="/signup"
                className="custom--btn ligghtbtn-hover flex mx-auto mt-25"
                style={{ alignItems: "center", justifyContent: 'center' }}
              >
                <span>Sign Up as a Hustlr</span>
              </Link> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyHustler;
