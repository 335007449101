import React from "react";
import { BudgetFormat } from "../../utils/helper";

const ShowOfferModal = ({ setshowOfferModel, offerDetails }) => {
  console.log(offerDetails, "srdfghbjnkmfdghjnkm");

  return (
    <div>
      <div
        className={`deletemodal modal ${setshowOfferModel ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-left">Creatr's Offer</h4>
              <button
                type="button"
                className="close"
                onClick={() => setshowOfferModel(false)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body pt-3 pb-4">
              <ul className="list-group">
                {offerDetails.map((offer) => (
                  <li key={offer._id} className="list-group-item">
                    <p className="mb-1">Task Title: <strong>{offer?.contractTitle}</strong></p>
                    <p className="mb-0">
                      Description: <strong>{offer?.contractDescription}</strong>
                    </p>
                    <p className="mb-0">Budget: <strong>{BudgetFormat(`${offer.budget}`)}</strong></p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${setshowOfferModel ? "show" : ""}`}
      ></div>
    </div>
  );
};

export default ShowOfferModal;
