import React from "react";
import { setReportFormatDate } from "../../assets/Helper";

const ShowRejectedReason = ({ showRejectionModel, rejectionDetails, setshowRejectionModel }) => {
    return (
        <div>
            <div
                className={`deletemodal modal ${showRejectionModel ? "show" : ""}`}
                tabIndex="-1"
                role="dialog"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header flex-column">
                            <h4 className="modal-title w-100 text-left">Reason Job was Declined</h4>
                            <button
                                type="button"
                                className="close"
                                onClick={() => setshowRejectionModel(false)}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body pt-3 pb-4">
                            <ul className="list-group">
                                <li className="list-group-item" style={{ maxHeight: '50vh', overflowY: 'scroll' }}>
                                    {rejectionDetails.map((detail, index) => (
                                        <div key={index} className="list-group-item">
                                            {/* <p className="mb-1"><strong>Your Price :</strong> ${detail.finalPrice}</p> */}
                                            <p className="mb-0"><strong>Your Reason :</strong> {detail.reason}</p>
                                            {/* <p className="mb-0"><strong>Date:</strong> {setReportFormatDate(detail.createdAt)}</p> */}

                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal-backdrop ${showRejectionModel ? "show" : ""}`}
            ></div>
        </div>
    );
};

export default ShowRejectedReason;
