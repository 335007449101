import React from "react";

const DeleteProgressTaskModal = ({ setshowDeleteProgressTaskModal,handleDeleteProgressTask }) => {
  return (
    <div>
      <div
        className={`deletemodal modal ${
          setshowDeleteProgressTaskModal ? "show" : ""
        }`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-center">Please Confirm</h4>
              <button
                type="button"
                className="close"
                onClick={() => setshowDeleteProgressTaskModal(false)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-5 pb-5 confirmation--modal">
              <h5 className="mb-0">
              Are you sure you want to remove this task from the progress tasks?
              </h5>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn"
                onClick={() => setshowDeleteProgressTaskModal(false)}
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                className="btn btn-dark darkbtn-hover"
                onClick={handleDeleteProgressTask}
              >
                <span>Done</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${
          setshowDeleteProgressTaskModal ? "show" : ""
        }`}
      ></div>
    </div>
  );
};

export default DeleteProgressTaskModal;
