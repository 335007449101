import React from 'react';

const JobFrequently = ({ item }) => {
  const cancelClass = item?.cancel ? 'cancelled--interested-job' : '';
  const stripePaymentClass = !item?.stripePaymentStatus ? 'confirmation-task' : '';
  const isMarkedCompleted = item?.taskCompletionId?.hustlerMarkedCompleted ? 'mark-status' : '';
  const reasonForNotCompleted = item?.taskCompletionId?.reasonForNotCompleted ? 'cancelled--interested-job' : '';

  const className = `${cancelClass} ${stripePaymentClass} ${isMarkedCompleted} ${reasonForNotCompleted}`.trim() || 'start--work';



  return (
    <div className={`task--items-down--footer ${className}`}>
      <p>Please note that this is a <b>{item?.subscriptionInterval}ly</b> job, and payment will be made on a <b>{item?.subscriptionInterval}ly</b> basis upon completion period. </p>
    </div>
  );
};

export default JobFrequently;
