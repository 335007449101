import React from "react";
import { FaRegFaceDizzy } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

const ContactAdmin = ({ closeModal }) => {
  // firsthustler@gmail.com
  return (
    <div className="warning--modal--container accountlogin--modal--container">
      <div className="warning--modal--box">
        <IoClose className="close--modal" onClick={closeModal} />

        <FaRegFaceDizzy className="icon--warning" />

        <h2>Account has been frozen </h2>
        <p>
          Your Account is frozen Please Contact admin{" "}
          <br/>  <br/>  
          <a style={{color:"black",textDecoration:"none",cursor:"pointer" }} href="mailto:admin@sideHustle.com" >admin@sideHustle.com</a>
        </p>
      </div>
    </div>
  );
};

export default ContactAdmin;
