import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline, IoIosSearch } from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { AiOutlineCalendar, AiOutlineHeart } from "react-icons/ai";
import { TbMoneybag } from "react-icons/tb";
import { GrLocation } from "react-icons/gr";
import FilterRightSide from "./FilterRightSide";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import { setReportFormatDate } from "../../assets/Helper";
import { MdReviews } from "react-icons/md";
import HustlerAddReview from "../../common/HustlerAddReview";
import { FaRegCircleCheck } from "react-icons/fa6";
import AcceptIncomingJobModel from "./AcceptIncomingJobModel";
import CancelIncomingJobModel from "./CancelIncomingJobModel";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { PiEye } from "react-icons/pi";
import HustlerCompletedJobModal from "../../common/Modal/HustlerCompletedJobModal";
import { BiSolidCaretLeftSquare, BiTimeFive } from "react-icons/bi";
import { BudgetFormat, handleDecimalNumber } from "../../utils/helper";
import { useSocket } from "../../contexts/SocketContext";
import { useLocation } from "react-router-dom";
import ShowCancelDisableModal from "../../common/Modal/ShowCancelDisableModal";
import { GoArrowRight } from "react-icons/go";
import { LuPartyPopper } from "react-icons/lu";
import CounterOfferJobModel from "../../common/Modal/CounterOfferJobModel";
import DirectorcancelJobReason from "./DirectorcancelJobReason";
import HustlerDeclinedJobCompletedModal from "./HustlerDeclinedJobCompletedModal";
import JobFrequently from "../../common/JobFrequently";
import Pagination from "../../common/Pagination";

const HustlerJobs = (props) => {
  const location = useLocation();
  const id = location?.state?.id;
  const { setLoading } = props;
  const socket = useSocket();
  const [activeTab, setActiveTab] = useState("upcoming");
  const [showCancelDisableModal, setshowCancelDisableModal] = useState(false);
  const [TaskDetails, setTaskDetails] = useState([]);
  const [ProgressTask, setProgressTask] = useState([]);
  const [taskId, setTaskId] = useState("");
  const [CompletedTask, setCompletedTask] = useState([]);
  const [CancelledTask, setCancelledTask] = useState([]);
  const [ClosedTask, setClosedTask] = useState([]);
  const [showReviewModel, setshowReviewModel] = useState(false);
  const [taskReviewDetails, settaskReviewDetails] = useState({});
  const [allCounterOfferdetails, setallCounterOfferdetails] = useState([]);
  const [showCounterOfferList, setShowCounterOfferList] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);
  const [totalPosts, settotalPosts] = useState(0);
  const [directorCancelreason, setdirectorCancelreason] = useState({
    model: false,
    data: "",
  });
  const [SensitiveWords, setSensitiveWords] = useState([]);
  const [showHustlerCompletedJobModel, setshowHustlerCompletedJobModel] =
    useState(false);
  const [incomingJobtaskId, setincomingJobtaskId] = useState("");
  const [showIncomingModel, setshowIncomingModel] = useState({
    acceptJob: false,
    cancelJob: false,
    counterJob: false,
  });

  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const [baseProfileBase, setBaseProfileBase] = useState("");
  const [userDetails, setuserDetails] = useState([]);
  const [allId, setallId] = useState({
    directorId: "",
    taskId: "",
    subscriptionId: "",
    intervalId: "",
  });

  const [showDeclinedJobTaskModal, setshowDeclinedJobTaskModal] = useState({
    model: false,
    data: "",
  });

  const handleTabClick = (id) => {
    if (id === activeTab) {
      return;
    }
    setActiveTab(id);
    setcurrentPage(1);
  };

  const handleShowCancelDisable = (item) => {
    setTaskDetails(item);
    setshowCancelDisableModal(true);
  };

  const fetchClosedTask = async (number) => {
    if(number){
      setcurrentPage(1)
    }
    setLoading(true)
    await authAxios()
      .get(
        `/task/get-closed-task?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        setLoading(false)
        const resData = response?.data;
        if (resData.status == 1) {
          setClosedTask(resData?.data);
          settotalPosts(resData?.count?.total);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchProgressTask = async (number) => {
    if(number){
      setcurrentPage(1)
    }
    setLoading(true);
    await authAxios()
      .get(
        `/bookhustler/get-in-progress-task?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData.status == 1) {
          setProgressTask(resData?.data);
          settotalPosts(resData?.count?.total);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchCompletedtask = async (number) => {
    if(number){
      setcurrentPage(1)
    }
    setLoading(true);
    await authAxios()
      .get(
        `/bookhustler/get-completed-task?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          setCompletedTask(resData?.data);
          settotalPosts(resData?.count?.total);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchCancelledtask = async (number) => {
    if(number){
      setcurrentPage(1)
    }
    setLoading(true);
    await authAxios()
      .get(
        `/taskproposal/get-cancelled-proposal?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          setCancelledTask(resData?.data);
          settotalPosts(resData?.count?.total);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleAddOpenModal = (item) => {
    setallId((prev) => ({
      ...prev,
      directorId: item?.task?.user,
      taskId: item?.task?._id,
    }));
    setshowReviewModel(true);
  };

  const handleSubmitReview = async (item) => {
    const payload = {
      reviewReceiver: allId.directorId,
      rating: item.rating,
      behavior: item.behavior,
      availability: item.availability,
      payment: item.payment,
      communication: item.communication,
      comment: item.comment,
      taskId: allId.taskId,
    };

    setLoading(true);
    await authAxios()
      .post("/review/create-review", payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData.status == 1) {
          socket.emit("hustlerReviewed", resData?.data.review);
          toast.success(resData?.message);
          fetchCompletedtask(1);
          setshowReviewModel(false);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleEditReview = async (item) => {
    const payload = {
      rating: item.rating,
      behavior: item.behavior,
      availability: item.availability,
      payment: item.payment,
      communication: item.communication,
      comment: item.comment,
    };
    const taskId = item.taskId;
    setLoading(true);
    await authAxios()
      .put(`/review/update-review/${taskId}`, payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData.status == 1) {
          toast.success(resData?.message);
          fetchCompletedtask(1);
          settaskReviewDetails([]);

          setshowReviewModel(false);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleViewOpenModal = (item) => {
    fetchSpecificTaskReview(item?.task?._id);
    setshowReviewModel(true);
  };

  const fetchSpecificTaskReview = async (id) => {
    setLoading(true);
    await authAxios()
      .get(`/review/specific-task-reviews/${id}`)
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          settaskReviewDetails(resData?.data[0]);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchSensitiveWords = async () => {
    setLoading(true);
    await authAxios()
      .get(`/sensitiveword/get-all-sensitive-words`)
      .then((response) => {
        const resData = response?.data;

        if (resData.status == 1) {
          setLoading(false);
          setSensitiveWords(resData?.data?.words);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleStripeAccountConnect = async () => {
    setLoading(true)
    await authAxios()
      .post(`/stripe/create-express-account`)
      .then((response) => {
        setLoading(false)
        const resData = response?.data;
        if (resData.status == 1) {
          window.open(resData?.data?.url);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.response?.data?.message);
      });
  };

  const handleAcceptJob = async () => {
    const resData = await authAxios()
      .get(`/stripe/check-stripe-account`)
      .then((response) => response?.data)
      .catch((error) => {
        if (error.response?.data.status === 0) {
          handleStripeAccountConnect();
        } else {
          toast.error(error?.response?.data?.message);
        }
      });

    if (resData && resData.status === 1) {
      setLoading(true);
      await authAxios()
        .put(`task/accept-task/${incomingJobtaskId}`)
        .then((response) => {
          setLoading(false);
          const resData = response?.data;
          if (resData.status == 1) {
            socket.emit("taskAccepted", resData?.data);
            toast.success(resData?.message);
            fetchProgressTask(1);
            setshowIncomingModel({
              acceptJob: false,
              cancelJob: false,
              counterJob: false,
            });
            setActiveTab("upcoming");
          } else {
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const handleDeniedJob = async ({ finalprice, reason }) => {
    const taskId = incomingJobtaskId;
    setLoading(true);
    await authAxios()
      .put(`task/cancel-tasks/${taskId}`, {
        // finalPrice: finalprice,
        reason: reason,
      })
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          socket.emit("taskCancelled", resData?.data);
          toast.success(resData?.message);
          fetchProgressTask(1);
          setshowIncomingModel({
            acceptJob: false,
            cancelJob: false,
            counterJob: false,
          });
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleCompleteJob = async (id) => {
    setshowHustlerCompletedJobModel(true);
    setTaskId(id);
    setallId((prev) => ({
      ...prev,
      taskId: id,
    }));

    if (showHustlerCompletedJobModel == true) {
      setLoading(true);
      await authAxios()
        .post(`bookhustler/completed/${allId?.taskId}`)
        .then((response) => {
          setLoading(false);
          const resData = response?.data;
          if (resData.status == 1) {
            socket.emit("taskCompletedbyHustler", resData?.data);
            toast.success(resData?.message);
            fetchProgressTask(1);
            setshowHustlerCompletedJobModel(false);
          } else {
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const handleCompleteRecurringJob = async (item) => {
    if (showHustlerCompletedJobModel == true) {
      setLoading(true);

      try {
        setLoading(false);
        const response = await authAxios().post(
          "/stripe/mark-job-completed-hustler",
          {
            subscriptionId: allId.subscriptionId,
            intervalId: allId.intervalId,
          }
        );
        toast.success(response?.data.message);
        fetchProgressTask(1);
        setshowHustlerCompletedJobModel(false);
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    } else {
      const subscriptionId = item?.subscription?._id;
      const intervalRecords = item?.subscription?.intervalRecords;
      const intervalRecordsid = intervalRecords[intervalRecords.length - 1]._id;
      setallId((prev) => ({
        ...prev,
        subscriptionId: subscriptionId,
        intervalId: intervalRecordsid,
      }));
      setTaskDetails(item);
      setshowHustlerCompletedJobModel(true);
    }
  };

  const handleProfileBaseMode = (buttonName) => {
    setBaseProfileBase(buttonName === baseProfileBase ? null : buttonName);
  };

  const handleRemoveActiveClass = () => {
    setBaseProfileBase(null);
  };

  const fetchhustlerDetails = async () => {
    setLoading(true)
    await authAxios()
      .get("/users/profile")
      .then((response) => {
        setLoading(false)
        const resData = response?.data;
        if (resData.status == 1) {
          setuserDetails(resData?.data);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    fetchhustlerDetails();
    fetchSensitiveWords();
    setActiveTab(id || "upcoming");
  }, [id]);

  useEffect(() => {
    if (activeTab === "upcoming") {
      fetchProgressTask(1);
    }
    if (activeTab === "completed-jobs") {
      fetchCompletedtask(1);
    }
    if (activeTab === "cancelled-jobs") {
      fetchCancelledtask(1);
    }
    if (activeTab === "closed-jobs") {
      fetchClosedTask(1);
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === "upcoming") {
      fetchProgressTask();
    }
    if (activeTab === "completed-jobs") {
      fetchCompletedtask();
    }
    if (activeTab === "cancelled-jobs") {
      fetchCancelledtask();
    }
    if (activeTab === "closed-jobs") {
      fetchClosedTask();
    }
  }, [currentPage]);



  return (
    <>
      <section
        className="director--search--hustler pt-117 hustler--home--page"
        style={{ minHeight: "400px" }}
      >
        <div className="custom--container m-auto pt-lg-5 pb-5 pt-0">
          <div className="container--filter">
            <div className="filter--data--search">
              <div className="task--lists--profile--based">
                <div className="base--profile--your">
                  <div
                    className="arrow--view--profilebase"
                    onClick={() => handleProfileBaseMode("profile--toggle")}
                  >
                    <BiSolidCaretLeftSquare />
                  </div>
                </div>
                <div className="director--list--task">
                  <div className="director--list--task--header">
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            activeTab === "upcoming" ? "active" : ""
                          }`}
                          id="upcoming-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#upcoming"
                          type="button"
                          role="tab"
                          aria-controls="upcoming"
                          aria-selected={
                            activeTab === "upcoming" ? "true" : "false"
                          }
                          onClick={() => handleTabClick("upcoming")}
                        >
                          In Progress
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            activeTab === "completed-jobs" ? "active" : ""
                          }`}
                          id="completed-jobs-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#completed-jobs"
                          type="button"
                          role="tab"
                          aria-controls="completed-jobs"
                          aria-selected={
                            activeTab === "completed-jobs" ? "true" : "false"
                          }
                          onClick={() => handleTabClick("completed-jobs")}
                        >
                          Completed Jobs
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            activeTab === "cancelled-jobs" ? "active" : ""
                          }`}
                          id="cancelled-jobs-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#cancelled-jobs"
                          type="button"
                          role="tab"
                          aria-controls="cancelled-jobs"
                          aria-selected={
                            activeTab === "cancelled-jobs" ? "true" : "false"
                          }
                          onClick={() => handleTabClick("cancelled-jobs")}
                        >
                          Cancelled Jobs
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            activeTab === "closed-jobs" ? "active" : ""
                          }`}
                          id="closed-jobs-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#closed-jobs"
                          type="button"
                          role="tab"
                          aria-controls="closed-jobs"
                          aria-selected={
                            activeTab === "closed-jobs" ? "true" : "false"
                          }
                          onClick={() => handleTabClick("closed-jobs")}
                        >
                          Closed Jobs
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className={`tab-pane fade ${
                        activeTab === "upcoming" ? "show active" : ""
                      }`}
                      id="upcoming"
                      role="tabpanel"
                      aria-labelledby="upcoming-tab"
                    >
                      <div className="list--tasks">
                        {ProgressTask ? (
                          ProgressTask.map((item, index) => (
                            <div
                              key={index}
                              className="created--task--lists process--task--list"
                            >
                              {item?.subscription?.intervalRecords[
                                item?.subscription?.intervalRecords.length - 1
                              ]?.jobCompletion?.director.isCompleted == true &&
                              item?.subscription?.intervalRecords[
                                item?.subscription?.intervalRecords.length - 1
                              ]?.jobCompletion?.hustler.isCompleted == true ? (
                                <div className="waiting--msg">
                                  <div>
                                    <BiTimeFive /> Job period has been
                                    completed, now wait for the next
                                    subscription period
                                  </div>
                                </div>
                              ) : item?.subscription?.intervalRecords[
                                  item?.subscription?.intervalRecords.length - 1
                                ]?.jobCompletion?.director.reason ? (
                                <div class="cancelled--interested-job">
                                  <span>
                                    <IoIosCloseCircleOutline /> Creatr rejected
                                    your task.{" "}
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      onClick={() =>
                                        window.open(
                                          "https://sidehustlsupport.zendesk.com"
                                        )
                                      }
                                    >
                                      {" "}
                                      Contact admin for details.{" "}
                                    </span>
                                  </span>

                                  <div
                                    className="view--reason"
                                    onClick={() =>
                                      setdirectorCancelreason({
                                        model: true,
                                        reason:
                                          item?.subscription?.intervalRecords[
                                            item?.subscription?.intervalRecords
                                              .length - 1
                                          ]?.jobCompletion?.director.reason,
                                      })
                                    }
                                  >
                                    {" "}
                                    View Reason <GoArrowRight />{" "}
                                  </div>
                                </div>
                              ) : item?.taskCompletionId
                                  ?.hustlerMarkedCompleted == true ||
                                item?.subscription?.intervalRecords[
                                  item?.subscription?.intervalRecords.length - 1
                                ]?.jobCompletion?.hustler.isCompleted ==
                                  true ? (
                                <div class="mark-status">
                                  <span>
                                    <BiTimeFive /> Waiting For Creatr's Approval
                                  </span>

                                  <span className="view--reason">
                                    <div className="task--link">
                                      <Link
                                        to={`/job-details/${item._id}`}
                                        state={{ id: "jobDetails--view" }}
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                      >
                                        Job Details <GoArrowRight />
                                      </Link>
                                    </div>
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {item?.cancel == true ? (
                                    <div class="cancelled--interested-job">
                                      <span>
                                        <IoIosCloseCircleOutline /> You've
                                        rejected the offer
                                      </span>
                                    </div>
                                  ) : (
                                    <>
                                      {!item?.stripePaymentStatus ? (
                                        <div class="confirmation-task">
                                          <span>
                                            <BiTimeFive /> Waiting for
                                            confirmation.
                                          </span>
                                        </div>
                                      ) : (
                                        <>
                                          {item.taskCompletionId
                                            ?.reasonForNotCompleted ? (
                                            <div class="cancelled--interested-job">
                                              <span>
                                                <IoIosCloseCircleOutline />{" "}
                                                Creatr rejected your task.{" "}
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                  }}
                                                  onClick={() =>
                                                    window.open(
                                                      "https://sidehustlsupport.zendesk.com"
                                                    )
                                                  }
                                                >
                                                  {" "}
                                                  Contact admin for details.{" "}
                                                </span>
                                              </span>

                                              <div
                                                className="view--reason"
                                                onClick={() =>
                                                  setdirectorCancelreason({
                                                    model: true,
                                                    reason:
                                                      item.taskCompletionId
                                                        ?.reasonForNotCompleted,
                                                  })
                                                }
                                              >
                                                {" "}
                                                View Reason <GoArrowRight />{" "}
                                              </div>
                                            </div>
                                          ) : (
                                            <div class="start--work">
                                              <span>
                                                <BiTimeFive /> The task is
                                                active. You can begin working
                                                now.
                                              </span>
                                              <span className="view--reason">
                                                <div className="task--link">
                                                  <Link
                                                    to={`/job-details/${item._id}`}
                                                    state={{
                                                      id: "jobDetails--view",
                                                    }}
                                                  >
                                                    Job Details <GoArrowRight />
                                                  </Link>
                                                </div>
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              <div className="task--work">
                                <div
                                  className="task--items--header"
                                  style={{ marginBottom: "0px" }}
                                >
                                  <div className="task--add--info">
                                    <div className="user--task--info">
                                      <div className="task--info--img">
                                        <Link
                                          to={`/user-info/${item?.user?._id}`}
                                        >
                                          <img
                                            src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.user?.profilePic}`}
                                          />
                                        </Link>
                                      </div>
                                      <div className="task--info--details">
                                        <span>
                                          {item?.user?.fname}{" "}
                                          {item?.user?.lname}
                                        </span>
                                        <p>
                                          <img src="../assets/images/hustler-star.png" />{" "}
                                          {handleDecimalNumber(
                                            item?.user?.directorDetails?.ratings
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="task--items--edit--delete">
                                    {item?.taskCompletionId
                                      ?.hustlerMarkedCompleted ||
                                    item.taskCompletionId
                                      ?.reasonForNotCompleted ||
                                    item?.cancel ||
                                    !item?.stripePaymentStatus ? null : (
                                      <div className="button--lists-view">
                                        {item.oneTimePayment == false ? (
                                          <>
                                            {item?.subscription
                                              ?.intervalRecords[
                                              item?.subscription
                                                ?.intervalRecords.length - 1
                                            ]?.jobCompletion?.director
                                              .reason ? (
                                              <> </>
                                            ) : item?.subscription
                                                ?.intervalRecords[
                                                item?.subscription
                                                  ?.intervalRecords.length - 1
                                              ]?.jobCompletion?.director
                                                .isCompleted == true &&
                                              item?.subscription
                                                ?.intervalRecords[
                                                item?.subscription
                                                  ?.intervalRecords.length - 1
                                              ]?.jobCompletion?.hustler
                                                .isCompleted == true ? (
                                              <> </>
                                            ) : item?.subscription
                                                ?.intervalRecords[
                                                item?.subscription
                                                  ?.intervalRecords.length - 1
                                              ]?.jobCompletion?.hustler
                                                .isCompleted == true ? (
                                              <> </>
                                            ) : (
                                              <>
                                                {item?.latestCounterOffer
                                                  ?.finalDate &&
                                                (moment().format("LL") ===
                                                  moment(
                                                    item?.latestCounterOffer
                                                      ?.finalDate
                                                  ).format("LL") ||
                                                  moment().format("LL") ===
                                                    moment(
                                                      item?.nextDueDate
                                                    ).format("LL")) ? (
                                                  <button
                                                    onClick={() =>
                                                      handleCompleteRecurringJob(
                                                        item
                                                      )
                                                    }
                                                    className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                                  >
                                                    <span>
                                                      <FaRegCircleCheck />
                                                      Complete Job
                                                    </span>
                                                  </button>
                                                ) : item?.taskProposal
                                                    ?.proposalDate &&
                                                  (moment().format("LL") ===
                                                    moment(
                                                      item?.taskProposal
                                                        ?.proposalDate
                                                    ).format("LL") ||
                                                    moment().format("LL") ===
                                                      moment(
                                                        item?.nextDueDate
                                                      ).format("LL")) ? (
                                                  <button
                                                    onClick={() =>
                                                      handleCompleteRecurringJob(
                                                        item
                                                      )
                                                    }
                                                    className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                                  >
                                                    <span>
                                                      <FaRegCircleCheck />
                                                      Complete Job
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {item?.latestCounterOffer
                                              ?.finalDate &&
                                            (moment().format("LL") ===
                                              moment(
                                                item?.latestCounterOffer
                                                  ?.finalDate
                                              ).format("LL") ||
                                              moment().format("LL") ===
                                                moment(
                                                  item?.nextDueDate
                                                ).format("LL")) ? (
                                              <button
                                                onClick={() =>
                                                  handleCompleteJob(item?._id)
                                                }
                                                className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                              >
                                                <span>
                                                  <FaRegCircleCheck />
                                                  Complete Job
                                                </span>
                                              </button>
                                            ) : 
                                            item?.taskProposal ?.proposalDate && 
                                            // (moment().format("LL") ===moment(item?.taskProposal?.proposalDate).format("LL") 
                                            //     || moment().format("LL") === moment( item?.nextDueDate ).format("LL"))

                                                (moment().isSameOrAfter(moment(item?.taskProposal?.proposalDate), 'day') ||
 moment().isSameOrAfter(moment(item?.nextDueDate), 'day')) 

                                                
                                                ? (
                                              <button
                                                onClick={() =>
                                                  handleCompleteJob(item?._id)
                                                }
                                                className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                              >
                                                <span>
                                                  <FaRegCircleCheck />
                                                  Complete Job
                                                </span>
                                              </button>
                                            ) 
                                            : (
                                              <></>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {item.oneTimePayment == false &&
                                  item?.nextDueDate && (
                                    <div>
                                      {item?.subscription?.intervalRecords[
                                        item?.subscription?.intervalRecords
                                          .length - 1
                                      ]?.jobCompletion?.director.reason ||
                                      (item?.subscription?.intervalRecords[
                                        item?.subscription?.intervalRecords
                                          .length - 1
                                      ]?.jobCompletion?.director.isCompleted ==
                                        true &&
                                        item?.subscription?.intervalRecords[
                                          item?.subscription?.intervalRecords
                                            .length - 1
                                        ]?.jobCompletion?.hustler.isCompleted ==
                                          true) ||
                                      item?.subscription?.intervalRecords[
                                        item?.subscription?.intervalRecords
                                          .length - 1
                                      ]?.jobCompletion?.hustler.isCompleted ==
                                        true ? (
                                        <> </>
                                      ) : (
                                        <>
                                          {" "}
                                          <div className="next-due-date">
                                            <div className="task--items--content--icon">
                                              <AiOutlineCalendar />
                                            </div>
                                            {item?.latestCounterOffer
                                              ?.finalDate ? (
                                              <span>
                                                Next Job Date:{" "}
                                                {moment().isBefore(
                                                  moment(
                                                    item?.latestCounterOffer
                                                      .finalDate
                                                  )
                                                )
                                                  ? moment(
                                                      item?.latestCounterOffer
                                                        .finalDate
                                                    ).format("LL")
                                                  : moment(
                                                      item?.nextDueDate
                                                    ).format("LL")}
                                              </span>
                                            ) : (
                                              <span>
                                                Next Job Date:{" "}
                                                {moment().isBefore(
                                                  moment(
                                                    item?.taskProposal
                                                      ?.proposalDate
                                                  )
                                                )
                                                  ? moment(
                                                      item?.taskProposal
                                                        ?.proposalDate
                                                    ).format("LL")
                                                  : moment(
                                                      item?.nextDueDate
                                                    ).format("LL")}
                                              </span>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  )}

                                <div className="task--items--details">
                                  <div
                                    className="task--detail-full"
                                    style={{ width: "100%" }}
                                  >
                                    <Link
                                      to={`/job-details/${item._id}`}
                                      style={{
                                        textDecoration: "none",
                                        color: "#000",
                                      }}
                                    >
                                      <h2 className="hover-text">
                                        {item?.title}
                                      </h2>
                                    </Link>
                                    <div className="service--category--task">
                                      <h3>{item?.serviceName}</h3>
                                    </div>
                                  </div>
                                </div>

                                <div className="task--items--content">
                                  <p>
                                    <span className="task--items--content--icon">
                                      <AiOutlineCalendar />
                                    </span>
                                    {setReportFormatDate(item?.dueDate)}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <TbMoneybag />
                                    </span>{" "}
                                    Budget: ${BudgetFormat(`${item?.budget}`)}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <GrLocation />
                                    </span>{" "}
                                    Area: {item?.cityName}
                                  </p>
                                </div>
                                <div className="task--items-footer">
                                  <p>
                                    {item?.description?.length > 350
                                      ? `${item?.description?.substring(
                                          0,
                                          350
                                        )}...`
                                      : `${item?.description}`}
                                  </p>
                                </div>
                              </div>
                              {item?.oneTimePayment ? null : (
                                <JobFrequently item={item} />
                              )}
                            </div>
                          ))
                        ) : (
                          <div className="no--data--dedected">
                            No in progress jobs to display
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className={`tab-pane fade ${
                        activeTab === "completed-jobs" ? "show active" : ""
                      }`}
                      id="completed-jobs"
                      role="tabpanel"
                      aria-labelledby="completed-jobs-tab"
                    >
                      <div className="list--tasks">
                        {CompletedTask ? (
                          CompletedTask.map((item, index) => (
                            <div
                              key={index}
                              className="created--task--lists process--task--list"
                            >
                              <div class="mark-status">
                                <span>
                                  <LuPartyPopper /> Completed Job
                                </span>
                              </div>

                              <div className="task--work">
                                <div
                                  className="task--items--header"
                                  style={{ marginBottom: "0px" }}
                                >
                                  <div className="task--add--info">
                                    <div className="user--task--info">
                                      <div className="task--info--img">
                                        <Link
                                          to={`/user-info/${item?.task?.user?._id}`}
                                        >
                                          <img
                                            src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.task?.user?.profilePic}`}
                                          />
                                        </Link>
                                      </div>
                                      <div className="task--info--details">
                                        <span>
                                          {item?.task?.user?.fname}{" "}
                                          {item?.task?.hustlerId?.lname}
                                        </span>
                                        <p>
                                          <img src="../assets/images/hustler-star.png" />{" "}
                                          {handleDecimalNumber(
                                            item?.task?.user?.directorDetails
                                              ?.ratings
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="task--items--edit--delete"
                                    style={{ maxWidth: "fit-content" }}
                                  >
                                    {item?.task?.hustlerReviewed ? (
                                      <button
                                        onClick={() =>
                                          handleViewOpenModal(item)
                                        }
                                        className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                        style={{ maxWidth: "fit-content" }}
                                      >
                                        <span>
                                          <PiEye /> View Review
                                        </span>
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => handleAddOpenModal(item)}
                                        className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                      >
                                        <span>
                                          <MdReviews /> Add Review
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                </div>

                                <div className="task--items--details">
                                  <div
                                    className="task--detail-full"
                                    style={{ width: "100%" }}
                                  >
                                    <Link
                                      to={`/job-details/${item?.task?._id}`}
                                      style={{
                                        textDecoration: "none",
                                        color: "#000",
                                      }}
                                      
                                    >
                                      <h2 className="hover-text">
                                        {item?.task?.title}
                                      </h2>
                                    </Link>
                                    <div className="service--category--task">
                                      <h3>{item?.task?.serviceName}</h3>
                                    </div>
                                  </div>
                                </div>

                                <div className="task--items--content">
                                  <p>
                                    <span className="task--items--content--icon">
                                      <AiOutlineCalendar />
                                    </span>
                                    {setReportFormatDate(item?.task?.dueDate)}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <TbMoneybag />
                                    </span>{" "}
                                    Budget: $
                                    {BudgetFormat(`${item?.task?.budget}`)}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <GrLocation />
                                    </span>{" "}
                                    Area: {item?.task?.cityName}
                                  </p>
                                </div>
                                <div className="task--items-footer">
                                  <p>
                                    {item?.task?.description?.length > 350
                                      ? `${item?.task?.description?.substring(
                                          0,
                                          350
                                        )}...`
                                      : `${item?.task?.description}`}
                                  </p>
                                </div>
                              </div>
                              {item?.task?.oneTimePayment ? null : (
                                <div
                                  className={`task--items-down--footer mark-status`}
                                >
                                  <p>
                                    Please note that this is a{" "}
                                    <b>{item?.task?.subscriptionInterval}ly</b>{" "}
                                    job, and payment will be made on a{" "}
                                    <b>{item?.task?.subscriptionInterval}ly</b>{" "}
                                    basis upon completion.{" "}
                                  </p>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div className="no--data--dedected">
                            No completed jobs to display
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className={`tab-pane fade ${
                        activeTab === "cancelled-jobs" ? "show active" : ""
                      }`}
                      id="cancelled-jobs"
                      role="tabpanel"
                      aria-labelledby="cancelled-jobs-tab"
                    >
                      <div className="list--tasks">
                        {CancelledTask
                          ? CancelledTask.map((item, index) => (
                              <div
                                key={index}
                                className="created--task--lists process--task--list"
                              >
                                <div className="cancelled--interested-job">
                                  <div>
                                    <IoIosCloseCircleOutline />{" "}
                                    {item?.cancellation?.cancelledBy ===
                                    "DIRECTOR"
                                      ? "Creatr"
                                      : "You"}{" "}
                                    had cancelled this job
                                  </div>
                                  <div
                                    className="view--reason"
                                    onClick={() =>
                                      handleShowCancelDisable(item)
                                    }
                                  >
                                    View Reasons <GoArrowRight />
                                  </div>
                                </div>

                                <div className="task--work">
                                  <div
                                    className="task--items--header"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <div className="task--add--info">
                                      <div className="user--task--info">
                                        <div className="task--info--img">
                                          <Link
                                            to={`/user-info/${item?.task?.user?._id}`}
                                          >
                                            <img
                                              src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.task?.user?.profilePic}`}
                                            />
                                          </Link>
                                        </div>
                                        <div className="task--info--details">
                                          <span>
                                            {item?.task?.user?.fname}{" "}
                                            {item?.task?.hustlerId?.lname}
                                          </span>
                                          <p>
                                            <img src="../assets/images/hustler-star.png" />{" "}
                                            {handleDecimalNumber(
                                              item?.task?.user?.directorDetails
                                                ?.ratings
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="task--items--edit--delete">
                                      {item?.task?.hustlerReviewed ? (
                                        <button
                                          onClick={() =>
                                            handleViewOpenModal(item)
                                          }
                                          className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                        >
                                          <span>
                                            <PiEye /> View Review
                                          </span>
                                        </button>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="task--items--details">
                                    <div
                                      className="task--detail-full"
                                      style={{ width: "100%" }}
                                    >
                                      <h2>{item?.task?.title}</h2>
                                      <div className="service--category--task">
                                        <h3>{item?.task?.serviceName}</h3>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="task--items--content">
                                    <p>
                                      <span className="task--items--content--icon">
                                        <AiOutlineCalendar />
                                      </span>
                                      {setReportFormatDate(item?.task?.dueDate)}
                                    </p>
                                    <p>
                                      <span className="task--items--content--icon">
                                        <TbMoneybag />
                                      </span>{" "}
                                      Budget: $
                                      {BudgetFormat(`${item?.task?.budget}`)}
                                    </p>
                                    <p>
                                      <span className="task--items--content--icon">
                                        <GrLocation />
                                      </span>{" "}
                                      Area: {item?.task?.cityName}
                                    </p>
                                  </div>
                                  <div className="task--items-footer">
                                    <p>
                                      {item?.task?.description?.length > 350
                                        ? `${item?.task?.description?.substring(
                                            0,
                                            350
                                          )}...`
                                        : `${item?.task?.description}`}
                                    </p>
                                  </div>
                                </div>

                                {item?.task?.oneTimePayment ? null : (
                                  <div
                                    className={`task--items-down--footer cancelled--interested-job`}
                                  >
                                    <p>
                                      Please note that this is a{" "}
                                      <b>
                                        {item?.task?.subscriptionInterval}ly
                                      </b>{" "}
                                      job, and payment will be made on a{" "}
                                      <b>
                                        {item?.task?.subscriptionInterval}ly
                                      </b>{" "}
                                      basis upon completion.{" "}
                                    </p>
                                  </div>
                                )}
                              </div>
                            ))
                          : null}
                        {CancelledTask && CancelledTask.length > 0 ? null : (
                          <div className="no--data--dedected">
                            No cancelled jobs to display
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === "closed-jobs" ? "show active" : ""
                      }`}
                      id="closed-jobs"
                      role="tabpanel"
                      aria-labelledby="closed-jobs"
                    >
                      <div className="list--tasks">
                        {ClosedTask ? (
                          ClosedTask.map((item, index) => (
                            <div
                              key={index}
                              className="created--task--lists process--task--list"
                            >
                              {(item?.status === "blocked" ||
                                item?.status === "closed") && (
                                <div className="cancelled--interested-job">
                                  <div>
                                    <IoIosCloseCircleOutline />{" "}
                                    {item?.status === "blocked"
                                      ? "Blocked"
                                      : "Closed"}{" "}
                                    Job
                                  </div>
                                  {item?.status === "closed" &&
                                    item?.taskCompletionId !== null && (
                                      <div
                                        className="view--reason"
                                        onClick={() =>
                                          setshowDeclinedJobTaskModal({
                                            model: true,
                                            reason:
                                              item.taskCompletionId
                                                ?.reasonForNotCompleted,
                                          })
                                        }
                                      >
                                        View Reasons <GoArrowRight />
                                      </div>
                                    )}
                                  {item?.status === "blocked" && (
                                    <div style={{ color: "#FF0000" }}>
                                      This job is blocked for breaching policy.
                                    </div>
                                  )}
                                </div>
                              )}
                              <div className="task--work">
                                <div className="task--items--details">
                                  <div
                                    className="task--detail-full"
                                    style={{ width: "100%" }}
                                  >
                                    <h2>{item?.title}</h2>
                                    <div className="service--category--task">
                                      <h3>{item?.serviceName}</h3>
                                    </div>
                                  </div>
                                </div>

                                <div className="task--items--content">
                                  <p>
                                    <span className="task--items--content--icon">
                                      <AiOutlineCalendar />
                                    </span>
                                    {setReportFormatDate(item?.dueDate)}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <TbMoneybag />
                                    </span>{" "}
                                    Budget: ${BudgetFormat(`${item?.budget}`)}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <GrLocation />
                                    </span>{" "}
                                    Area: {item?.cityName}
                                  </p>
                                </div>
                                <div className="task--items-footer">
                                  <p>
                                    {item?.description?.length > 350
                                      ? `${item?.description?.substring(
                                          0,
                                          350
                                        )}...`
                                      : `${item?.description}`}
                                  </p>
                                </div>
                              </div>

                              {item?.oneTimePayment ? null : (
                                <div
                                  className={`task--items-down--footer cancelled--interested-job`}
                                >
                                  <p>
                                    Please note that this is a{" "}
                                    <b>{item?.subscriptionInterval}ly</b> job,
                                    and payment will be made on a{" "}
                                    <b>{item?.subscriptionInterval}ly</b> basis
                                    upon completion.{" "}
                                  </p>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div className="no--data--dedected">
                            No closed jobs to display
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPosts={totalPosts}
                    paginate={paginate}
                    postsPerPage={postsPerPage}
                  />
                </div>
              </div>
            </div>
            <div
              className={`filter--data custom--scroll ${
                baseProfileBase === "profile--toggle"
                  ? "active--toggle--baseprofile"
                  : ""
              }`}
            >
              <div className="scroll--sticky">
                <FilterRightSide
                  userDetails={userDetails}
                  onRemoveActiveClass={handleRemoveActiveClass}
                  handleTabClick={handleTabClick}
                />
              </div>
            </div>
            {baseProfileBase === "profile--toggle" && (
              <div className="overlay--filter--data"></div>
            )}
          </div>
        </div>
      </section>

      {showReviewModel && (
        <HustlerAddReview
          SensitiveWords={SensitiveWords}
          setshowReviewModel={setshowReviewModel}
          taskReviewDetails={taskReviewDetails}
          settaskReviewDetails={settaskReviewDetails}
          handleSubmitReview={handleSubmitReview}
          handleEditReview={handleEditReview}
        />
      )}

      {showIncomingModel.acceptJob && (
        <AcceptIncomingJobModel
          handleAcceptJob={handleAcceptJob}
          setshowIncomingModel={setshowIncomingModel}
        />
      )}

      {showHustlerCompletedJobModel && (
        <HustlerCompletedJobModal
          handleCompleteJob={handleCompleteJob}
          setshowHustlerCompletedJobModel={setshowHustlerCompletedJobModel}
          taskId={taskId}
          TaskDetails={TaskDetails}
          setTaskDetails={setTaskDetails}
          handleCompleteRecurringJob={handleCompleteRecurringJob}
          allId={allId}
          setallId={setallId}
        />
      )}

      {showIncomingModel.cancelJob && (
        <CancelIncomingJobModel
          handleDeniedJob={handleDeniedJob}
          setshowIncomingModel={setshowIncomingModel}
        />
      )}

      {directorCancelreason.model && (
        <DirectorcancelJobReason
          directorCancelreason={directorCancelreason}
          setdirectorCancelreason={setdirectorCancelreason}
        />
      )}

      {showCancelDisableModal && (
        <ShowCancelDisableModal
          setshowCancelDisableModal={setshowCancelDisableModal}
          TaskDetails={TaskDetails}
        />
      )}

      {showDeclinedJobTaskModal.model && (
        <HustlerDeclinedJobCompletedModal
          showDeclinedJobTaskModal={showDeclinedJobTaskModal}
          setshowDeclinedJobTaskModal={setshowDeclinedJobTaskModal}
        />
      )}
    </>
  );
};

export default IsLoadingHOC(HustlerJobs);
