import React from 'react'
import { AiOutlineCalendar, AiOutlineHeart } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { IoCloseSharp } from "react-icons/io5";
import { TbMoneybag } from "react-icons/tb";



const ShowCancelDisableModal = ({ setshowCancelDisableModal, TaskDetails }) => {
  return (
    <div className="show--interest--modal--overlay">
      <div className="show--interest--modal custom--scroll--thumb" style={{ height: 'auto',maxHeight:'80vh' }}>
        <div className="showInsterest--header">
          <h2 className="playFairDisplay heading-h3">Cancel Job</h2>
          <div className="close--popup" onClick={() => setshowCancelDisableModal(false)}>
            <IoCloseSharp />
          </div>
        </div>

        <div className="showInsterest--cancel showInsterest--content--area">
          <div className="showInsterest--content--area--left" style={{maxWidth:'100%'}}>
            <div className="task--description--info">
              <p className="director--reviews--heading">
                Reasons
              </p>

              <ul>
                {TaskDetails && TaskDetails?.cancellation?.reasons?.map((item) => (
                  <>
                    <li>
                      <div className="checkbox--custom">
                        <input
                          type="checkbox"
                          checked="true"
                        />

                        <span className="checkmark"></span>
                      </div>
                      <span>
                        {item}
                      </span>
                    </li>
                  </>
                ))}
              </ul>

            </div>

            <div className="showInsterest--form">


              <div className="showInsterest--textarea">
                <h3 className="mb-3">Cancelled Job Descrption</h3>
                <textarea required name="comment" disabled value={TaskDetails?.cancellation?.comment}>

              </textarea>
            </div>


          </div>
        </div>
      </div>

    </div>
    </div >
  )
}

export default ShowCancelDisableModal