import React from 'react'

const CancelSubscription = ({setCancelSubscriptionModal}) => {
  return (
    <div>
    <div
      className={`deletemodal modal ${setCancelSubscriptionModal ? "show" : ""}`}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header flex-column">
            <h4 className="modal-title w-100 text-center">Please Confirm</h4>
            <button
              type="button"
              className="close"

             
             onClick={()=>setCancelSubscriptionModal(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body pt-5 pb-5 confirmation--modal">
            <h5 className="mb-0">
            Are you sure that you have cancel this subscription?
            </h5>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="custom--btn ligghtbtn-hover transparent--btn"
              onClick={()=>setCancelSubscriptionModal(false)}
            >
              <span>Cancel</span>
            </button>
            <button
              type="button"
              className="btn btn-dark darkbtn-hover"
              onClick={()=>setCancelSubscriptionModal(false)}
            >
              <span>Yes</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      className={`modal-backdrop ${setCancelSubscriptionModal ? "show" : ""}`}
    ></div>
  </div>
  )
}

export default CancelSubscription