import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CanadianCity } from "../../utils/constants";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { State, City } from "country-state-city";

import { Link, useNavigate } from "react-router-dom";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { authAxios, withoutAuthAxios } from "../../services/config";
import { toast } from "react-toastify";
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import TermsandConditonModel from "../../common/TermsandConditonModel";
import {
  checkServices,
  // HustlerDescription,
  correctArrayFormat,
  dataWithoutIds,
  removeIds,
} from "../../utils/helper";
import { IoClose, IoCloseCircle, IoEyeOutline } from "react-icons/io5";
import usePasswordValidation from "../../common/usePasswordValidation";
import { FaCheck } from "react-icons/fa6";

const HustlerSignup = (props) => {
  const { setLoading } = props;
  const navigate = useNavigate();

  const { toggleActiveForm } = props;

  const [selectedService, setSelectedService] = useState(null);
  const [selectedCategory, setselectedCategory] = useState(null);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [specificCategory, setspecificCategory] = useState([]);
  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [stateCode, setStateCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showTermsandConditonModel, setshowTermsandConditonModel] =
    useState(false);
  const [addMoreCategory, setAddMoreCategory] = useState(false);

  const [allservices, setAllServices] = useState({
    count: 0,
    services: [],
  });
  const taskCatgeory = useSelector((state) => state.app?.services);
  const taskServices = useSelector((state) => state.app.tasks);
  const task = useSelector((state) => state);
  // https://hustlenode.makemagento.com/api/services/get-all-services

const [data, setData] = useState([])
  const getTaskListData = async () => {
    setLoading(true);
    await withoutAuthAxios()
      .get(`/services/get-all-services`)
      .then((response) => {
        const resData = response?.data.data;
        setData(response?.data.data)
        setLoading(false);
        if (resData?.status == 1) {

          
         // dispatch(saveTaskOptions(resData?.data));

          const services = [];
          resData?.data.map((item) => {
            services.push({
              label:  toCamelCase(item?.serviceName),
              value: toCamelCase(item?.serviceName),
            });
          });

          const category=[]

          resData?.data?.map((item)=>{
            item?.category?.map((cat)=>{
              category?.push(cat)
            })
          })

          // dispatch(CombineTaskOptions(resData))
          // dispatch(saveServices(RemovingDuplicatesServicesandCategories(services)))
          // dispatch(saveTaskOptions(RemovingDuplicatesServicesandCategories(category)))

          

        //  dispatch(saveServices(services));
          
        } else {
          toast.error(resData?.message?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getTaskListData();
  }, []);











  const [showWindow, setshowWindow] = useState({
    basicDetails: true,
    verifyOtp: false,
    insertPassword: false,
  });

  const [categoriesModal, setCategoriesModal] = useState(false);

  const [formData, setformData] = useState({
    step: 1,
    email: "",
    fname: "",
    lname: "",
    phoneno: "",
    password: "",
    confirmpassword: "",
    category: [],
    serviceName: "",
    termsandConditon: "false",
    //price: 20,
    city: "",
    otp: "",
    userType: "HUSTLER",
  });

  const { step } = formData;

  const handleTogglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(
        (prevShowConfirmPassword) => !prevShowConfirmPassword
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAlphabet=(e)=>{
    const { name, value } = e.target;
    const regex = /^[A-Za-z ]*$/;
  if(regex.test(value)){
    setformData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  }



  const handleNumber = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    if (sanitizedValue.match(/^[0-9]*$/)) {
      setformData((prev) => ({
        ...prev,
        [name]: sanitizedValue,
      }));
    }
  };
  
  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData("Text");
    
    // Remove + and anything after it if it's followed by a number
    let sanitizedValue = pastedText.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    
    // Check if the pasted number starts with the country code (e.g. +1)
    if (sanitizedValue.startsWith("1") && sanitizedValue.length > 10) {
      sanitizedValue = sanitizedValue.substring(1); // Remove the leading 1 (country code)
    }
  
    e.preventDefault(); // Prevent the default paste action
    
    // Update formData with the sanitized value
    setformData((prev) => ({
      ...prev,
      phoneno: sanitizedValue,
    }));
  };
  const handleTermsCheckbox = () => {
    if (formData.termsandConditon === true) {
      setformData((prev) => ({
        ...prev,
        termsandConditon: false,
      }));
      setshowTermsandConditonModel(true);
    } else {
      setformData((prev) => ({
        ...prev,
        termsandConditon: false,
      }));
      setshowTermsandConditonModel(true);
    }
  };

  const toCamelCase = (str) => {
    if(str){ 
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if(formData.phoneno.length<10){
      toast.warn('Phone number must be 10 digits')
    }else{
      if(!formData.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)){
        toast.warn('Enter Correct Email Address')
        return false
      }
      if (formData.step === 2) {
        const finalCatgory = [];
        formData.category.map((item) => {
          finalCatgory.push({ label: toCamelCase(item?.label), value: toCamelCase(item?.value) });
        });
        const firstArray = {
          serviceName:  toCamelCase(formData.serviceName.label),
          category: finalCatgory,
        };
  
        const modifyArray = removeIds(allservices.services);
        const modifyArray2 = correctArrayFormat(modifyArray);
  
        const mergedArray = [...modifyArray2, firstArray];



        if (checkServices(mergedArray) == "not completed") {
          toast.info("Please fill in all categories and services.");
          return false;
        }
  
        const payload = {
          email: formData.email,
          fname: formData.fname,
          lname: formData.lname,
          phoneno: formData.phoneno,
          province: selectedProvince?.label,
          location: formData.city?.value,
          userType: "HUSTLER",
  
          hustlerDetails: { services: mergedArray },
          // description:HustlerDescription
        };
        setLoading(true);
        await withoutAuthAxios()
          .post("/users/register", payload)
          .then((response) => {
            const resData = response?.data;
  
            setLoading(false);
            if (resData.status == 1) {
              toast.success(resData?.message);
              setshowWindow({
                basicDetails: false,
                verifyOtp: true,
                insertPassword: false,
              });
              // navigate("/login");
            } else {
              toast.error(resData?.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.message);
          });
      } else {
        setformData((prevState) => ({
          ...prevState,
          step: prevState.step + 1,
        }));
      }
    }
  };

  const handlePrevious = () => {
    if (formData.step > 1) {
      setformData((prevState) => ({
        ...prevState,
        step: prevState.step - 1,
      }));
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      email: formData.email,
      enteredOTP: Number(formData.otp),
    };
    await authAxios()
      .post(`/users/verify-otp`, payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData.status == 1) {
          toast.success(resData?.message);
          setshowWindow({
            basicDetails: false,
            verifyOtp: false,
            insertPassword: true,
          });
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  const handleReSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      email: formData.email,
    };
    await authAxios()
      .post(`users/resend-otp`, payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData.status == 1) {
          toast.success(resData?.message);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  const handleSetPassword = async (e) => {
    e.preventDefault();
    
    if (formData.password.length < 8 ||
      !/(?=.*[A-Z])/.test(formData.password) ||
      !/(?=.*[!@#$%^&*])/.test(formData.password) ||
      !/(?=.*[0-9])/.test(formData.password)) {
      toast.error("Password criteria do not match.");
      return;
    }
    if (formData.confirmpassword == formData.password) {
      const payload = {
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmpassword,
      };
      await authAxios()
        .post(`users/setup-password`, payload)
        .then((response) => {
          const resData = response?.data;
          setLoading(false);
          if (resData.status == 1) {
            toast.success(resData?.message);
            navigate(`/login`);
          } else {
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    } else {
      toast.error("The passwords entered do not match.");
    }
  };

  // const handleSelectService = (e) => {
  //   setformData((prev) => ({
  //     ...prev,
  //     serviceName: e,
  //     category:[]
  //   }));
  //   //setSelectedService(e);
  //  // setselectedCategory([]);
  // };

  const handleSingleService=(value,e)=>{
    if (e.action == "menu-close") {
      if (e.prevInputValue.length > 0) {
        const newOption = { label: e.prevInputValue, value: e.prevInputValue };
        setformData((prev) => ({
          ...prev,
          serviceName: newOption,
          category:[]
        }));   
      }
    }
  }

  const handleSelectCategory = (e) => {
    setformData((prev) => ({
      ...prev,
      category: e,
    }));
   // setselectedCategory(e);
  };
  const handleSingleCategory = (value, e) => {
    if (e.action === "menu-close") {
      if (e.prevInputValue.length > 0) {
        const newOption = { label: e.prevInputValue, value: e.prevInputValue };
        const exists = formData.category.some(option => option.value.toLowerCase() === newOption.value.toLowerCase());
  
        if (!exists) {
          setformData((prev) => ({
            ...prev,
            category: [...formData.category, newOption],
          }));
        }
      }
    }
  };

  const handleSelectCity = (e) => {
    setformData((prev) => ({
      ...prev,
      city: e,
    }));
    setSelectedCity(e);
  };

  const handleSelectProvince = (e) => {
    if (e) {
      setStateCode(e?.value);
      setselectedProvince(e);
      setSelectedCity([]);
    } else {
      setcityList([]);
      setSelectedCity([]);
      setStateCode("");
      setselectedProvince([]);
    }
  };

  useEffect(() => {
    // setprovinceList(
    //   State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
    //     value: isoCode,
    //     label: name,
    //   }))
    // );
    setprovinceList(
      [ { value: "BC", label: "British Columbia" },
     ]
     );
  }, []);

  useEffect(() => {
    setcityList(
      [ {"value": "Kamloops", "label": "Kamloops"},
        {"value": "Kelowna","label": "Kelowna"},
        {"value": "Peachland","label": "Peachland"},
        {"value": "Penticton","label": "Penticton"},
        {"value": "Vernon","label": "Vernon"},
        {"value": "West Kelowna","label": "West Kelowna"},
      ]
    )


    if (stateCode) {
      // setcityList(
      //   City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
      //     value: name,
      //     label: name,
      //   }))
      // );
    }
  }, [stateCode]);

  const handleAddService = () => {
    setAllServices((prevState) => ({
      count: prevState.count + 1,
      services: [
        ...prevState.services,
        { id: prevState.count, serviceName: "", category: [] },
      ],
    }));

    setCategoriesModal(true)
  };

  const handleRemoveService = (indexToRemove) => {
    setAllServices((prevState) => {
      const updatedServices = prevState.services.filter((_, index) => index !== indexToRemove);
      return {
        count: Math.min(prevState.count, updatedServices.length),
        services: updatedServices
      };
    });

  };
  const handleAddServiceNameChange = (newValue, index) => {
    setAllServices((prevState) => {
      const updatedServices = [...prevState.services];
      updatedServices[index].serviceName = newValue;
      return { ...prevState, services: updatedServices };
    });

  };

  const handleInputAddServiceNameChange=(inputValue, actionMeta, index)=>{
    if (actionMeta.action == "menu-close") {
      if (actionMeta.prevInputValue.length > 0) {
        const newOption = { label: actionMeta.prevInputValue, value: actionMeta.prevInputValue };
        setAllServices((prevState) => {
          const updatedServices = [...prevState.services];
          updatedServices[index].serviceName = newOption;
          return { ...prevState, services: updatedServices };
        });
      }
    }

  }

  const handleAddCategoryChange = (newValue, index) => {
    setAllServices((prevState) => {
      const updatedServices = [...prevState.services];
      updatedServices[index].category = newValue;
      return { ...prevState, services: updatedServices };
    });
  };

  // const handleInputAddCategoryChange=(inputValue, actionMeta, index)=>{
  //    if (actionMeta.action == "menu-close") {
       
  //      if (actionMeta.prevInputValue.length > 0) {              
  //        const newOption = { label: actionMeta.prevInputValue, value: actionMeta.prevInputValue };

  //        setAllServices((prev) => {
  //            const updatedServices = [...prev.services];
  //            const updatedCategories = [...updatedServices[index].category, newOption];
  //            updatedServices[index] = {
  //                ...updatedServices[index],
  //                category: updatedCategories
  //            };
  //            return { ...prev, services: updatedServices };
  //        });
  //      }
  //    }
  //  }
  const [selectedServiceName, setSelectedServiceName] = useState(null);
const [categoryOptions, setCategoryOptions] = useState([]);
useEffect(() => {
  if (selectedServiceName) {
    // Filter categories based on the selected service name
    const filteredCategories = data
      .find(service => service.serviceName === selectedServiceName)
      ?.category || [];

    setCategoryOptions(filteredCategories);
  } else {
    setCategoryOptions([]);
  }
}, [selectedServiceName, data]);

const handleSelectService = (selectedOption) => {
  setSelectedServiceName(selectedOption ? selectedOption.value : null);
  setformData(prevState => ({
    ...prevState,
    serviceName: selectedOption
  }));
};


  const handleInputAddCategoryChange = (inputValue, actionMeta, index) => {
    if (actionMeta.action === "menu-close") {
      if (actionMeta.prevInputValue.length > 0) {
        const newOption = { label: actionMeta.prevInputValue, value: actionMeta.prevInputValue };
  
        setAllServices((prev) => {
          const updatedServices = [...prev.services];
          const existingCategories = updatedServices[index].category;
          
          // Check for duplicates (case insensitive)
          const exists = existingCategories.some(option => option.value.toLowerCase() === newOption.value.toLowerCase());
  
          if (!exists) {
            const updatedCategories = [...existingCategories, newOption];
            updatedServices[index] = {
              ...updatedServices[index],
              category: updatedCategories
            };
            return { ...prev, services: updatedServices };
          }
  
          return prev;
        });
      }
    }
  };
  

  const {
    hasValidLength,
    hasNumber,
    hasUpperCase,
    hasSpecialCharacter
  } = usePasswordValidation(formData.password);



  return (
    <>
      <div>
        <div
          className={`form-container sign-up-container ${showWindow.verifyOtp === true ? "verify--otp--container" : ""
            } ${showWindow.insertPassword === true
              ? "password--confirm--container"
              : ""
            }`}
          style={{
            alignItems: "flex-start",
            paddingTop: "clamp(5px,3vw,50px)",
          }}
        >
          {showWindow.basicDetails && (
            <form onSubmit={handleSubmit}>
              <h2 className="mb-3">Become a Hustlr</h2>
              {step === 1 && (
                <>
                  <div className="form--group--login text-start">
                    <label htmlFor="Select your area">Select your area</label>
                    <Select
                      options={provinceList}
                      isClearable
                      className="select--custom"
                      placeholder={`Select your province`}
                      value={selectedProvince}
                      onChange={handleSelectProvince}
                      required
                    />
                  </div>
                  <div className="form--group--login text-start">
                    <label htmlFor="Select your area">Select your area</label>
                    <Select
                      options={cityList}
                      isClearable
                      className="select--custom"
                      placeholder={`Select your city`}
                      value={selectedCity}
                      onChange={handleSelectCity}
                      required
                    />
                  </div>
                  <div className="flex--container">
                    <div className="form--group--login">
                      <label htmlFor="First Name">First Name</label>
                      <input
                        type="text"
                        name="fname"
                        placeholder="Enter first name"
                        value={formData.fname}
                        onChange={handleAlphabet}
                        required
                      />
                    </div>
                    <div className="form--group--login">
                      <label htmlFor="Last Name">Last Name</label>
                      <input
                        type="text"
                        name="lname"
                        placeholder="Enter last name"
                        value={formData.lname}
                        onChange={handleAlphabet}
                        required
                      />
                    </div>
                  </div>
                  <div className="form--group--login">
                    <label htmlFor="Email">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form--group--login">
                    <label htmlFor="Phone Number">Phone Number</label>
                    <input
                      type="tel"
                      name="phoneno"
                      placeholder="Enter your phone number"
                      value={formData.phoneno}
                      onChange={handleNumber}
                      onPaste={handlePaste} // Add the onPaste handler

                      maxLength={10}
                      required
                    />
                  </div>
                </>
              )}

              {step === 2 && (
                <>

                  {/* add category */}
                  {/* {data.map((service, index)=>(
                    
                  <div className={`container--add--more ${allservices.count > 1 ? "active-scroll" : ""}`}>
                  <div className="row--add--more">
                    <div className="form--group--login text-start">
                      <label htmlFor="Choose a Category">
                        Choose a Service
                      </label>
                      <CreatableSelect
                        required
                        options={service?.serviceName}
                        isClearable
                        className="select--custom"
                        placeholder={`Your Job Title`}
                        value={formData.serviceName}
                        onChange={handleSelectService}
                        onInputChange={handleSingleService}
                      />
                    </div>

                    <div className="form--group--login text-start">
                      <label htmlFor="Choose a Category">
                        Choose a Service
                      </label>
                      <CreatableSelect
                        required
                        options={service?.category}
                        isClearable
                        isMulti
                        className="select--custom multiple--select--overflow"
                        placeholder={`Choose Services`}
                        value={formData.category}
                        onChange={handleSelectCategory}
                        onInputChange={handleSingleCategory}
                      />
                    </div>
                  </div>

                </div>

                  ))} */}

{/* {data.map((service, index) => ( */}
  <div
  //  key={service._id} 
   className={`container--add--more ${allservices.count > 1 ? "active-scroll" : ""}`}>
    <div className="row--add--more">
      <div className="form--group--login text-start">
        <label
        //  htmlFor={`service-name-${index}`}
         >
          Choose a Service
        </label>
        <CreatableSelect
          required
          options={data.map(s => ({ label: s.serviceName, value: s.serviceName }))}
          isClearable
          className="select--custom"
          placeholder="Your Job Title"
          value={formData.serviceName}
          onChange={handleSelectService}
          onInputChange={handleSingleService}
        />
      </div>

      <div className="form--group--login text-start">
        <label
        //  htmlFor={`service-category-${index}`}
         >
          Choose a Category
        </label>
        <CreatableSelect
          required
          options={categoryOptions.map(cat => ({ label: cat.label, value: cat.value }))}
          isClearable
          isMulti
          className="select--custom multiple--select--overflow"
          placeholder="Choose Services"
          value={formData.category}
          onChange={handleSelectCategory}
          onInputChange={handleSingleCategory}
        />
      </div>
    </div>
  </div>
{/* ))} */}




                  
                  <div
                    onClick={allservices.count === 0 ? handleAddService : () => setCategoriesModal(true)}
                    className="form--group--login text-start add--more--category"
                  >
                    <p>{allservices.count > 0 ? 'Add more here' : 'Add more skills'} {allservices.count > 0 ? <IoEyeOutline /> : null}</p>
                  </div>
                  {/* add category */}
                  <div className="form--group--login1 accept--terms">
                    <div className="checkbox--custom mb-0">
                      <label
                        htmlFor={`checkbox-terms`}
                        style={{ cursor: "pointer" }}
                      >
                        Read All Terms And Condition
                      </label>

                      <input
                        type="checkbox"
                        id={`checkbox-terms`}
                        value={formData.termsandConditon}
                        onClick={handleTermsCheckbox}
                        checked={formData.termsandConditon === true}
                        required
                      />
                      <span className="checkmark"></span>
                    </div>
                  </div>

                  <div className="form--group--login1 accept--terms">
                    <div
                      className="checkbox--custom"
                      style={{ width: "100%", textAlign: "left" }}
                    >
                      <label htmlFor="smsCheckbox" style={{ cursor: "pointer" }} className="wordkeep">
                        I consent to receive SMS updates and notifications from
                        SideHustl.
                      </label>

                      <input type="checkbox" id="smsCheckbox" required />
                      <span className="checkmark"></span>
                    </div>
                  </div>
                </>
              )}

              <div
                className="form--group--login1 accept--terms"
                style={{ justifyContent: "center", gap: "10px" }}
              >
                {step === 1 ? null : (
                  <>
                    <button
                      type="button"
                      className="custom--btn ligghtbtn-hover transparent--btn"
                      onClick={handlePrevious}
                    >
                      <span>Previous</span>
                    </button>
                  </>
                )}
                <button type="submit" className="custom--btn darkbtn-hover">
                  <span>{step === 2 ? "Submit" : "Next"}</span>
                </button>
              </div>

              <p className="text-center dispri">
                Already have an account?{" "}
                <Link to="/login">
                  <b>Login here</b>
                </Link>
              </p>
            </form>
          )}

          {showWindow.verifyOtp && (
            <>
              <form onSubmit={handleVerifyOtp}>
                <h2 className="mb-3 mb-4" style={{ textAlign: "center" }}>
                  Verify One Time Password
                </h2>
                <div
                  className="form--group--login"
                  style={{ alignItems: "center" }}
                >
                  <label htmlFor="Password"></label>
                  <input
                    type="number"
                    name="otp"
                    placeholder="Enter One Time Password"
                    value={formData.otp}
                    onChange={handleChange}
                    required
                    style={{ width: "100%" }}
                  />

                  <button type="submit" className="custom--btn darkbtn-hover">
                    <span>Verify</span>
                  </button>
                </div>
              </form>

              <p
                onClick={handleReSendOtp}
                className="resend--password"
                style={{
                  cursor: "pointer",
                  fontWeight: "600",
                  textDecoration: "underline",
                  fontSize: "14px",
                  textAlign: 'center',
                  width: '100%'
                }}
              >
                Resend Code
              </p>

              <p style={{fontSize:'14px',marginBottom:'0px',textAlign: 'center', width: '100%'}}>Please verify your <b>email/SMS</b> one time password.</p>
            </>
          )}

          {showWindow.insertPassword && (
            <form onSubmit={handleSetPassword}>
              <h2 className="mb-3 mb-4" style={{ textAlign: "center" }}>
                Enter Password
              </h2>
              <div className="form--group--login">
                <label htmlFor="Password"></label>
                <div className="input-password relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter Password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <div
                    className="passwod--visibitly"
                    onClick={() => handleTogglePasswordVisibility("password")}
                  >
                    {showPassword ? <PiEyeLight /> : <PiEyeSlash />}
                  </div>
                </div>
              </div>
              <div
                className="form--group--login"
                style={{ alignItems: "center" }}
              >
                <div
                  className="input-password relative"
                  style={{ width: "100%" }}
                >
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmpassword"
                    placeholder="Enter Confirm Password"
                    value={formData.confirmpassword}
                    onChange={handleChange}
                    required
                    style={{ width: "100%" }}
                  />
                  <div
                    className="passwod--visibitly"
                    onClick={() =>
                      handleTogglePasswordVisibility("confirmPassword")
                    }
                  >
                    {showConfirmPassword ? <PiEyeLight /> : <PiEyeSlash />}
                  </div>
                </div>
                <button type="submit" className="custom--btn darkbtn-hover">
                  <span>Submit</span>
                </button>
              </div>

              <ul className="password--rules">
                <li className={`${hasValidLength && 'active'}`} >
                  {hasValidLength && hasValidLength === true ? <FaCheck className="check" /> : <IoClose className="close" />} Be a miminum of 8 characters
                </li>
                <li className={`${hasNumber && 'active'}`}>
                  {hasNumber && hasNumber === true ? <FaCheck className="check" /> : <IoClose className="close" />} Include at least one number (0-9)
                </li>
                <li className={`${hasUpperCase && 'active'}`}>
                  {hasUpperCase && hasUpperCase === true ? <FaCheck className="check" /> : <IoClose className="close" />} Include at least one uppercase letter (A-Z)
                </li>
                <li className={`${hasSpecialCharacter && 'active'}`}>
                  {hasSpecialCharacter && hasSpecialCharacter === true ? <FaCheck className="check" /> : <IoClose className="close" />} Include at least one special characters (!@#$%^&*)
                </li>
              </ul>
            </form>
          )}
        </div>

        {showTermsandConditonModel && (
          <TermsandConditonModel
            setformData={setformData}
            setshowTermsandConditonModel={setshowTermsandConditonModel}
          />
        )}
      </div>

      {categoriesModal && (


        allservices.count === 0 ? null : (

          <div className="modal--categories">
            <div className={`container--categories ${allservices.count > 3 ? 'custom--scroll' : ''}`}>
              {allservices.services.map((item, index) => (
                <div className="row--add--more" key={index}>
                  <div className="form--group--login text-start">
                    <CreatableSelect
                      required
                      isClearable
                      className="select--custom"
                      options={taskCatgeory}
                      placeholder={`Your Job Title`}
                      value={item.serviceName}
                      onChange={(newValue) =>
                        handleAddServiceNameChange(newValue, index)
                      }
                      onInputChange={(inputValue, actionMeta) =>
                        handleInputAddServiceNameChange(inputValue, actionMeta, index)
                      }
                    />
                  </div>
                  <div className="form--group--login text-start">
                    <CreatableSelect
                      isMulti
                      required
                      isClearable
                      options={taskServices}
                      className="select--custom multiple--select--overflow"
                      placeholder={`Choose Services`}
                      value={item.category}
                      onChange={(newValue) =>
                        handleAddCategoryChange(newValue, index)
                      }
                      onInputChange={(inputValue, actionMeta) =>
                        handleInputAddCategoryChange(inputValue, actionMeta, index)
                      }
                    />
                  </div>
                  <span className="remove--service" onClick={() => handleRemoveService(index)}><IoCloseCircle /></span>
                </div>
              ))}
            </div>
            <p onClick={handleAddService} style={{ cursor: 'pointer', paddingTop: '10px', width: 'fit-content' }}>Add Category +</p>

            <div className="group--btn">
              <button className="custom--btn transparent--btn mini-btn" style={{ color: '#000' }} onClick={() => setCategoriesModal(false)}>Cancel</button>

              {allservices.count > 0 ? <button className="custom--btn mini-btn" onClick={() => setCategoriesModal(false)}>Add</button>
                : null}
            </div>
          </div>
        )

      )}

    </>


  );
};

export default IsLoadingHOC(HustlerSignup);
