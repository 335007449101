import React from "react";
import moment from "moment";
import { dateFormat } from "../../utils/helper";
const HustlerCompletedJobModal = ({
  setshowHustlerCompletedJobModel,
  handleCompleteJob,
  setallId,
  allId,
  handleCompleteRecurringJob,
  setTaskDetails,
  TaskDetails,
}) => {
  const handleSubmit = () => {
    if (allId?.subscriptionId) {
      handleCompleteRecurringJob();
    } else {
      handleCompleteJob();
    }
  };
  const handleClose = () => {
    setshowHustlerCompletedJobModel(false);
    setallId((prev) => ({
      ...prev,
      directorId: "",
      taskId: "",
      subscriptionId: "",
      intervalId: "",
    }));
    setTaskDetails([])
  };

  return (
    <div>
      <div
        className={`deletemodal modal ${
          setshowHustlerCompletedJobModel ? "show" : ""
        }`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-center">Please Confirm</h4>
              <button
                type="button"
                className="close"
                onClick={handleClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-5 pb-5 confirmation--modal">
              {allId?.subscriptionId ? (
                <h5 className="mb-0">
                  Are you sure that you have completed this reoccurring job for{" "}
                  {TaskDetails?.latestCounterOffer?.finalDate ? (
                    <span>
                      {dateFormat(new Date())}
                    </span>
                  ) : (
                    <span>
                    {dateFormat(new Date())}
                  </span>
                  )}
                  ?
                </h5>
              ) : (
                <h5>Are you sure you have completed this Job</h5>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn"
                //onClick={setshowHustlerCompletedJobModel}
                onClick={handleClose}
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                className="btn btn-dark darkbtn-hover"
                onClick={handleSubmit}
              >
                <span>Yes</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${
          setshowHustlerCompletedJobModel ? "show" : ""
        }`}
      ></div>
    </div>
  );
};

export default HustlerCompletedJobModal;
