import React, { useState, useEffect } from 'react';
import FooterDesktop from './footerDesktop';
import FooterMobile from './footerMobile';
// import FooterMobile from './FooterMobile';
// im
// import FooterDesktop from './FooterDesktop';

const Footer = () => {
    const [isMobile, setIsMobile] = useState(false);

    // Detect screen width and set isMobile state
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1025); // You can adjust the breakpoint for mobile view
        };

        handleResize(); // Check initial size

        window.addEventListener('resize', handleResize); // Add resize event listener
        return () => window.removeEventListener('resize', handleResize); // Clean up event listener
    }, []);

    return (
        <>
            {isMobile ? <FooterMobile /> : <FooterDesktop />}
        </>
    );
};

export default Footer;
