import React from 'react'

const EndSubsciption = ({handleEndSubScription,setshowEndSubscriptionModel}) => {

    const handleClose=()=>{
        setshowEndSubscriptionModel(false)
    }

    const handleSubmit=()=>{
        handleEndSubScription()
    }
  return (
    <div>
      <div
        className={`deletemodal modal ${setshowEndSubscriptionModel ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-center">Please Confirm</h4>
              <button
                type="button"
                className="close"
                onClick={handleClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-5 pb-5 confirmation--modal">
           
                <h5>Are you sure you want to end the Subscription?</h5>
             
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn"
                onClick={handleClose}
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                className="btn btn-dark darkbtn-hover"
                onClick={handleSubmit} // Call endSubscription when "Yes" is clicked
              >
                <span>Yes</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${setshowEndSubscriptionModel ? "show" : ""}`}
      ></div>
    </div>
  )
}

export default EndSubsciption