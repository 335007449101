import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authAxios } from "../services/config";
import { toast } from "react-toastify";
import { handleDecimalNumber } from "../utils/helper";
import { Link } from "react-router-dom";
const ProfileRattingDetails = () => {
  const userId = useSelector((state) => state?.auth?.user?.userId);

  const [allProfile, setallProfile] = useState({});

  const fetchUserAllReviewDetails = async () => {
    await authAxios()
      .get(`/users/get-specific-user/${userId}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setallProfile(resData?.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchUserAllReviewDetails();
  }, []);

  return (
    <>
      {allProfile?.userType == "DIRECTOR" ? (
        <div className="reviews--section">
          <div className="profile--reviews--rating">
            <div>
              <img src="/assets/images/hustler-star.png" />{" "}
              {handleDecimalNumber(allProfile?.directorDetails?.ratings)}
            </div>
          </div>
          <div className="ratingbar--list">
            <div className="ratingbar--list--left">
              <Link to={{
                pathname: '/your-task-list/',
              }}
                state={{ id: "new-jobs" }}
                className="task--status"
                style={{ cursor: 'pointer', color: '#000', textDecoration: 'none' }}
              >
                <div className="task--icon">
                  <img src="/assets/images/interestshare.png" />
                </div>
                <div className="task--numbers">
                  <p>Total Jobs</p>
                  <p>
                    <strong>{allProfile?.directorDetails?.totalTask}</strong>
                  </p>
                </div>
              </Link>

              <Link to={{
                pathname: '/your-task-list/',
              }}
                state={{ id: "completed-task" }}
                className="task--status"
                style={{ cursor: 'pointer', color: '#000', textDecoration: 'none' }}
              >
                <div className="task--icon">
                  <img src="/assets/images/completetask.png" />
                </div>
                <div className="task--numbers">
                  <p>Completed Jobs</p>
                  <p>
                    <strong>
                      {allProfile?.directorDetails?.completedTask}
                    </strong>
                  </p>
                </div>
              </Link>

              <Link to={{
                pathname: '/your-task-list/',
              }}
                state={{ id: "in-progress" }}
                className="task--status"
                style={{ cursor: 'pointer', color: '#000', textDecoration: 'none' }}
              >
                <div className="task--icon">
                  <img src="/assets/images/pendingtask.png" />
                </div>
                <div className="task--numbers">
                  <p>In Progress Jobs</p>
                  <p>
                    <strong>
                      {allProfile?.directorDetails?.inProgressTask || 0}
                    </strong>
                  </p>
                </div>
              </Link>

              <Link to={{
                pathname: '/your-task-list/',
              }}
                state={{ id: "in-closed" }}
                className="task--status"
                style={{ cursor: 'pointer', color: '#000', textDecoration: 'none' }}
              >

                <div className="task--icon">
                  <img src="/assets/images/cancelled.png" />
                </div>
                <div className="task--numbers">
                  <p>Closed Jobs </p>
                  <p>
                    <strong>
                      {allProfile?.directorDetails?.closedTask}
                    </strong>
                  </p>
                </div>

              </Link>

            </div>
          </div>
          <div className="rating--list--right">
            <ul>
              <li>
                <span>Professionalism</span>
                <span>
                  <img src="/assets/images/hustler-star.png" />{" "}
                  {handleDecimalNumber(allProfile?.directorDetails?.behaviorAvg)}
                </span>
              </li>

              <li>
                <span>Availability</span>
                <span>
                  <img src="/assets/images/hustler-star.png" />{" "}
                  {handleDecimalNumber(allProfile?.directorDetails?.availabilityAvg)}
                </span>
              </li>
              <li>
                <span>Payment</span>
                <span>
                  <img src="/assets/images/hustler-star.png" />{" "}
                  {handleDecimalNumber(allProfile?.directorDetails?.paymentAvg)}
                </span>
              </li>
              <li>
                <span>Communication</span>
                <span>
                  <img src="/assets/images/hustler-star.png" />{" "}
                  {handleDecimalNumber(allProfile?.directorDetails?.communicationAvg)}
                </span>
              </li>
            </ul>
          </div>
        </div>
      ) : allProfile?.userType == "HUSTLER" ? (
        <div className="reviews--section">
          <div className="profile--reviews--rating">
            <div>
              <img src="/assets/images/hustler-star.png" />{" "}
              {handleDecimalNumber(allProfile?.hustlerDetails?.ratings)}
            </div>
          </div>
          <div className="ratingbar--list">
            <div className="ratingbar--list--left">
              <Link to={{
                pathname: '/home',
              }}
                state={{ id: "interested-jobs" }}
                className="task--status" style={{ cursor: 'pointer', color: '#000', textDecoration: 'none' }}>
                <div className="task--icon">
                  <img src="/assets/images/interestshare.png" />
                </div>
                <div className="task--numbers">
                  <p>Total Applied Jobs </p>
                  <p>
                    <strong>{allProfile?.hustlerDetails?.appliedTask}</strong>
                  </p>
                </div>
              </Link>

              <Link to={{
                pathname: '/hustlr-jobs/',
              }}
                state={{ id: "completed-jobs" }}
                className="task--status" style={{ cursor: 'pointer', color: '#000', textDecoration: 'none' }}>
                <div className="task--icon">
                  <img src="/assets/images/completetask.png" />
                </div>
                <div className="task--numbers">
                  <p>Completed Jobs</p>
                  <p>
                    <strong>{allProfile?.hustlerDetails?.completedTask}</strong>
                  </p>
                </div>
              </Link>

              <Link to='/hustlr-jobs'
                state={{ id: "upcoming" }}
                className="task--status" style={{ cursor: 'pointer', color: '#000', textDecoration: 'none' }}>
                <div className="task--icon">
                  <img src="/assets/images/pendingtask.png" />
                </div>
                <div className="task--numbers">
                  <p>In Progress Jobs</p>
                  <p>
                    <strong>
                      {allProfile?.hustlerDetails?.inProgressTask}
                    </strong>
                  </p>
                </div>
              </Link>

              <Link to={{
                pathname: '/hustlr-jobs',
              }}
                state={{ id: "cancelled-jobs" }}
                className="task--status" style={{ cursor: 'pointer', color: '#000', textDecoration: 'none' }}>
                <div className="task--icon">
                  <img src="/assets/images/cancelled.png" />
                </div>
                <div className="task--numbers">
                  <p>Cancelled Jobs</p>
                  <p>
                    <strong>{allProfile?.hustlerDetails?.cancelledTask}</strong>
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="rating--list--right">
            <ul>
              <li>
                <span>Professionalism</span>
                <span>
                  <img src="/assets/images/hustler-star.png" />{" "}
                  {handleDecimalNumber(allProfile?.hustlerDetails?.behaviorAvg)}
                </span>
              </li>

              <li>
                <span>Availability</span>
                <span>
                  <img src="/assets/images/hustler-star.png" />{" "}
                  {handleDecimalNumber(allProfile?.hustlerDetails?.availabilityAvg)}
                </span>
              </li>
              <li>
                <span>Skills</span>
                <span>
                  <img src="/assets/images/hustler-star.png" />{" "}
                  {handleDecimalNumber(allProfile?.hustlerDetails?.skillsAvg)}
                </span>
              </li>
              <li>
                <span>Communication</span>
                <span>
                  <img src="/assets/images/hustler-star.png" />{" "}
                  {handleDecimalNumber(allProfile?.hustlerDetails?.communicationAvg)}
                </span>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <> </>
      )}
    </>
  );
};

export default ProfileRattingDetails;
