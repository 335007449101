import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { store, persistor } from "./Redux/store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { SocketProvider } from './contexts/SocketContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <SocketProvider>
        <App />
      </SocketProvider>
      <ToastContainer />
    </PersistGate>
  </Provider>
);

