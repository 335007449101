import React, { useState, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { authAxios } from "../services/config";
import { toast } from "react-toastify";
import { FaLocationDot } from "react-icons/fa6";
import { handleDecimalNumber } from "../utils/helper";

const AnotherHustlrLists = ({ setshowAnotherHustlr, setLoading, handleChangeHustler, showAnotherHustlr }) => {
  const [hustler, sethustler] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(6);
  const [totalPosts, settotalPosts] = useState(0);

  const handleClose = () => {
    setshowAnotherHustlr((prev) => ({
      ...prev,
      model: false,
    }));
  };

  const fetchallUsers = async (page = 1, excludeId = null) => {
    setLoading(true);
    try {
      const response = await authAxios().post(`/search/get-all-other-hustlers`, {
        page: page,
        limit: postsPerPage,
        excludeId: showAnotherHustlr.hustlerId
      });

      const resData = response?.data;
      setLoading(false);

      if (resData.status === 1) {
        settotalPosts(resData?.count?.total);
        sethustler(resData?.data);
        setcurrentPage(page);
      } else {
        toast.error(resData?.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "An unexpected error occurred.");
    }
  };

  const handleHire = (id) => {

    const payload = {
      hustlerId: id,
      taskId: showAnotherHustlr.taskId,
    }


    handleChangeHustler(payload)

  }



  useEffect(() => {
    fetchallUsers(currentPage);
  }, []);

  const handlePrev = () => {
    if (currentPage > 1) {
      fetchallUsers(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(totalPosts / postsPerPage)) {
      fetchallUsers(currentPage + 1);
    }
  };

  return (
    <div
      className="another--hustler--lists"
    >
      <div
        className="another--hustler--container"
      >
        <div
          className="another--hustler--header"
        >
          <h2 className="text--dark playFairDisplay heading-h2 mb-2">
            Hustlr Lists
          </h2>
          <div
            onClick={handleClose}
            className="closemodal"
          >
            <IoCloseSharp />
          </div>
        </div>

        <div
          className="another--hustler--boxes"
        >
          {hustler &&
            hustler.length > 0 &&
            hustler.map((item) => (
              <div
                key={item.user.id}
                className="another--hustler--box"
              >
                <div className="profile--hustler" >
                  <div style={{
                    maxWidth: "70px",
                    width:'100%'
                  }}>
                    <img
                      src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.user?.profilePic}`}
                      alt="Profile"
                      className="profile--image"
                    />
                  </div>
                  <div style={{
                    width: "100%",
                    minWidth:'200px'
                  }}>
                    <div
                      className="profile--name"
                    >
                      <span>{item.user.fname} </span>{" "}<span>{item.user.lname}</span>
                    </div>
                    <div className="location--hustler">
                      {item?.user?.hustlerDetails?.province}{" "}
                      {item?.user?.hustlerDetails?.location}
                    </div>

                    <div className="profile--rating">
                      <span>
                        <img src="/assets/images/hustler-star.png" /> {handleDecimalNumber(item.user.hustlerDetails.ratings)}
                      </span>
                      <span>
                        <img src="/assets/images/hustler--taskdone.png" /> {item.user.hustlerDetails.completedTask} Completed Jobs
                      </span>
                    </div>
                  </div>
                </div>
                <button onClick={() => handleHire(item.user.id)} className="btn btn-dark darkbtn-hover">
                  <span>Hire Hustlr</span>
                </button>
              </div>
            ))}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <button
            onClick={handlePrev}
            style={{
              padding: "10px 20px",
              marginRight: "10px",
              backgroundColor: "#fff",
              border: "1px solid #000",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <button
            onClick={handleNext}
            style={{
              padding: "10px 20px",
              backgroundColor: "#fff",
              border: "1px solid #000",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AnotherHustlrLists;
