import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { saveUser, setAccessToken } from "../../Redux/Reducers/authSlice";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { withoutAuthAxios } from "./../../services/config";
import socketIOClient from "socket.io-client";
import ContactAdmin from "../../common/ContactAdmin";
import { saveServices, saveTaskOptions,CombineTaskOptions } from "../../Redux/Reducers/appSlice";
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import { removeDuplicates, RemovingDuplicatesServicesandCategories,toCamelCase } from "../../utils/helper";
const ENDPOINT = process.env.REACT_APP_BASEURL;
function Login(props) {
  const { setLoading } = props;
  const initialFormData = {
    email: "",
    password: "",
  };
  const socketRef = useRef();
  socketRef.current = socketIOClient(ENDPOINT);
  const token = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [showContactadminModel, setshowContactadminModel] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
   socketRef.current = socketIOClient(ENDPOINT);
  }, []);

  const getTaskListData = async () => {
    setLoading(true);
    await withoutAuthAxios()
      .get(`/services/get-all-services`)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData?.status == 1) {

          
         // dispatch(saveTaskOptions(resData?.data));

          const services = [];
          resData?.data.map((item) => {
            services.push({
              label: toCamelCase(item?.serviceName),
              value: toCamelCase(item?.serviceName),
            });
          });

          const category=[]

          resData?.data?.map((item)=>{
            item?.category?.map((cat)=>{
              category?.push(cat)
            })
          })

          
          dispatch(CombineTaskOptions(resData))

          dispatch(saveServices(RemovingDuplicatesServicesandCategories(services)))
          dispatch(saveTaskOptions(RemovingDuplicatesServicesandCategories
            (category)))
          
        } else {
          toast.error(resData?.message?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getTaskListData();
    if (token) {
      navigate("/home");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password.length < 8) {
      toast.error("Invalid password");
      return;
    }
    try {
      setLoading(true);
      const response = await withoutAuthAxios().post("/users/login", formData);
      const resData = response.data;
      setLoading(false);
      if (resData.status === 1) {
        if (resData.data.userType == "ADMIN") {
          toast.error("Invalid credentials");
        } else {
          
           socketRef.current.emit("login", resData.data.userId);
         dispatch(saveUser(resData.data));
        dispatch(setAccessToken(resData.data.token));

        if(resData.data.userType=="HUSTLER"){
          localStorage.setItem('Province', resData.data.hustlerDetails.province);
          localStorage.setItem('CityName', JSON.stringify([resData.data.hustlerDetails.location]));
        }
       
          toast.success("Logged in successfully");
         setFormData(initialFormData);
         navigate("/home");
        }
      } 
    } catch (error) {
      console.log("error.response", error.response);
      if(error.response?.data?.errors?.[0]?.email){
        toast.error(error.response?.data?.errors?.[0]?.email);
      }else{
        toast.error(error.response?.data?.message);
      }
      
      setLoading(false);
      if (
        error.response.data.message ==
        "Your account is frozen. Contact admin to reactivate your account."
      ) {
        setshowContactadminModel(true);
      } else {
        console.log('error')
      }
    }
  };

  const closeModal = () => {
    setshowContactadminModel(false);
  };

  return (
    <>
      <div className="login--container login--new-design">
        <div className="signup-form">
          <Link to='/' className="text-center">
            <img
              src="./assets/images/logo.png"
              class="mb-3 w-full logo--form"
              alt="logo"
            />
          </Link>
          <div className={`form--main--container`} id="container">
            <div className="form-container sign-in-container">
              <form onSubmit={handleSubmit}>
                <h2 className="text-center">Login</h2>
                <div className="form--group--login">
                  <label htmlFor="Email">Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form--group--login">
                  <label htmlFor="Password">Password</label>
                  <div className="input-password">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      placeholder="Enter your password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    <div className="passwod--visibitly" onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <PiEyeLight /> : <PiEyeSlash />}
                    </div>
                  </div>
                </div>
                <div className="forgot--password">
                  <Link to='/forgot-password'>Forgot Password</Link>
                </div>
                <button type="submit" className="custom--btn darkbtn-hover">
                  <span>Login</span>
                </button>
                {/* <p className='text-center dispri'>Don't have an account? <Link to="/signup" className='text-white'><b>Register here</b></Link></p> */}
              </form>
            </div>
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-right">
                  <h4 className="mb-3">Don't have an account? Register here</h4>
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                  <Link
                    to="/signup"
                    className="btn-dark btn darkbtn-hover custom--btn"
                    id="signUp"
                  >
                    <span>Register here</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showContactadminModel && <ContactAdmin closeModal={closeModal} />}
    </>
  );
}

export default IsLoadingHOC(Login);
