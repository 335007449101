import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authAxios } from "../services/config";
import { toast } from "react-toastify";

const ViewTask = () => {
  const parmas = useParams();

  const UserId = parmas.id;
  const [TaskDetail, setTaskDetail] = useState(null);

  const fetchtask = async () => {
    await authAxios()
      .get(`/task/get-specific-task/${UserId}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setTaskDetail(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchtask();
  }, []);

  return <div>
    
  </div>;
};

export default ViewTask;
