import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Footer from '../common/footer';
import CommonHeader from '../common/CommonHeader';
import TaskNotificationDetails from '../common/TaskNotificationDetails';

const CommonLayout = ({ children, pageName }) => {
  const { accessToken } = useSelector((state) => state.auth);
  const userType = useSelector((state) => state.auth?.user?.userType);
  const navigate = useNavigate();

  const id = pageName ? `${pageName}--page` : "home--page";

  useEffect(() => {
    if (userType !== "HUSTLER" && userType !== "DIRECTOR") {
      navigate("/");
    }
  }, []);


  return (
    <>
      <div id={id}>
        <CommonHeader />
        {children}
        <Footer />
        {userType === "DIRECTOR" && <TaskNotificationDetails />}
      </div>
    </>
  );
};

export default CommonLayout;
